import UserTaskCountChartData from "./UserTaskCountChartData";
import './UserTaskCountChart.css'
// import { useApp } from "../../../Contexts/AppContext";

const UserTaskCountChart = ({ transformdatas, page, setProjectId, projectid }:any) => {


    console.log(page,"tldashboardtldashboard")
    

    let option = {
        color: ['#27B7FF', '#7690FF', '#8DD6FF', '#5FAFFF', '#FF76AF', '#D3A8FD', '#FFB579', '#4FD190'],
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: transformdatas,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    const nodatajsx = () => {
        return <UserTaskCountChartData option={option} />
    }
    return (
        <>
            {
                page === "tldashboard" ?
                    <div>

                        {
                            nodatajsx()
                        }

                    </div>
                    :
                    <div className="UserTaskCountChart" >
                        <h4 style={{ marginTop: "0px", marginBottom: "6px", fontSize: "16px", color: "#3f5bd3", paddingLeft: "20px", paddingTop: "10px" }}>User wise task</h4>
                        {
                            nodatajsx()
                        }

                    </div>

            }
           
        </>
    )
}

export default UserTaskCountChart