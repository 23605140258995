import React, { useEffect, useState } from "react";
import {
  Avatar,
  Breadcrumb,
  Col,
  Row,
  Table,
  Button,
  List,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getAvatarUrl } from "../utils/Util";
import HTTPSCalls from "../Services/HTTPCalls";
import { ToothbarReport } from "./Peoplereportroute/ToothbarReport";
import { ColumnsType, TableProps } from "antd/es/table";
import {
  ArrowLeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AppRoutesPath } from "../Routes/AppRoutesPath";
import TableSkeleton from "./dashboard/Common/TableSkeleton";
import "../common/common.css";
export enum userStatus {
  All,
  Active,
  InActive,
}
const UserReport = ({ rowCount }: any) => {
  const columns: ColumnsType = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "7%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name), 
      render: (text: string, record: any) => (
        <>
          <List itemLayout="horizontal" size="small">
            <List.Item
              key={record?.id}
              style={{ padding: 0, alignItems: "center" }}
            >
              <List.Item.Meta
                style={{ padding: 0, alignItems: "center" }}
                key={`key-${record?.id}`}
                avatar={
                  <Avatar
                    icon={<UserOutlined />}
                    size={"small"}
                    src={record?.avatarUrl}
                    alt="user"
                  />
                }
                title={
                  <Link
                    to={`/${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`}
                    state={{ userid: record?.id }}
                  >
                    <Typography.Paragraph
                      title={text}
                      ellipsis
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {text}
                    </Typography.Paragraph>
                  </Link>
                }
              />
            </List.Item>
          </List>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      showSorterTooltip:false,
      width: "20%",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email), 
      render: (text: string, record: any) => (
        // <Link
        //   style={{ cursor: "pointer", color: "#1677ff" }}
        //   to={`/${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`}
        //   state={{ userid: record?.id }}
        // >
        <>
          <Typography.Paragraph
            title={record?.email}
            ellipsis
            copyable
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {record?.email}
          </Typography.Paragraph>
        </>
        // </Link>
      ),
    },
    {
      title: "To Do",
      dataIndex: "todo",
      key: "todo",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.todo - b.todo,
      render: (text: number) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(209, 236, 241)",
            color: "rgb(12, 84, 96)",
            width: "110px",
            border: "none",
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "In Progress",
      dataIndex: "inprogress",
      key: "inprogress",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.inprogress - b.inprogress,
      render: (text: number) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 229, 255)",
            // color: "#64b4fe",
            width: "110px",
            border: "none",
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Done",
      dataIndex: "done",
      key: "done",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Done - b.Done,
      render: (text: number) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 209, 255)",
            // color: "rgb(204, 209, 255)",
            width: "110px",
            border: "none",
          }}
        >
          {text}
        </Button>
      ),
    },
  ];
  // UserReport.propTypes = {
  //   columns: PropTypes.array.isRequired,
  //   rowCount: PropTypes.number,
  // };

  // UserReport.defaultProps = {
  //   rowCount: 20,
  // };
  const [usertaskcount, setusertaskcount] = useState([]);
  const [projectuserarrfilter, setprojectuserarrfilter] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [defaultCount, setdefaultCount] = useState<userStatus>(
    userStatus.Active
  );
  const navigate = useNavigate();
  const data = usertaskcount.map((item: any, index: number) => ({
    serial:index+1,
    key: index,
    id: item?.id,
    name: item?.name,
    imagePath: "",
    imageThumb: "",
    email: item?.email,
    todo: item?.todo,
    inprogress: item?.inprogress,
    done: item?.done,
    avatarUrl: getAvatarUrl(item),
  }));
  const fetchUserTaskCount = async () => {
    try {
      setisLoading(true);
      const response = await HTTPSCalls.GET(
        `${HTTPSCalls.ENDPOINTS.user.getAssigneeByTaskCount}?isActive=${defaultCount}`
      );
      setprojectuserarrfilter(response.result);
      setusertaskcount(response.result);
    } catch (e) {
      console.log("error", e);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchUserTaskCount();
  }, [defaultCount]);

  // const handleclick = (pagename: string, item: any) => {
  //   LocalStorage.setItem("page", pagename);
  //   // console.log(item, "itemitem");
  //   if (pagename === "report") {
  //     navigate("/reports/reporting", {
  //       state: {
  //         userid: item.id,
  //       },
  //     });
  //   }
  // };
  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "Peoples",
    },
  ];

  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const [refresh,setRefresh] = useState<boolean>(false)
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      <Row justify={"end"} className="">
        <Col span={21}>
          <Button type="link" onClick={() => navigate("/")}>
            <ArrowLeftOutlined />
          </Button>
        </Col>
        <Col
          className="pl-15"
          span={3}
          style={{ fontSize: "12px", textAlign: "center" }}
        >
          <Breadcrumb items={breadcrumbItems} />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"end"} className="mb-2">
            <Col span={24}>
              <ToothbarReport
                defaultCount={defaultCount}
                setdefaultCount={setdefaultCount}
                setprojectuserarr={setusertaskcount}
                projectuserarrfilter={projectuserarrfilter}
              />
            </Col>
          </Row>
          {/* <Loader loading={isLoading} /> */}
        </Col>

        <Col span={24}>
          {isLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
           className="capTask-custom-table"
              dataSource={data}
              columns={columns}
              onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              // pagination={{
              //   onChange: cancel,
              // }}
              scroll={{ y: "70vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserReport;
