import { Skeleton, Table, TableProps } from "antd";
import * as React from "react";
import "../../../common/common.css";

interface ISkeletonTableProps extends TableProps {
  rows?: number;
  rowBorder?:boolean
}
const TableSkeleton = ({ rows = 15,rowBorder=true,...rest }: ISkeletonTableProps) => {
  const data: string[] = [];
  for (let index = 0; index < rows; index++) {
    data.push(new Date()?.toString());
  }
  const clsNames:any[] = [
    // 'skeletonTable',
    rest?.className,
    rowBorder?"removeRowBorder":""
    
  ]
  return (
    <>
      <Table
          
        {...rest}
         className={clsNames.join(" ")}
     style={{marginTop:'12px'}}
        rowKey={rest?.rowKey ?? new Date()?.toString()}
        pagination={rest?.pagination ?? false}
        dataSource={data?.map((itm: string) => ({
          key: `key${itm}`,
        }))}
        columns={rest?.columns?.map((columns: any) => ({
          ...columns,
          render: () => (
            <>
              <Skeleton.Button active block shape="square" />
            </>
          ),
        }))}
      />
    </>
  );
};

export default React.memo(TableSkeleton);

