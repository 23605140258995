
import UserDetail from './UserDetail/UserDetail'
const User = () => {
  return (
    <div>
      <UserDetail/>
    </div>
  )
}

export default User
