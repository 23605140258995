import { Droppable } from "@hello-pangea/dnd";
import { DraggableItemListView } from "./DraggableItemListView";
import { Button, Card, Col, ConfigProvider, Row, Typography, } from "antd";
import { GRID_VIEW } from "../../constants/commonConstants";
import DraggableItemGridView from "./DraggableItemGridView";
import { formatDatedaytomonth } from "../../utils/DateConvertor";
import "../../common/common.css";
import { getEllipsisText, statusbackgroundgenerator, } from "../../utils/Util";
import { DingdingOutlined, NodeIndexOutlined } from "@ant-design/icons";
const DroppableColumn = (props: any) => {
  const status = props?.status;
  const tasks = props?.tasks ? [...props?.tasks] : [];

  return (
    <div
      style={{
        // margin: "9px 5px",
        width: props?.viewMode === GRID_VIEW ? "70vh" : "auto",
        height: props?.viewMode === GRID_VIEW ? "100vh" : "auto",
        // backgroundColor: "#F2F6FE",
       overflowY: props?.viewMode === GRID_VIEW ? "visible" : "scroll",
        maxHeight: props?.viewMode === GRID_VIEW ? "100vh" : "50vh",
        scrollbarWidth: "none",
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Card: {
              headerBg: "#F2F6FE",
              colorBgContainer: "#F2F6FE",
              colorTextHeading: "#377dff",
              
            },
          },
        }}
      >
        {/* <div
        style={{
          position: "sticky",
          top: 0,
          padding: "5px",
          backgroundColor: "#F2F6FE",
          zIndex: 1,
          overflowY: "scroll",
          cursor: "pointer",
        }}
      >
         <Typography.Title level={5} className="mb-0 pl-4">
          {<img src="/mywork_blue.svg" alt="backlog" className="pr-2 pb-1" />}
          {props?.sprintData ? props?.sprintData?.name : status}
          <Badge
            className="pl-2 badgecolor"
            size="small"
            count={tasks?.length}
            color="rgb(224, 231, 245)"
            style={{ padding: "0px 13px" }}
          />

          {props?.sprintData && (
            <span className="pl-3 dataFormet">
              {formatDatedaytomonth(props?.sprintData?.startDate)} -{" "}
              {formatDatedaytomonth(props?.sprintData?.endDate)}
            </span>
          )}
        </Typography.Title>
      </div> */}
        <Droppable droppableId={status}>
          {(droppableProvided, snapshot) => (
            <Card
              size="small"
              style={{
                // position: "sticky",
                // top: 0,
                // padding: "5px",
                // backgroundColor: "#F2F6FE",
                // zIndex: 0,
                borderRadius: 5,
                padding: "0px 9px",
                 height: "74%",
                scrollbarWidth: "none",
                 overflowY: "scroll",
                //  maxHeight:175,
                cursor: "pointer",
                marginBottom: 10,
                marginRight: props?.viewMode === GRID_VIEW ? "10px" : "0px",
              }}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              title={
                <Row >
                  <Col>
                    <NodeIndexOutlined style={{ color: "#B0BEC5" }} />
                    <Typography.Text
                      // size="small"
                      className="resume-btn-sm "
                      style={{
                        // color: statusbackgroundgenerator(status),
                        color: "#000",

                        // backgroundColor: "rgb(223, 246, 221)",
                        // color: "rgb(12, 84, 96)",
                        border: "none",
                      }}
                    >
                      {props?.viewMode === GRID_VIEW
                        ? getEllipsisText(
                            props?.sprintData
                              ? props?.sprintData?.name
                              : status,
                            13
                          )
                        : props?.sprintData
                        ? props?.sprintData?.name
                        : status}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <>
                      {props?.sprintData && (
                        <span className="pl-3 dataFormet">
                          {formatDatedaytomonth(props?.sprintData?.startDate)} -{" "}
                          {formatDatedaytomonth(props?.sprintData?.endDate)}
                        </span>
                      )}
                    </>
                  </Col>
                </Row>
              }
              extra={
                // <Tag color="#377dff" >Task {tasks?.length}</Tag>
                <Button
                  size="small"
                  className="resume-btn-sm"
                  shape="round"
                  style={{
                    backgroundColor: "rgb(204, 229, 255)",
                    //  color: "#64b4fe",
                    width: "40px",
                    border: "none",
                  }}
                >
                  {tasks?.length}
                </Button>
                // <Badge
                //   className="pl-2 badgecolor"
                //   size="small"
                //   count={tasks?.length}
                //   color="#4096ff"
                //   style={{ padding: "0px 13px" }}
                // />
              }
            >
              {/* <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className={snapshot.isDraggingOver ? "isDraggingOver" : ""}
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  background: "red",
                  // position:"sticky",
                  borderRadius: 5,
                  // padding: "5px",
                  height: "90%",
                  overflowX: "scroll",
                  scrollbarWidth: "none",
                  // zIndex:"80000 !important"
                  // backgroundColor: snapshot.isDraggingOver ? "#dadadf" : "inherit",
                }}
              > */}
              {tasks?.map(
                (task: any, index: number) =>
                  task?.UKey &&
                  (props?.viewMode === GRID_VIEW ? (
                    <DraggableItemGridView
                      task={task}
                      index={index}
                      key={task?._id}
                      page={props?.page}
                      sourceDroppableId={status}
                      showLoading={props?.showLoading}
                      onSelectDropDown={props?.onSelectDropDown}
                    />
                  ) : (
                    <DraggableItemListView
                      task={task}
                      index={index}
                      key={task?._id}
                      page={props?.page}
                      sourceDroppableId={status}
                      showLoading={props?.showLoading}
                      onSelectDropDown={props?.onSelectDropDown}
                    />
                  ))
              )}
              {droppableProvided.placeholder}
              {/* </div> */}
            </Card>
          )}
        </Droppable>
      </ConfigProvider>
    </div>
  );
};

export default DroppableColumn;

