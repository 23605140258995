import { Card, Col, Row, Skeleton } from "antd";
import * as React from "react";

const TaskReportSkeleton: React.FC = () => {
  return (
    <Card
      className="main-border mt-1"
      style={{ minWidth: 340, height: "39vh" }}
    >
      <Row align="middle">
        <Col span={24}>
          {/* Header section with title and dropdown */}
          <Row>
            <Col span={5}>
              <Skeleton.Button style={{ width: "90%", height: 20 }} active />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row align="middle"  style={{ marginTop: 20 }}>
        {/* Circular Chart Placeholder */}
        <Col span={12}>
          <Skeleton.Avatar size={200} active shape="circle" />
          <Col span={24} style={{}}>
            {/* Priority Levels with progress bars */}
            <Row align={"middle"} justify={"center"} gutter={[5, 10]}>
              <Col span={3} style={{ marginLeft: "5%" }}>
                <Skeleton.Avatar size={15} active shape="circle" />
              </Col>
              <Col span={3}>
                <Skeleton.Button style={{ height: 10, width: 200 }} active />
              </Col>
              <Col span={3}>
                <Skeleton.Avatar size={15} active shape="circle" />
              </Col>
              <Col span={3}>
                <Skeleton.Button style={{ width: 200, height: 10 }} active />
              </Col>

              <Col span={3}>
                <Skeleton.Avatar size={15} active shape="circle" />
              </Col>
              <Col span={3}>
                <Skeleton.Button style={{ width: 200, height: 10 }} active />
              </Col>
              <Col span={3}>
                <Skeleton.Avatar size={15} active shape="circle" />
              </Col>
              <Col span={13}>
                <Skeleton.Button style={{ height: 10, width: 200 }} active />
              </Col>
            </Row>
          </Col>
        </Col>
        <Col span={12}>
        <Row align={"middle"} justify={"center"} gutter={[5, 10]}>
        <Col span={24}>
          <Skeleton.Input style={{ width: "80%", height: 25 }} active />
          <Row>
          <Col span={7} style={{ marginTop: 20 , width: 120, }}>
            <Skeleton.Avatar size={35} active shape="square" />
          </Col>
          <Col span={14}style={{marginTop: 20 ,}} >
          <Skeleton.Button style={{marginTop: 20 , height: 10, width: 380 }} active />
          </Col>
          </Row>
          <Row>
          <Col span={7} style={{ marginTop: 20 , width: 120, }}>
            <Skeleton.Avatar size={35} active shape="square" />
          </Col>
          <Col span={14}style={{marginTop: 20 ,}} >
          <Skeleton.Button style={{marginTop: 20 , height: 10, width: 380 }} active />
          </Col>
          </Row>
          <Row>
          <Col span={7} style={{ marginTop: 20 , width: 120, }}>
            <Skeleton.Avatar size={35} active shape="square" />
          </Col>
          <Col span={14}style={{marginTop: 20 ,}} >
          <Skeleton.Button style={{marginTop: 20 , height: 10, width: 380 }} active />
          </Col>
          </Row>
        </Col>
        </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(TaskReportSkeleton);
