import React, { useEffect, useState } from "react";
import MeetingToothbar from "../components/commoncomponents/MeetingToothbar";
import BooksService from "../Services/Books";
import { Col, Row } from "antd";
import MeetingTable from "../components/MeetingTable/MeetingTable";
import UpcomingMeeting from "./UserDetail/UpcomingMeeting/UpcomingMeeting";
import Loader from "../components/commoncomponents/Loader";
// import "../App.css";

const Meetings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [meetingData, setMeetingData] = useState<{
    today: any[];
    upcoming: any[];
    old: any[];
  }>({ today: [], upcoming: [], old: [] });
  const [meetingId, setMeetingId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isdoneshow, setIsdoneshow] = useState<boolean>(false);
const getMeetingData = async () => {
  try {
    setIsLoading(true);
    const res = await BooksService.getMeeting(
      "",
      meetingId,
      fromDate,
      toDate,
      isdoneshow
    );
    console.log("API Response:", res);

    if (res?.status) {
      if (res.result.length > 0) {
        setMeetingData(res.result[0]);
      } else {
        // Handle the case where the result is empty
        console.error("No meeting data found.");
        setMeetingData(res.result);
      }
    } else {
      console.error("Error response status:", res.status);
    }
  } catch (err) {
    console.error("An error occurred while fetching meeting data:", err);
  } finally {
    setIsLoading(false);
  }
};

  useEffect(() => {
    getMeetingData();
  }, []);

  return (
    <Row gutter={16}>
      <Loader loading={isLoading} />
      <Col span={24}>
        <MeetingToothbar
          setMeetingId={setMeetingId}
          setToDate={setToDate}
          setFromDate={setFromDate}
          getMeetingData={getMeetingData}
          meetingId={meetingId}
        />
      </Col>
      <Col span={19}>
        <MeetingTable meetingData={meetingData} />
      </Col>
      <Col span={5}>
        <UpcomingMeeting
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
          meetingId={meetingId}
          setMeetingData={setMeetingData}
          meetingData={meetingData}
          isLoading={isLoading}
          isdoneshow={isdoneshow}
        />
      </Col>
    </Row>
  );
};

export default Meetings;
