
import SprintList from './CreateSprint/SprintList'

const Sprint = ({props}: any) => {
  return (
    <>
    <SprintList/>
    </>
  )
}
export default Sprint