import { Avatar, Col, List, Modal, Row, Spin, Table, Typography } from "antd";
import ProjectDetail from "./ProjectCards/ProjectDetail";
import "../../common/common.css";
import PendingTask from "./PendingTask/PendingTask";
import TaskBoard from "./TaskBoard/TaskBoard";
import TaskReportdash from "../Taskreportdashbord/TaskReportdash";
import { useEffect, useState } from "react";
import BooksService from "../../Services/Books";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Loader from "../../components/commoncomponents/Loader";
import { projectData } from "../../store/slices/project-slice";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import TableSkeleton from "./Common/TableSkeleton";
import { getAvatarUrl } from "../../utils/Util";
import { AppRoutesPath } from "../../Routes/AppRoutesPath";
import ActiviyLogsDashboard from "./ActiviyLogs/ActiviyLogsDashboard";
const Dashboard = (props: any) => {
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const [projectdata, setprojectdata] = useState<any>(null);
  // const [dashboarddata, setdashboarddata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [retryLoading, setRetryLoading] = useState(true);
  const [dashboardloading, setdashboardloading] = useState(0);
  // const [width, setWidth] = useState<any>(window.innerWidth);
  const [linechartprojectdata, setlinechartprojectdata] = useState<
    { label: string; value: string }[]
  >([]);
  const [projectidcirculargraph, setprojectidcirculargraph] = useState<any>(
    project?.id
  );
  const [assigneedata, setassigneedata] = useState<any>(null);
  const [assigneechartdata, setassigneechartdata] = useState<any>(null);
  const [alltaskchartrefresh, setalltaskchartrefresh] = useState(false);
  // const [sidebar2, setsidebar2] = useState(false);
  const [priorityselect, setpriorityselect] = useState<any>(null);
  const [reloading, setReloading] = useState(0);
  const [meetingData, setMeetingData] = useState<any>({
    today: [],
    upcoming: [],
    old: [],
  });
  const [meetingId, setMeetingId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [activitydata, setactivitydata] = useState([]);
  const [comid, setcomid] = useState(false);
  const [pendingdata, setpendingdata] = useState<any>(null);
  // const [detailstate, setdetailstate] = useState<any>(null);
  const [lateststate, setlateststate] = useState<any>(null);
  const location = useLocation();
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [countresponse, setcountresponse] = useState<any>(null);
  const [switchData, setSwitchData] = useState<any>("");
  const [addwidth, setaddwidth] = useState(window.innerWidth);
  const [pageroute, setPageRoute] = useState("");
  const [projectIds, setProjectIds] = useState<any>("");
  const navigate = useNavigate();
  const [show, setShow] = useState<string>("");
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const state: any = location.state as {
    bookid: string;
    name: string;
    email: string;
    role: string;
    id: string;
    imageThumb: string;
  };

  const fetchProjects = async () => {
    try {
      let res = await BooksService.getProjectByUser(user?.id);

      setprojectdata(res.result);
      // if (user?.id) {
      //   if (res.result.length > 0) {
      //     setprojectidcirculargraph(res.result[0].id);
      //   }
      // }
      let userdatatype: any = [];

      res.result.map((item: any) => {
        userdatatype.push({ label: item.name, value: item.id });
      });
      setlinechartprojectdata(userdatatype);
      BooksService.getDashboardByProject(
        projectidcirculargraph === null
          ? userdatatype[0].value
          : projectidcirculargraph
      ).then((res: any) => {
        if (res.result.length === 0) {
          setassigneechartdata([]);
        } else {
          setassigneechartdata(res.result.items);
        }
      });
      setRetryLoading(false);
    } catch (err) {}
  };

  const fetchactivity = async () => {
    try {
      let res = await BooksService.getlogByprojectid(15, "", user?.id, 0);
      setactivitydata(res.result);
    } catch (err) {}
  };

  useEffect(() => {
    if (location.state) {
      setcomid(state?.bookId);
    }
  }, [comid, dashboardloading]);

  useEffect(() => {
    if (!retryLoading) {
      return;
    }
  }, [comid, dashboardloading]);

  useEffect(() => {
    fetchactivity();
  }, [localStorage.getItem("createtask"), dashboardloading]);

  const getassigneedata = async () => {
    try {
      let res = await BooksService.getTaskByAssignee(user?.id, 1);
      setIsLoading(false);
      if (res.result.length === 0) {
        setassigneedata([]);
      } else {
        setassigneedata(res.result);
      }
    } catch (err) {}
  };

  const getpendingdata = async () => {
    try {
      let res = await BooksService.getpendingTasks(user?.id);
      if (res.result.items.length === 0) {
        setpendingdata([]);
      } else {
        setpendingdata(res.result.items);
      }
    } catch (err) {}
  };

  //StatusTotalRecord Task Reportselect Api
  const fetchdashboard = async () => {
    try {
      let res = await BooksService.getTaskByassingeeidandprojectid(
        projectIds,
        ""
      );
      if (res.result.items.length === 0) {
        setcountresponse([]);
      } else {
        setcountresponse(res.result.items);
      }
    } catch (err) {
      console.error("Error fetching dashboard data:", err);
    }
  };
  useEffect(() => {
    getassigneedata();
    getpendingdata();
    fetchdashboard();
  }, [lateststate, dashboardloading, projectIds]);

  const getMeetingData = async () => {
    try {
      setIsLoading(true);
      const res = await BooksService.getMeeting(
        "",
        meetingId,
        fromDate,
        toDate,
        false
      );
      if (res.result.length > 0) {
        setMeetingData(res.result[0]);
      }
      setIsLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    getMeetingData();
    setSwitchData("meeting");
  }, [reloading]);

  useEffect(() => {
    fetchProjects();
  }, [lateststate, dashboardloading, projectidcirculargraph]);

  useEffect(() => {
    fetchactivity();
  }, [dashboardloading]);

  useEffect(() => {}, [
    projectidcirculargraph,
    priorityselect,
    lateststate,
    dashboardloading,
  ]);

  // const widthwise = () => {
  //   if (width < 1410 && width >= 984) {
  //     return "auto auto";
  //   } else if (width < 984) {
  //     return "auto";
  //   } else {
  //     return "auto auto auto";
  //   }
  // };

  // window.addEventListener("resize", () => {
  //   setaddwidth(window.innerWidth);
  //   setWidth(window.innerWidth);
  //   // setScreenWidth(window.innerWidth);
  // });
  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "7%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      showSorterTooltip:false,
      width: "20%",
      sorter: (a: any, b: any) => a.Name - b.Name,
      render: (item: any, record: any) => (
        <List itemLayout="horizontal" size="small">
        <List.Item
          key={record?.id}
          style={{ padding: 0, alignItems: "center" }}
        >
          <List.Item.Meta
            style={{ padding: 0, alignItems: "center" }}
            key={`key-${record?.id}`}
            avatar={
              <Avatar
                icon={<UserOutlined />}
                size={"small"}
                src={getAvatarUrl(record)}
                alt="user"
              />
            }
            title={
              <Link
                to={`/${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`}
                state={{ userid: record?.id }}
              >
                <Typography.Paragraph
                  title={record?.name}
                  ellipsis
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {record?.name}
                </Typography.Paragraph>
              </Link>
            }
          />
        </List.Item>
      </List>
      ),
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    //   width: "10%",
    //   sorter: (a: any, b: any) => a.email - b.email,
    // },
    // {
    //   title: "Avatar",
    //   dataIndex: "thumb",
    //   key: "thumb",
    //   width: "10%",
    //   render: (item: any, record: any) => (
    //     <Avatar size={25} src={getAvatarUrl(record)} />
    //   ),
    // },
  ];

  return (
    <>
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined spin />}
        size="default"
      >
        {/* <Loader loading={isLoading} /> */}
        <div
          style={{
            height: "calc(97vh - 62px)",
            overflow: "hidden",
            // overflow: "auto"
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Row gutter={[24, 16]} className="projectListRow">
              <Col span={19}>
                <Row>
                  <Col span={24} className="projectListRow">
                    <ProjectDetail
                      projectdata={projectdata}
                      setShow={setShow}
                      setSelectedAssignees={setSelectedAssignees}
                    />
                  </Col>
                </Row>
                <Row gutter={[25, 50]}>
                  <Col span={12}>
                    <TaskReportdash
                      y={"31vh"}
                      page="TaskReportdash"
                      projectdata={projectdata}
                      linechartprojectdata={linechartprojectdata}
                      assigneechartdata={assigneechartdata}
                      retryLoading={retryLoading}
                      setlateststate={setlateststate}
                      setdashboardloading={setdashboardloading}
                      setprojectidcirculargraph={setprojectidcirculargraph}
                      priorityselect={priorityselect}
                      setpriorityselect={setpriorityselect}
                      assigneedata={assigneechartdata}
                      setalltaskchartrefresh={setalltaskchartrefresh}
                      setProjectIds={setProjectIds}
                      charttype={"tasks"}
                    />
                  </Col>
                  <Col span={12} lg={12} md={24} sm={24}>
                    <PendingTask
                      page="PendingTask"
                      props={undefined}
                      projectdata={undefined}
                      setShow={setShow}
                      setSelectedAssignees={setSelectedAssignees}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="mt-5">
                    <Col span={12} xs={24} md={24}>
                      <TaskBoard
                        page="TaskBoard"
                        setassigneedata={setassigneedata}
                        assigneedata={assigneedata}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Col span={5}>
                <div>
                  <ActiviyLogsDashboard
                    y={"750px"}
                    page="dashboard"
                    params={{ assigneeid: user?.id }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {show === "Assignees" && (
          <Modal
            title="Assignee List"
            open={!!show}
            onCancel={() =>
              setTimeout(() => {
                setShow("");
              }, 100)
            }
            footer={null}
          >
            {isLoading ? (
              <TableSkeleton
                columns={columns}
                scroll={{ y: "75vh" }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
              />
            ) : (
              <Table
                scroll={{ y: "255px" }}
                style={{
                  border: "1px solid #dfe6e9",
                  borderRadius: 3,
                  padding: "12px 7px",
                }}
                dataSource={selectedAssignees}
                columns={columns}
                rowKey="id"
                pagination={false}
              />
            )}
          </Modal>
        )}
      </Spin>
    </>
  );
};

export default Dashboard;
