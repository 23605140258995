import { Table, Avatar, Form, Button, Select, DatePicker } from "antd";
import { formatDatedatetimeyear } from "../../../utils/DateConvertor";
import { useMemo, useState } from "react";
import { useGetLog } from "../../../Services/RQMastersService";
import { useGetAllAssigneeList } from "../../../Services/RQDashboardService";

interface Filter {
  start: number;
  length: number;
  isTodayDateFilter: boolean;
  isLoginNot?: boolean;
}

const LogDetailList = () => {
  // const [, setLogData] = useState(false);
  // const [apiData, setApiData] = useState<any[]>([]);
  // const [isLoading, setIsLoading] = useState(true);
  const { RangePicker } = DatePicker;
  const [filterObject, setFilterObject] = useState<Filter>({
    start: 0,
    length: 15,
    isTodayDateFilter: false,
  });
  const {
    isLoading: isLogLoading,
    data: logData,
    refetch: api,
  } = useGetLog({ length: 1500 });
  // const { data, isLoading: logLoading, refetch } = useGetLog(filterObject);
  const { isLoading: isOptLoading, data: assigneeData } = useGetAllAssigneeList(
    { length: 1500 }
  );

  const assigneeOption = useMemo(
    () =>
      assigneeData?.result?.map((val: any) => ({
        label: val?.name,
        value: val?.id,
      })),
    [assigneeData]
  );

  // useEffect(() => {
  //   if (data && data.data) {
  //     setApiData(data.data);
  //     setIsLoading(false);
  //   }
  // }, [data]);
  // useEffect(() => {
  //    //refetch(filterObject);
  // }, [filterObject]);

  // const reload = (filter: Filter, p0: string) => {
  //   reload({
  //     start: 0,
  //     length: 15,
  //     isTodayDateFilter: true,
  //     isLoginNot: true
  // }, Routes.userRoute + "/getlog?");
  // reload(filterObject, Routes.userRoute + "/getlog?")
  //   const res = Routes.userRoute + "/getlog?";
  // };
  const logListDataSource = useMemo(() => {
    return logData?.result?.map((sprint: any, index: any) => ({
      key: index,
      createdDate: sprint.createdDate,
      user: {
        name: sprint.userName,
        imageThumb: sprint.imageThumb,
      },
    }));
  }, [logData]);

  const columns = [
    {
      title: "Last Login",
      dataIndex: "createdDate",
      key: "createdDate",
      render: formatDatedatetimeyear,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user: any) => (
        <span>
          <Avatar src={user.imageThumb} /> {user.name}
        </span>
      ),
    },
  ];
  const onClear = () => {};
  // const handleSubmit = (val:any) => console.log("val",val);
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };
  return (
    <div>
      <Form className="pt-5"
        name="normal_login"
        layout={"inline"}
        onFinish={onFinish}
        colon={false}
        initialValues={{ remember: true }}
      >
        <Form.Item label="" name={"fromTo"} className="pl-16">
          <RangePicker />
        </Form.Item>
        <Form.Item label="User" name={"userid"} >
          <Select
            placeholder="Please Select"
            options={assigneeOption}
            allowClear
            onClear={() => onClear()}
            style={{ width: "200px" }}
          />
        </Form.Item>
        <Form.Item  >
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: "45px",
          marginTop: "15px",
        }}
      >
        {/* <Checkbox
          onChange={(e) => setLogData(!logData)}
          name="checkbox-default"
        // checked={logData}
        >
          {logData ? "Not Logged in User Today" : "Logged in User"}
        </Checkbox> */}
      </div>
      {/* <Spin spinning={isLoading || logLoading}> */}
      <Table
         className='pr-16 pl-16'
        columns={columns}
        dataSource={logListDataSource}
        pagination={false}
        loading={isOptLoading}
        bordered
        scroll={{ y: 500 }}
      />
      {/* </Spin> */}
    </div>
  );
};

export default LogDetailList;
