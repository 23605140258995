import { Avatar, Button,  Table, TableColumnsType, TableProps, Tooltip, Typography } from "antd";
import { statusbackgroundgenerator, statusconvertor } from "../../utils/Util";
import AssigneeAvatar from "../../components/commoncomponents/AssigneeAvatar";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { format } from "date-fns";
import "../../common/common.css"
import TableSkeleton from "../dashboard/Common/TableSkeleton";
function IssuesTable({ userbyproject, isloading ,  }: any) {
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span className="pr-1" style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "10px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };

  const navigate = useNavigate();
  function substringmethod(data: string) {
    if (data?.length > 20) {
      return data.substring(0, 20) + "...";
    } else {
      return data;
    }
  }
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh,setRefresh] = useState<boolean>(false)
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  
  // Tabel
  const columns: TableColumnsType<any> = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "5%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "UKey",
      dataIndex: "uKey",
      width: "9%",
      render: (uKey: any, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: "rgb(204, 229, 255)",
            // color: "rgb(12, 84, 96)",
            width: "80px",
            border: "none",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // fontSize: 14,
              }}
            >
              <Typography.Text className="mr-1">
                {priorityIcon(record.priority)}
              </Typography.Text>
            </div>
            <Typography.Text
              style={{
                color: "rgb(12, 84, 96)",
                fontFamily: "Open Sans, sans-serif",
                fontSize: "12px",
                paddingTop: "3px",
              }}
              onClick={() => navigate("/task/" + record.uKey)}
            >
              {uKey}
            </Typography.Text>
          </div>
        </Button>
      ),
    },
    {
      title: "Parent",
      dataIndex: "taskId",
      key: "taskId",
      showSorterTooltip:false,
      width: "9%",
      sorter: (a: any, b: any) => a.Parent - b.Parent,
      render: (task, record) => (
        <Typography.Text>
          {record?.jira_UKey?.length > 0 ? record?.jira_UKey : "-"}
          
        </Typography.Text>
      ),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      showSorterTooltip:false,
      width: "10%",
      sorter: (a: any, b: any) => a.Project - b.Project,
      render: (task: any, record: any) => record.project[0]?.name,
    },

    {
      title: "Task",
      dataIndex: "title",
      key: "title",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Task - b.Task,
      width: "15%",
      render: (text, record) => (
        <Tooltip title={record.title}>
          <Typography.Text>{substringmethod(record.title)}</Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "tStatus",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Status - b.Status,

      render: (text: any, record: any) => (
        <Button
          size="small"
          className="resume-btn-sm"
          style={{
            backgroundColor: statusbackgroundgenerator(
              statusconvertor(record.tStatus)
            ),
             color: "rgb(12, 84, 96)",
            width: "110px",
            border: "none",
          }}
        >
          {statusconvertor(record.tStatus)}
        </Button>
      ),
    },
    {
      title: "Assignee",
      dataIndex: "assignee",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Assignee - b.Assignee,
      render: (attendees: any[]) => (
        <div>
          <Avatar.Group>
            {attendees?.map((lead, index) => (
              <AssigneeAvatar
                size={24}
                assignee={lead}
                key={index}
                index={index}
              />
            ))}
          </Avatar.Group>
        </div>
      ),
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.assignedBy - b.assignedBy,
      render: (attendees: any[]) => (
        <div style={{ alignItems: "center", width: "80px" }}>
          <Avatar.Group size="small" maxCount={2}>
            {attendees?.map((lead, index) => (
              <AssigneeAvatar
                size={24}
                assignee={lead}
                key={index}
                index={index}
              />
            ))}
          </Avatar.Group>
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.dueDate - b.dueDate,
      render: (text: any, record: any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            fontFamily: "Open Sans",
          }}
        >
          {format(new Date(record.dueDate), "dd MMM yyyy")}
        </div>
      ),
    },

    {
      title: "Duplicate of",
      dataIndex: "duplicateReference",
      key: "Duplicate of",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Duplicate - b.Duplicate,
      render: (task, record) => (
        <Typography.Text>
          {task?.duplicateReference?.join > 0 ? task.duplicateReference : "-"}
        </Typography.Text>
      ),
    },
  ];

  return (
    <div className="mt-2 mr-4 ml-4">
      {isloading ? (
        <TableSkeleton
          columns={columns}
          scroll={{ y: "75vh" }}
          style={{
            scrollbarWidth: "none",
            borderTop: "solid 1px #b2bec3",
          }}
        />
      ) : (
        <Table
       
          style={{ borderTop: "solid 1px #b2bec3" }}
          scroll={{ y: "71vh" }}
          columns={columns}
          dataSource={userbyproject}
          onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams?.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
          className="issuestabel capTask-custom-table"
          locale={{
            emptyText: (
              <Typography.Title
                level={4}
                style={{
                  textAlign: "center",
                  paddingTop: "47px",
                }}
              >
                {/* <NoData message={undefined} /> */}
                <img
                  style={{ width: 140, paddingBottom: 15 }}
                  // src={process.env.PUBLIC_URL + "/noTask.svg"}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
                <div style={{ textAlign: "center" }}>
                  <h3
                    style={{
                      margin: 0,
                      fontFamily: "Open Sans",
                      fontSize: 16,
                      color: "rgb(72 81 110)",
                    }}
                  >
                    Please select project
                  </h3>
                  <h3
                    style={{
                      margin: 0,
                      fontFamily: "Open Sans",
                      fontSize: 16,
                      color: "rgb(72 81 110)",
                    }}
                  >
                    And Click the search button to get Task
                  </h3>
                </div>
              </Typography.Title>
            ),
          }}
        />
      )}
    </div>
  );
}
export default IssuesTable;
