import { useEffect, useState } from "react";
import { Modal, Button, Select, Spin, notification, TreeSelect } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../Services/HTTPCalls";
// import BooksService from "../../Services/Books";
import { useSelector } from "react-redux";
import { projectData } from "../../store/slices/project-slice";

const { Option } = Select;

// interface AdduserProps {
//   isOpen2: boolean;
//   openModal2: () => void;
//   closeModal2: () => void;
//   changeassignee: boolean;
//   setchangeassignee: (value: boolean) => void;
//   projectassigneearray: any[];
//   fetchassignee: () => void;
//   projectdataassignee: any[];
//   fetchProjects: () => void;
// }

const Adduser = ({
  isOpen2,
  closeModal2,
  changeassignee,
  setchangeassignee,
  projectassigneearray,
  fetchassignee,
  projectdataassignee,
}: any) => {
  const [projectdata, setprojectdata] = useState<any>({});
  const [alluseroption, setalluseroption] = useState([]);
  const [assigneearray, setassigneearray] = useState<any[]>(
    projectassigneearray || []
  );
  const [loading, setloading] = useState(false);

  const fetchalluser = async () => {
    try {
      setloading(true);
      const res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", {
        length: 15000,
      });

      const userdatatype = res.result.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setalluseroption(userdatatype);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setloading(false);
    }
  };

  const handlesubmit = async () => {
    setloading(true);
    try {
        await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.addEdit, {
        id: project?.id,
        name: projectdata?.name,
        assigneeIds: assigneearray,
         type: 1,
        //  company: "63a959074e041fdc2ae91ce8",
        //  alias: projectdata?.alias,
        // description: projectdata?.description,
        // imageThumbPath: projectdata?.imageThumbPath,
        // imagePath: projectdata?.imagePath,
      });
      notification.success({
        message: "Success",
        description: "Assignee updated successfully",
        placement:'bottomLeft',
        icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
      });
      closeModal2();
      setchangeassignee(!changeassignee);
      fetchassignee();
    } catch (error) {
      console.error("Failed to update assignee:", error);
    } finally {
      setloading(false);
    }
  };
  const project = useSelector(projectData);

  useEffect(() => {
    setprojectdata(
      projectdataassignee?.find((item: any) => item?.id === project?.id)
    );
    fetchalluser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen2, projectassigneearray, projectdataassignee]);
  return (
    <Modal
      open={isOpen2}
      title="Add Assignee"
      onCancel={closeModal2}
      loading={loading}
      footer={[
        <Button key="cancel" onClick={closeModal2}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handlesubmit}
          loading={loading}
        >
          Add
        </Button>,
      ]}
    >
      {loading && <Spin size="large" className="loading-spinner" />}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%" }}>
          {/* <Select
            mode="multiple"
            placeholder="Choose Label"
            options={alluseroption}
            // defaultValue={projectassigneearray.map((item: any) => item.value)}
            value={assigneearray}
            onChange={(value) => setassigneearray(value)}
            style={{ width: "100%" }}
            optionFilterProp="label"
            showSearch
          >
            {alluseroption.map((option: any) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select> */}
          <TreeSelect
          className="treeselectadduser"
            multiple
            treeData={alluseroption}
            value={assigneearray}
            onChange={(value) => setassigneearray(value)}
            placeholder="Choose Label"
            style={{ width: "100%" }}
            treeCheckable
            treeNodeFilterProp="label"
            showSearch
          />
        </div>
      </div>
    </Modal>
  );
};

export default Adduser;
