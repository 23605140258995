
import { Avatar } from "antd";
import { formatDatedatetimeyeargforcreatetask } from "../../../utils/DateConvertor";

const LoginUser = ({ logindata }:any) => {


    return (
        <>
            <div style={{ height: "220px", overflowX: "scroll" }}>
                {
                    logindata?.map((item: any) => (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px" }}>
                            <div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ paddingRight: "10px" }}>
                                        <Avatar src={item.imageThumb} />
                                    </div>
                                    <div>
                                        <h4 style={{ marginTop: "5px", marginBottom: "5px", fontSize: "14px", fontWeight:400 }}>{item.userName}</h4>
                                    </div>
                                </div>

                            </div>
                            <div style={{ paddingRight: "10px" }}>
                                <p style={{ fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>{formatDatedatetimeyeargforcreatetask(item.createdDate,"")}</p>
                            </div>
                        </div>
                    ))
                }



            </div>
        </>
    )
}

export default LoginUser;