import React, { useEffect, useRef, useState } from 'react';
import Loader from '../../components/commoncomponents/Loader';
import { ImagePopup } from './ImagePopup';

interface Props {
  file: {
    path: string;
  };
  onClose: () => void;
}

const FilePreview: React.FC<Props> = ({ file, onClose }) => {
  const [showDocViewer, setShowDocViewer] = useState(true);
  const [enableTimer, setEnableTimer] = useState(true);
  const [frameDataLoaded, setFrameDataLoaded] = useState(true);

  const refreshInterval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (file?.path && enableTimer) {
      refreshInterval.current = setInterval(() => {
        setShowDocViewer((prev) => !prev);
      }, 5000);
    }
    return () => {
      if (refreshInterval.current) {
        clearInterval(refreshInterval.current);
      }
    };
  }, [file.path, enableTimer]);

  return (
    <ImagePopup onClose={onClose} style={{ width: '80%' }}>
      {!file.path || !showDocViewer ? (
        <Loader loading={true} />
      ) : (
        <>
          <iframe
            title="img"
            width="70%"
            height="90%"
            src={file.path}
            style={{ border: 'none' }}
            onLoad={() => {
              setFrameDataLoaded(false);
              setEnableTimer(false);
            }}
          />
          {frameDataLoaded && <Loader loading={true} />}
        </>
      )}
    </ImagePopup>
  );
};

export default FilePreview;
