// import React, { useEffect, useState } from "react";
// import { Table, Avatar, Badge, Row, Col, Tooltip, Typography } from "antd";
// import { formatDatedatetimeyeargforcreatetask } from "../../utils/DateConvertor";
// import AssigneeAvatar from "../commoncomponents/AssigneeAvatar";
// // import '../MeetingTable/MeetingTable.css'
// const MeetingTable = ({
//   meetingData,
//   closeModal,
//   isOpen,
//   setUniversalLoadingSate,
//   uniqueMeet,
// }: any) => {
//   // const user = useSelector(userData);
//   // const [currentMeetingData, setCurrentMeetingData] = useState({});
//   const [combinedData, setCombinedData] = useState<any[]>([]);
//   useEffect(() => {
//     const combined = [
//       ...(meetingData?.today ?? []),
//       ...(meetingData?.old ?? []),
//     ];
//     setCombinedData(combined);
//   }, [meetingData]);

//   // const handleOpenModal = (data: any) => {
//   //   openModal();
//   //   setCurrentMeetingData(data);
//   //   setUniqueMeet("edit");
//   // };
//   function substringmethod(data: string) {
//     if (data?.length > 35) {
//       return data.substring(0, 35) + "...";
//     } else {
//       return data;
//     }
//   }
//   const columns = [
//     {
//       title: "Project",
//       dataIndex: "projectDetail",
//       key: "project",
//       width: "19%",
//       render: (projectDetail: string | any[]) => (
//         <div>
//           <Typography.Text style={{ color: "#5c6577", fontFamily: "Open Sans" }}>
//             {projectDetail.length > 0 && projectDetail[0].name}
//           </Typography.Text>
//         </div>
//       ),
//     },
//     {
//       title: "Description",
//       dataIndex: "meetingGoal",
//       key: "meetingGoal",
//       width: "22%",
//       render: (meetingGoal: any) => (
//         <Tooltip title={meetingGoal}>
//           <Typography.Text ellipsis>
//             <p
//               style={{ color: "#5c6577", fontSize: "14px", fontFamily: "Open Sans" }}
//             >
//               {substringmethod(meetingGoal)}
//             </p>
//           </Typography.Text>
//         </Tooltip>
//       ),
//     },
//     {
//       title: "Lead",
//       dataIndex: "meetingLead",
//       key: "lead",
//       render: (meetingLead: any) => (
//         <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
//           <Avatar.Group size="small" maxCount={2}>
//             {meetingLead?.map((lead: any, index: any) => (
//               <AssigneeAvatar assignee={lead} key={index} index={index} />
//             ))}
//           </Avatar.Group>
//         </div>
//       ),
//     },
//     {
//       title: "Place",
//       dataIndex: "meetingPlace",
//       key: "place",
//       render: (text: any) => (
//         <div
//           style={{ marginRight: "35px", width: "118px", alignItems: "center" }}
//         >
//           <p style={{ color: "#5c6577", fontSize: "14px", fontFamily: "Open Sans" }}>
//             {text}
//           </p>
//         </div>
//       ),
//     },
//     {
//       title: "Date",
//       dataIndex: "date",
//       key: "date",
//       render: (date: any) => (
//         <div
//           style={{ marginRight: "35px", width: "115px", alignItems: "center" }}
//         >
//           <p style={{ color: "#5c6577", fontSize: "14px", fontFamily: "Open Sans" }}>
//             {formatDatedatetimeyeargforcreatetask(date, "normal")}
//           </p>
//         </div>
//       ),
//     },
//     { 
//       title: "Team",
//       dataIndex: "attendees",
//       key: "team",
//       render: (attendees: any[]) => (
//         <div style={{ alignItems: "center", width: "80px", paddingTop: "8px" }}>
//           <Avatar.Group size="small" maxCount={2}>
//             {attendees?.map((lead, index) => (
//               <AssigneeAvatar assignee={lead} key={index} index={index} />
//             ))}
//           </Avatar.Group>
//         </div>
//       ),
//     },
//     //     {
//     //   title: '',
//     //   key: 'action',
//     //   render: (record: { attendees: any[]; meetingLead: any[]; }) =>
//     //     (record.attendees && record.attendees.some((item) => item.id === user?.id)) ||
//     //       (record.meetingLead && record.meetingLead.some((item) => item.id === user?.id)) ? (
//     //       <Badge dot>
//     //         <Button onClick={() => handleOpenModal(record)} style={{ cursor: 'pointer' }}>
//     //           <CheckCircleOutlined />
//     //         </Button>
//     //       </Badge>
//     //     ) : null,
//     // },
//   ];
//   return (
//     <>
//       <div>
//         <Row>
//           <Col span={24} className="mt-1">
//             <Typography.Link className="ml-6 pr-2">Meetings</Typography.Link>
//             <Badge count={combinedData.length} color={"blue"} />
//           </Col>
//           <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
//             <Table
//               dataSource={combinedData}
//               locale={{
//                 emptyText: (
//                   <div style={{ textAlign: "center", marginTop: "10%" }}>
//                     <img
//                       src={process.env.PUBLIC_URL + "/No Data.jpg"}
//                       alt="Data"
//                       height={250}
//                     />
//                     <div style={{ textAlign: "center" }}>
//                       <h3
//                         style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}
//                       >
//                         No Meetings Yet
//                       </h3>
//                       <p
//                         style={{
//                           marginTop: 4,
//                           fontFamily: "Open Sans",
//                           fontSize: 14,
//                         }}
//                       >
//                         You have no upcoming meetings to display.
//                       </p>
//                     </div>
//                   </div>
//                 ),
//               }}
//               columns={columns}
//               pagination={false}
//               scroll={{ y: "74vh" }}
//               className="mt-1 ml-5"
//               loading={false}
//             />
//           </Col>
//         </Row>
//       </div>

//       {isOpen && (
//         <MeetingTable
//           uniqueMeet={uniqueMeet}
//           setUniversalLoadingSate={setUniversalLoadingSate}
//           isOpen={isOpen}
//           closeModal={closeModal}
//           // meetingData={currentMeetingData}
//         />
//       )}
//     </>
//   );
// };

// export default MeetingTable;
import React, { useEffect, useState } from "react";
import { Table, Avatar, Badge, Row, Col, Tooltip, Typography } from "antd";
import { formatDatedatetimeyeargforcreatetask } from "../../utils/DateConvertor";
import AssigneeAvatar from "../commoncomponents/AssigneeAvatar";
import moment from "moment"; // Make sure to install moment for date comparison

const MeetingTable = ({
  meetingData,
  closeModal,
  isOpen,
  setUniversalLoadingSate,
  uniqueMeet,
}: any) => {
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const today = moment().startOf("day"); // Get today's date

  useEffect(() => {
    const combined = [
      ...(meetingData?.today ?? []),
      ...(meetingData?.old ?? []),
    ];
    const combinedWithIsToday = combined.map((item: any) => ({
      ...item,
      isToday: moment(item.date).isSame(today, "day"),
    }));
    setCombinedData(combinedWithIsToday);
  }, [meetingData]);

  function substringmethod(data: string) {
    if (data?.length > 35) {
      return data.substring(0, 35) + "...";
    } else {
      return data;
    }
  }

  const columns = [
    {
      title: "Project",
      dataIndex: "projectDetail",
      key: "project",
      width: "19%",
      render: (projectDetail: string | any[], record: any) => (
        <div>
          <Typography.Text style={{ color: "#5c6577", fontFamily: "Open Sans" }}>
            {projectDetail.length > 0 && projectDetail[0].name}
            {record.isToday && (
              <span style={{ color: "#3498db", marginLeft: 8 }}>Today</span>
            )}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "meetingGoal",
      key: "meetingGoal",
      width: "22%",
      render: (meetingGoal: any) => (
        <Tooltip title={meetingGoal}>
          <Typography.Text ellipsis>
            <p
              style={{ color: "#5c6577", fontSize: "14px", fontFamily: "Open Sans" }}
            >
              {substringmethod(meetingGoal)}
            </p>
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Lead",
      dataIndex: "meetingLead",
      key: "lead",
      render: (meetingLead: any) => (
        <div style={{ display: "flex", alignItems: "center", width: "100px" }}>
          <Avatar.Group size="small" maxCount={2}>
            {meetingLead?.map((lead: any, index: any) => (
              <AssigneeAvatar assignee={lead} key={index} index={index} />
            ))}
          </Avatar.Group>
        </div>
      ),
    },
    {
      title: "Place",
      dataIndex: "meetingPlace",
      key: "place",
      render: (text: any) => (
        <div
          style={{ marginRight: "35px", width: "118px", alignItems: "center" }}
        >
          <p style={{ color: "#5c6577", fontSize: "14px", fontFamily: "Open Sans" }}>
            {text}
          </p>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: any) => (
        <div
          style={{ marginRight: "35px", width: "115px", alignItems: "center" }}
        >
          <p style={{ color: "#5c6577", fontSize: "14px", fontFamily: "Open Sans" }}>
            {formatDatedatetimeyeargforcreatetask(date, "normal")}
          </p>
        </div>
      ),
    },
    {
      title: "Team",
      dataIndex: "attendees",
      key: "team",
      render: (attendees: any[]) => (
        <div style={{ alignItems: "center", width: "80px", paddingTop: "8px" }}>
          <Avatar.Group size="small" maxCount={2}>
            {attendees?.map((lead, index) => (
              <AssigneeAvatar assignee={lead} key={index} index={index} />
            ))}
          </Avatar.Group>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={24} className="mt-1">
            <Typography.Link className="ml-6 pr-2">Meetings</Typography.Link>
            <Badge count={combinedData.length} color={"blue"} />
          </Col>
          <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              dataSource={combinedData}
              locale={{
                emptyText: (
                  <div style={{ textAlign: "center", marginTop: "10%" }}>
                    <img
                      src={process.env.PUBLIC_URL + "/No Data.jpg"}
                      alt="Data"
                      height={250}
                    />
                    <div style={{ textAlign: "center" }}>
                      <h3
                        style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}
                      >
                        No Meetings Yet
                      </h3>
                      <p
                        style={{
                          marginTop: 4,
                          fontFamily: "Open Sans",
                          fontSize: 14,
                        }}
                      >
                        You have no upcoming meetings to display.
                      </p>
                    </div>
                  </div>
                ),
              }}
              columns={columns}
              pagination={{ pageSize: 10 }}
              scroll={{ y: "74vh" }}
              className="mt-1 ml-5"
              loading={false}
            />
          </Col>
        </Row>
      </div>

      {isOpen && (
        <MeetingTable
          uniqueMeet={uniqueMeet}
          setUniversalLoadingSate={setUniversalLoadingSate}
          isOpen={isOpen}
          closeModal={closeModal}
          // meetingData={currentMeetingData}
        />
      )}
    </>
  );
};

export default MeetingTable;
