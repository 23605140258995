// import Button from '@atlaskit/button';
// import Modal, {
//     ModalBody,
//     ModalFooter,
//     ModalHeader,
//     ModalTitle,
//     ModalTransition,
// } from '@atlaskit/modal-dialog';
// import CrossIcon from '@atlaskit/icon/glyph/cross';
// import { token } from '@atlaskit/tokens';
// import { N500 } from '@atlaskit/theme/colors';
import { formatDatedatetimeyeargforcreatetask } from '../../../utils/DateConvertor';
import { Button, Modal, Table } from 'antd';


const AllMilestoneList = ({ allMilestone, isOpen2, closeModal2 }: any) => {

    const columns = [

        {
            title: "Project Name",
            dataIndex: "Project Name",
            key: "Project Name",
            width: "20%",
            render: (item: any) => <div className="pl-4">{item?.project?.name}</div>,
        },
        {
            title: "Milestone Name",
            dataIndex: "Milestone Name",
            key: "Milestone Name",
            width: "20%",
            render: (item: any) => <div className="pl-4">{item.name}</div>,
        },
        {
            title: "Deadline",
            dataIndex: "Deadline",
            key: "Deadline",
            width: "20%",
            render: (item: any) => <div className="pl-4">{formatDatedatetimeyeargforcreatetask(item.deadline, "")}</div>,
        },


    ];

    const createHead = (withWidth: boolean) => {
        return {
            cells: [
                {
                    key: "Project Name",
                    content: "Project Name",
                    width: 10
                },
                {
                    key: "Milestone Name",
                    content: "Milestone Name",
                    width: 6
                },
                {
                    key: "Deadline",
                    content: "Deadline",
                    width: 6
                }

            ],
        };
    };

    const head = createHead(true);

    return (
        <>
            {

                isOpen2 &&
                <Modal onCancel={closeModal2} width="large">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e8ecf3",
                        paddingLeft: 10,
                        fontSize: 14,
                        paddingTop: 5,
                        paddingRight: 10,
                        justifyContent: "space-between"
                    }}>
                        <p style={{ fontSize: 14, fontWeight: 600 }}>Milestone list</p>
                        <div>
                            <Button onClick={() => {

                                closeModal2()

                            }}>
                                {/* <CrossIcon
                                    label="Close Modal"
                                    primaryColor={token('color.text.subtle', N500)}
                                /> */}
                            </Button>
                        </div>
                    </div>


                    <div>
                        {
                            allMilestone.length > 0 ?
                                <div style={{
                                    overflowX: "scroll",
                                    padding: "25px"
                                }}>

                                    <Table
                                        bordered
                                        scroll={{ y: 600 }}
                                        dataSource={allMilestone}
                                        pagination={false}
                                        columns={columns}

                                    />
                                    {/* <DynamicTable
                                        head={head}
                                            rows={allMilestone.map((data: any, index: number) => ({
                                            key: `row-${index}-${data.id}`,
                                            isHighlighted: false,
                                                cells: [
                                                {
                                                    content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{data?.project?.name}</p>
                                                },
                                                {
                                                    content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{data.name}</p>
                                                },
                                                {
                                                    content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{formatDatedatetimeyeargforcreatetask(data.deadline,"")}</p>
                                                }

                                            ],
                                        }))}
                                        defaultPage={1}
                                        loadingSpinnerSize="large"
                                    /> */}
                                </div>
                                :
                                <div style={{ marginBottom: "10%" }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "10%"
                                    }}>
                                        <img src={process.env.PUBLIC_URL + "/noTask.svg"} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <h3 style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}>No task yet</h3>
                                        <p style={{ marginTop: 4, fontFamily: "Open Sans", fontSize: 14 }}>You have no available task to display</p>
                                    </div>
                                </div>
                        }
                    </div>



                </Modal>

            }
        </>
    )
}

export default AllMilestoneList