import { useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";

export enum SaveType{
  Single = 1,
  Multiple = 2
}

export const AddEditApis = {
  useTask: (type:SaveType) =>
    useMutation({
      mutationFn: async (payload:any) => {
        const result = await addEditTask(payload,type);
        return result
      },
      onSuccess: (data: any) => {
        if (!data?.error) {
          queryClient.invalidateQueries({
            queryKey: ["addEditTask"],
          })
        }
      }
    }),
  useBranch: (payload: any={}) => {
    return useQuery({ 
      queryKey: ["taskBranchListOptions",payload],
      queryFn: () => getBranchList(payload),
    })
  },
  useIssue: (payload: any={}) => {
    return useQuery({
      queryKey: ["taskIssueListOptions",payload],
      queryFn: () => getIssueList(payload),
    })
  },
  useProject: (payload: any) => {
    return useQuery({
      queryKey: ["taskProjectList",payload],
      queryFn: () => getProjectsList(payload),
    })
  },
  useUser: (payload: any={}) => {
    return useQuery({
      queryKey: ["taskUserListOptions",payload],
      queryFn: () => getUserList(payload),
    })
  },
  useAssignee: (payload: any) => {
    return useQuery({
      queryKey: ["taskAssigneeListOptions",payload],
      queryFn: () => getAssigneeList(payload),
    })
  },
  useUkey: (projectId: string,currentUkey:string) => {
    return useQuery({
      queryKey: ["taskUkey",{projectId,currentUkey}],
      queryFn: () => getCurrentUkey(projectId),
    })
  },
}

const addEditTask = async (payload: any,type:SaveType) => {
  const url = type===SaveType.Single? HTTPSCalls.ENDPOINTS.task.post:HTTPSCalls.ENDPOINTS.task.multipleTasks
  return HTTPSCalls.POST(`${url}`, payload)
};

const getCurrentUkey = async (projectId: string) =>
  await HTTPSCalls.GET(
    HTTPSCalls.ENDPOINTS.project.getUkey+"?",{
      projectId
    }
  )

const getAssigneeList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.getAssigneeListOptions,{
    ...payload
  });

const getUserList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.getUserListOptions,{
    ...payload
  });

const getBranchList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.branch.getBranchListOptions,{
    ...payload
  });

const getIssueList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.issueType.getLabelListOptions,{
    ...payload
  });

const getProjectsList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.getProjectsListOptions, {
    ...payload
  });
