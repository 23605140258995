import { Draggable } from "@hello-pangea/dnd";
import { Avatar, Button, Card, Col, Row, Select, Tooltip, Typography } from "antd";
import { STATUS_ARRAY, statusArray } from "../../constants/commonConstants";
import "../../common/common.css";
import AssigneeAvatar from "../commoncomponents/AssigneeAvatar";
import { resetUserData } from "../../store/slices/user-slice";

export const DraggableItemGridView = ({
  task,
  index,
  size,
  page,
  showLoading,
  sourceDroppableId,
  onSelectDropDown,
  setRefresh,
}: any) => {
  //  const dispatch = resetRefreshState();
  function substringmethod(data: string) {
    if (data?.length > 6) {
      return data.substring(0, 6) + "...";
    } else {
      return data;
    }
  }
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };

  // const handleChange = (v: any) => {
  //   const result = {
  //     destination: {
  //       index: 0,
  //       droppableId: STATUS_ARRAY[v],
  //     },
  //     source: {
  //       index: index,
  //       droppableId: STATUS_ARRAY[task?.TStatus],
  //     },
  //     draggableId: task?._id,
  //   };

  //   onDragEnd(result);
  // };
  const handleChange = (v: any) => {
    console.log(STATUS_ARRAY[v?.value],'ash');
    
    onSelectDropDown({
      destinationDroppableId: STATUS_ARRAY[v?.value],
      sourceIndex: index,
      sourceDroppableId:
        page === "backlog" ? sourceDroppableId : STATUS_ARRAY[task?.TStatus],
      task: task,
    });
    resetUserData();
  };

  return (
    <Draggable draggableId={String(task?._id)} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            //   marginBottom: 1,
            //   margin: "5px",
            //   padding: "5px",
            //   border: "1px solid red",
            //   backgroundColor: snapshot.isDragging ? "#f0f0f0" : "white",
            ...provided.draggableProps.style,
          }}
        >
          <Card
            style={{
              // margin: 4,
              // padding: 5,
              borderRadius: "3px",
              border: "none",
              backgroundColor: "white",
              cursor: "grab",
            }}
            className="itemshover mt-2"
            hoverable
          >
            <Row>
              <Col lg={10} className="pr-1">
                <Tooltip title={task.Title}>
                  <Typography.Text onClick={() => showLoading(task?.UKey)}>
                    {substringmethod(task?.Title)}
                  </Typography.Text>
                </Tooltip>
              </Col>

              <Col lg={12} span={10} md={17}>
                <Select
                  // variant="borderless"
                  // className="borderLessInput"
                  // className="customselect"
                  labelInValue
                  className="taskbord"
                  size="small"
                  style={{ width: 102  }}
                  options={statusArray}
                  value={task?.TStatus}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(e) => handleChange(e)}
                  // style={{ width: "100px", height: 20 }}
                />
              </Col>
            </Row>
            <Row className="pt-2">
              <Col lg={2}>
                <Typography.Text onClick={() => showLoading(task?.UKey)}>
                  {priorityIcon(task.Priority)}
                </Typography.Text>
              </Col>
              <Col lg={10} md={7} onClick={() => showLoading(task?.UKey)}>
                <Button
                  size="small"
                  className="resume-btn-sm  "
                  style={{
                    // backgroundColor: "rgb(204, 229, 255)",
                    color: "rgb(16, 124, 16)",
                    backgroundColor: "rgb(223, 246, 221)",
                    width: "50px",
                    border: "none",
                  }}
                
                >
                  {task?.UKey}
                </Button>
              </Col>
              <Col lg={12} md={3} style={{ textAlign: "end" }}>
                <Avatar.Group
                  size={26}
                  maxCount={1}
                  maxPopoverTrigger="click"
                  maxPopoverPlacement="bottom"
                  className="cursorPointer"
                >
                  {task?.Assignee?.map((assignee: any, index: any) => (
                    <>
                      <AssigneeAvatar
                        size={size}
                        key={index}
                        assignee={assignee}
                        index={index}
                      />
                    </>
                  ))}
                </Avatar.Group>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItemGridView;
