import { Card, Col, Row, Skeleton } from "antd";
import * as React from "react";
import Meta from "antd/es/card/Meta";

interface ISkeletonTableProps {
  rows?: number;
  rowBorder?: boolean;
}

const ActivityMessageCardSkeleton = ({
  rows = 10,
  rowBorder = true,
}: ISkeletonTableProps) => {
  const skeletonCards = Array.from({ length: rows }).map((_, index) => (
    <Card
      key={index}
      className="main-border mt-1"
      style={{ minWidth: 340, height: 135 }}
    >
      <Row align="middle" style={{ width: "100%" }}>
        <Col flex="auto">
          <Meta
            title={
              <Row justify="space-between" align="middle">
                <Col span={5}>
                  <Skeleton.Avatar size={38} active shape="circle" />
                </Col>
                <Col span={19}>
                  <Skeleton.Input style={{ width: "100%" }} active />
                  <Skeleton.Input
                    style={{
                      width: "95%",
                      marginTop: 4,
                      marginRight: "40%",
                    }}
                    active
                  />
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
      <Row align="middle" justify="start">
        <Col flex="auto">
          <Meta
            title={
              <Row justify="space-between" align="middle">
                <Col span={8} style={{ marginTop: 4 }}>
                  <Skeleton.Button
                    style={{
                      height: 20,
                      width: "50%",
                      marginRight: "30%",
                    }}
                    active
                  />
                </Col>
                <Col span={16}>
                  <Skeleton.Button
                    style={{
                      height: 20,
                      width: "100%",
                      marginTop: 4,
                      marginRight: "70%",
                    }}
                    active
                  />
                </Col>
                <Col span={24}>
                  <Skeleton.Input
                    active
                    style={{ width: "100%", marginLeft: "4%" }}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
    </Card>
  ));

  return <>{skeletonCards}</>;
};

export default React.memo(ActivityMessageCardSkeleton);
