import React from "react";
import { Row, Col, Avatar, Card, Typography } from "antd";
import { convertTo12HourFormat } from "../../../utils/DateConvertor";
import "../UpcomingMeeting/MeetingFilter.css";
import { initialsgenerator, textnumbergenerator } from "../../../utils/Util";

const UpcomingMeeting = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  meetingId,
  meetingData,
  setMeetingData,
  isLoading,
}: any) => {
  console.log(meetingId);
  return (
    <>
      <Row gutter={16}>
        <Col lg={24} md={22}>
          <div className="meetingfromdate">
            <Card
              className="pl-2"
              loading={isLoading}
              title="Upcoming Meeting"
              size="small"
              style={{
                width: "40vw",
                marginTop: 20,
                height: "74vh",
                overflowY: "scroll",
                scrollbarWidth: "none",
                border: "1px solid #f3f4fa",
              }}
            >
              <div>
                {meetingData?.upcoming && meetingData.upcoming.length > 0 ? (
                  meetingData.upcoming.map((data: any, index: number) => (
                    <Card
                      key={index} // Use a unique key
                      className="mt-3 card-upcoming"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#e9f8ff",
                        height: 140,
                      }}
                    >
                      <Typography.Text
                        style={{ fontWeight: 600, fontSize: 16 }}
                      >
                        {data?.projectDetail?.length > 0 &&
                          data?.projectDetail[0].name}
                      </Typography.Text>
                      <p className="mb-1">{data.meetingGoal}</p>
                      <p className="mb-0">{data.meetingPlace}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>
                            {convertTo12HourFormat(
                              data.meetingTime?.startTime || ""
                            )}
                          </span>{" "}
                          -{" "}
                          <span>
                            {convertTo12HourFormat(
                              data.meetingTime?.endTime || ""
                            )}
                          </span>
                        </div>
                        <div>
                          <Avatar.Group maxCount={2}>
                            {data.attendees?.map((d: any) => (
                              <Avatar
                                key={d.id}
                                src={
                                  d.profileImage ||
                                  `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                                    d.name
                                  )}-${textnumbergenerator(d.name)}.png?ssl=1`
                                }
                              >
                                {d.name}
                              </Avatar>
                            ))}
                          </Avatar.Group>
                        </div>
                      </div>
                    </Card>
                  ))
                ) : (
                  <div style={{ textAlign: "center", marginTop: "36%" }}>
                    <img
                      src={process.env.PUBLIC_URL + "/meeting.jpg"}
                      alt="No Meeting"
                      height={200}
                    />
                    <div style={{ textAlign: "center" }}>
                      <h3
                        style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}
                      >
                        No Meeting yet
                      </h3>
                      <p
                        style={{
                          marginTop: 4,
                          fontFamily: "Open Sans",
                          fontSize: 14,
                        }}
                      >
                        You have no upcoming Meeting to display
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UpcomingMeeting;
