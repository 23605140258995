import { jwtDecode } from "jwt-decode";
import { AppDispatch, RootState, store } from "../store";
import { resetUserData } from "../store/slices/user-slice";
import { resetProjectData } from "../store/slices/project-slice";

export const isTokenNotExpired = (token: string) => {
  interface DecodedToken {
    id: string;
    name: string;
    email: string;
    role: string;
    exp: number;
    iss: string;
  }

  try {
    const decoded: DecodedToken = jwtDecode(token);

    const expirationDate = new Date(decoded.exp * 1000);

    const currentDate = new Date();

    if (expirationDate > currentDate) {
      // console.log("Token is valid");
      return true;
    } else {
      // console.log("Token is expired");
      return false;
    }
  } catch (error) {
    // console.error("Invalid token", error);
    return false;
  }
};

export const clearRedux = () => {
    const dispatch: AppDispatch = store.dispatch;
    const currentState: RootState = store.getState();
    dispatch(resetUserData());
    dispatch(resetProjectData());
};
