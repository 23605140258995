import { ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, ConfigProvider, Input, Row, Select } from "antd";
import "../../common/common.css";

export const ToothbarReport = ({
  setprojectuserarr,
  projectuserarrfilter,
  setdefaultCount,
  defaultCount,
}: any) => {
  const handlesearchfilter = (value: string) => {
    let filtereddata = projectuserarrfilter.filter((item: any) => {
      if (
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.email.toLowerCase().includes(value.toLowerCase())
      ) {
        return item;
      }
    });
    setprojectuserarr(filtereddata);
  };
  // const styled = {
  //   backgroundColor: "#f3f4fa",
  //   display: "flex",
  //   alignItems: "center",
  //   marginBottom: 7,
  //   // padding: "20px 74px",
  // };
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#95a5a6",
            fontSize: 14,
            colorPrimaryHover: "none",
            // controlHeight: 32,
            fontSizeIcon: 10,
            // borderRadius: 4,
            paddingSM: 4,
            paddingXS: 4,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 27,
            controlHeightLG: 27,

            // fontSize: 14,
            // controlHeight: 28,
            // borderRadius: 1,
            algorithm: true,
            fontWeightStrong: 600,
          },
          DatePicker: {
            controlHeight: 27,
            controlHeightLG: 27,
          },
          Input: {
            controlHeight: 27,
            controlHeightLG: 27,
          },
        },
      }}
    >
      {/* <Row style={styled}>
        <Col span={20} lg={20} xs={3} sm={24} md={9} xl={4} xxl={3} className="pl-10">
          <Select
            // className="commonstyle"
            style={{ width: 250 }}
            value={defaultCount}
            placeholder="Select..."
            options={[
              { label: "All", value: 0 },
              { label: "Active", value: 1 },
              { label: "InActive", value: 2 },
            ]}
            onChange={(item: any) => {
              setdefaultCount(item);
            }}
            defaultValue={defaultCount}
            showSearch
            onSearch={onSearch}
            optionFilterProp="label"
          />
        </Col>
        <Col span={4} lg={4} xs={3} sm={24} md={9} xl={4} xxl={3}>
          <Input
            // className="Reportinput"
            style={{
              width: 250,
              // border: "none",
              // borderRadius: 0,
              // padding: "8px 6px",
              // height: "2.57em",
              // cursor: "pointer",
            }}
            suffix={<SearchOutlined />}
            // variant="filled"
            placeholder="Name, Email"
            allowClear
            onChange={(e: any) => handlesearchfilter(e.target.value)}
            type="text"
          />
        </Col>
      </Row> */}
      <Row className="pb-4">
        <Col span={21}>
          <Input
            // className="Reportinput"
            variant="borderless"
            style={{
              border: "1px solid #d9d9d9",
              width: 150,
              backgroundColor: "white",
            }}
            suffix={<SearchOutlined />}
            placeholder="Name, Email"
            allowClear
            onChange={(e: any) => handlesearchfilter(e.target.value)}
            type="text"
          />
        </Col>

        <Col span={3}>
          {/* <Button
            className="ml-"
            icon={
              <ReloadOutlined
                style={{ color: "rgb(61, 153, 37)", justifyContent: "center" }}
              />
            }
          /> */}

          <Select
            // className="commonstyle"
            // className="ml-7"
            className="ant-select-selector ml-7"
            variant="borderless"
            style={{
              border: "1px solid #d9d9d9",
              width: 150,
              backgroundColor: "white",
            }}
            value={defaultCount}
            placeholder="Select..."
            options={[
              { label: "All", value: 0 },
              { label: "Active", value: 1 },
              { label: "InActive", value: 2 },
            ]}
            onChange={(item: any) => {
              setdefaultCount(item);
            }}
            defaultValue={defaultCount}
            showSearch
            onSearch={onSearch}
            optionFilterProp="label"
          />
        </Col>
      </Row>
    </ConfigProvider>
  );
};
