import { CollapseProps, Collapse, Col, Row } from 'antd';
import * as React from 'react';
import "../../common/common.css"
import { ArrowRightOutlined, BookOutlined, BranchesOutlined, PartitionOutlined, ProjectOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { AppRoutesPath } from '../../Routes/AppRoutesPath';
import { Link } from 'react-router-dom';
interface ISettingProps {
}

export const Setting = (props: ISettingProps) => {
    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    return (
        <>
            <Row gutter={[24, 16]} className='mb-4 roundedCornerMedium bg-white'>
                <Col span={24}>
                    <Row gutter={[24, 16]} className='headingSettings border-bottom'>
                        <p className='m-0'>Project</p>
                    </Row>
                    <Row className='px-4'>

                        <Link className='py10 cursor text-dark w100 ' to={`${AppRoutesPath.setting.project}`}>
                            <Row gutter={[24, 16]} justify={"space-between"}>
                                <div>
                                    <Row>
                                        <ProjectOutlined className='settingsIcon' />
                                        <div className='ms-3'>
                                            <span className='d-block'>
                                                My Project
                                            </span>
                                            <span className='fs12 opacity50 fw600'>
                                                Edit or view projects
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                                <span>
                                    <ArrowRightOutlined />
                                </span>
                            </Row>
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[24, 16]} className='mb-4 roundedCornerMedium bg-white'>
                <Col span={24}>
                    <Row gutter={[24, 16]} className='headingSettings border-bottom'>
                        <p className='m-0'>Issue</p>
                    </Row>
                    <Row className='px-4'>

                        <Link className='py10 cursor text-dark w100 ' to={`${AppRoutesPath.setting.issue}`}>
                            <Row gutter={[24, 16]} justify={"space-between"}>
                                <div>
                                    <Row>
                                        <BookOutlined className='settingsIcon' />
                                        <div className='ms-3'>
                                            <span className='d-block'>
                                                Issue
                                            </span>
                                            <span className='fs12 opacity50 fw600'>
                                                Edit or view issue
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                                <span>
                                    <ArrowRightOutlined />
                                </span>
                            </Row>
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[24, 16]} className='mb-4 roundedCornerMedium bg-white'>
                <Col span={24}>
                    <Row gutter={[24, 16]} className='headingSettings border-bottom'>
                        <p className='m-0'>Sprint</p>
                    </Row>
                    <Row className='px-4'>

                        <Link className='py10 cursor text-dark w100 ' to={`${AppRoutesPath.setting.sprint}`}>
                            <Row gutter={[24, 16]} justify={"space-between"}>
                                <div>
                                    <Row>
                                        <PartitionOutlined className='settingsIcon' />
                                        <div className='ms-3'>
                                            <span className='d-block'>
                                                Sprint
                                            </span>
                                            <span className='fs12 opacity50 fw600'>
                                                Edit or view sprint
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                                <span>
                                    <ArrowRightOutlined />
                                </span>
                            </Row>
                        </Link>
                    </Row>
                </Col>
            </Row>
            {/* <Row gutter={[24, 16]} className='mb-4 roundedCornerMedium bg-white'>
                <Col span={24}>
                    <Row gutter={[24, 16]} className='headingSettings border-bottom'>
                        <p className='m-0'>Log Details</p>
                    </Row>
                    <Row className='px-4'>

                        <Link className='py10 cursor text-dark w100 ' to={'/log-details'}>
                            <Row gutter={[24, 16]} justify={"space-between"}>
                                <div>
                                    <Row>
                                        <SolutionOutlined className='settingsIcon' />
                                        <div className='ms-3'>
                                            <span className='d-block'>
                                                Log Details
                                            </span>
                                            <span className='fs12 opacity50 fw600'>
                                                Edit or view log details
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                                <span>
                                    <ArrowRightOutlined />
                                </span>
                            </Row>
                        </Link>
                    </Row>
                </Col>
            </Row> */}
            <Row gutter={[24, 16]} className='mb-4 roundedCornerMedium bg-white'>
                <Col span={24}>
                    <Row gutter={[24, 16]} className='headingSettings border-bottom'>
                        <p className='m-0'>Branch</p>
                    </Row>
                    <Row className='px-4'>

                        <Link className='py10 cursor text-dark w100 ' to={`${AppRoutesPath.setting.branch}`}>
                            <Row gutter={[24, 16]} justify={"space-between"}>
                                <div>
                                    <Row>
                                        <BranchesOutlined className='settingsIcon' />
                                        <div className='ms-3'>
                                            <span className='d-block'>
                                                Branch
                                            </span>
                                            <span className='fs12 opacity50 fw600'>
                                                Edit or view branch
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                                <span>
                                    <ArrowRightOutlined />
                                </span>
                            </Row>
                        </Link>
                    </Row>
                </Col>
            </Row>

            <Row gutter={[24, 16]} className='mb-4 roundedCornerMedium bg-white'>
                <Col span={24}>
                    <Row gutter={[24, 16]} className='headingSettings border-bottom'>
                        <p className='m-0'>User</p>
                    </Row>
                    <Row className='px-4'>

                        <Link className='py10 cursor text-dark w100 '  to={`${AppRoutesPath.setting.user}`}>
                            <Row gutter={[24, 16]} justify={"space-between"}>
                                <div>
                                    <Row>
                                        <UserOutlined className='settingsIcon' />
                                        <div className='ms-3'>
                                            <span className='d-block'>
                                            User
                                            </span>
                                            <span className='fs12 opacity50 fw600'>
                                                Edit or view user
                                            </span>
                                        </div>
                                    </Row>
                                </div>
                                <span>
                                    <ArrowRightOutlined />
                                </span>
                            </Row>
                        </Link>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
