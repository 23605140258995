
import React from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export interface ModalTemplate {
  closeModal: () => void;
  onClose?: (params?: any) => void;
  width?: string;
  buttonText?: string;
  title: string;
  shouldShowFooter?: boolean;
  onSubmit?: () => void;
//    callback?: () => void;
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModalTemplate: React.FC<ModalTemplate> = ({
    closeModal,
    title,
    onSubmit = () => { },
    //   callback = () => {},
    children,
    width = "520px",
    shouldShowFooter = true,
    buttonText = "Save",
    onClose = () => { }
}: ModalTemplate) => {
    const handleClose = () => {
        onClose();
        closeModal();
        // callback();
    }
    return (
        <Modal 
        maskClosable={false}
            title={title}
            open={true}
            onCancel={handleClose}
            width={width}
            footer={shouldShowFooter ? (
                <div style={{ textAlign: 'right' }}>
                    <Button style={{ marginRight: "10px" }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="primary" onClick={onSubmit}>
                       Upload
                    </Button>
                </div>
            ) : null}
            closeIcon={<CloseOutlined style={{ color: '#bac2d0' }} />}
        >
            {children}
        </Modal>
    );
};

















// import Modal, {
//     ModalBody,
//     ModalTransition,
//     useModal,
// } from '@atlaskit/modal-dialog';
// import CrossIcon from '@atlaskit/icon/glyph/cross';
// import Select from '@atlaskit/select';
// import Button from '@atlaskit/button';

// export interface ModalTemplate {
//     closeModel: () => void,
//     onClose?: (params?: any) => void,
//     width?: string,
//     buttonText?: string,
//     title: string,
//     shouldShowFooter?: boolean,
//     onSubmit?: any,
//     children: React.ReactNode
// }

// export const ModalTemplate = ({ closeModel, title, onSubmit = () => { }, children, width = "medium", shouldShowFooter = true, buttonText = "Save", onClose = () => { } }: ModalTemplate) => {

//     const HandleClose = () => {
//         onClose()
//         closeModel()
//     }

//     return (
//         <ModalTransition>
//             <Modal width={width}>
//                 <ModalBody>
//                     <div style={{
//                         display: "flex",
//                         alignItems: "baseline",
//                         justifyContent: "space-between",
//                         marginTop: 14
//                     }}>
//                         <div style={{
//                             fontSize: 14,
//                             fontWeight: 600,
//                             paddingLeft: 20
//                         }}>
//                             {title}
//                         </div>
//                         <div>
//                             <div style={{ cursor: "pointer", marginRight: 18 }} onClick={HandleClose}>

//                                 <CrossIcon
//                                     label="Close Modal"
//                                     size="medium"
//                                     primaryColor="#bac2d0"
//                                 />

//                             </div>
//                         </div>
//                     </div>

//                     <hr style={{ color: "#F6F6F6", opacity: "0.2", marginTop: "10px", marginBottom: "10px" }} />
//                     <div style={{
//                         padding: "15px", paddingLeft: "20px", paddingRight: "20px"
//                     }}>
//                         {children}
//                     </div>
//                 </ModalBody>
//                 {
//                     shouldShowFooter &&
//                     <div style={{
//                         display: "flex",
//                         justifyContent: "end",
//                         margin: 20
//                     }}>

//                         <Button style={{ marginRight: "10px" }} appearance="subtle" onClick={HandleClose}>
//                             Cancel
//                         </Button>
//                         <Button appearance="primary"
//                             style={{ height: "35px", display: "flex", alignItems: "center", backgroundColor: "#3177ff", fontSize: "14px!important" }}
//                             type="submit"import { ModalTemplate } from './Modal';

//                             onClick={onSubmit}>
//                             {buttonText}
//                         </Button>
//                     </div>
//                 }

//             </Modal>
//         </ModalTransition>
//     )
// }