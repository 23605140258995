import TaskPercentageChartData from "./TaskPercentageChartData";
import './TaskPercentageChart.css'

const TaskPercentageChart = ({ projectid, setProjectId, transformedData,page }:any) => {

    let  option = {
        color: ['#4FD190','#FF9292'],
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: transformedData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    /*let option = {

        tooltip: {
            trigger: 'item',
            formatter: '{b}'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: transformedData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
*/
    const nodatajsx = () => {
        return <TaskPercentageChartData option={option} />
    }
    return (
        <>
            {
                page === "tldashboard" ?
                    <div  >
                       
                        {
                            nodatajsx()
                        }

                    </div>
                    :
                    <div className="TaskPercentageChart" >
                        <h4 style={{ marginTop: "0px", marginBottom: "6px", fontSize: "16px", color: "#3f5bd3", paddingLeft: "20px", paddingTop: "10px" }}>Task Percentage</h4>
                        {
                            nodatajsx()
                        }

                    </div>
            }
           
        </>
    )
}

export default TaskPercentageChart