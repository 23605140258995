import { Row, Col, Button, Form, TreeSelect, ConfigProvider } from "antd";
import { statusconvertor } from "../../utils/Util";

interface Props {
  page: any;
  onSearchbar: () => void;
  statusFilter: any;
  setStatusFilter: any;
}

const Filter = ({ page, statusFilter, setStatusFilter, onSearchbar }: Props) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields().then(() => {
      onSearchbar();
    });
  };
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                colorTextPlaceholder: "#95a5a6",
                fontSize: 14,
                colorPrimaryHover: "none",
                // controlHeight: 32,
                fontSizeIcon: 10,
                // borderRadius: 4,
                paddingSM: 4,
                paddingXS: 4,
                paddingXXS: 4,
                lineWidth: 1,
                borderRadius: 4,
                borderRadiusLG: 4,
                borderRadiusSM: 4,
                borderRadiusXS: 4,
                controlPaddingHorizontal: 4,
                controlPaddingHorizontalSM: 4,
                controlHeight: 27,
                controlHeightLG: 27,

                // fontSize: 14,
                // controlHeight: 28,
                // borderRadius: 1,
                algorithm: true,
                fontWeightStrong: 600,
              },
              DatePicker: {
                controlHeight: 27,
                controlHeightLG: 27,
              },
              Input: {
                controlHeight: 27,
                controlHeightLG: 27,
              },
            },
          }}
        >
          <Row>
            <Col span={22}>
              <TreeSelect
                 className="ml-5"
                 style={{
                  width:180
                 }}
                treeData={[
                  { title: statusconvertor(1), value: 1 },
                  { title: statusconvertor(2), value: 2 },
                  { title: statusconvertor(3), value: 3 },
                  { title: statusconvertor(7), value: 7 },
                  { title: statusconvertor(4), value: 4 },
                  { title: statusconvertor(5), value: 5 },
                  { title: statusconvertor(6), value: 6 },
                ]}
                value={statusFilter}
                treeCheckable
                showCheckedStrategy="SHOW_PARENT"
                placeholder="Status Filter"
                maxTagCount={1}
                onChange={setStatusFilter}
                onSearch={onSearch}
                treeNodeFilterProp="title"
              />
            </Col>
            <Col span={2}>
              <Form.Item>
                <Button
                  className="pl-5 pr-5"
                  type="primary"
                  style={{ borderRadius: "3px" }}
                  htmlType="submit"
                  size={"small"}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </ConfigProvider>
      </Form>
    </div>
  );
};

export default Filter;
