import { useCallback, useEffect, useState } from "react";
import {
  LoadingOutlined,
  ReloadOutlined,
  SearchOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  DatePicker,
  Select,
  Input,
  Avatar,
  Row,
  Col,
  Tooltip,
  Button,
  ConfigProvider,
} from "antd";
import BooksService from "../../Services/Books";

import { getEllipsisText, isUserAdmin } from "../../utils/Util";
import "../../common/Toothbar.css";
import Adduser from "./Adduser";
import { userOptions as UD } from "../../constants/commonConstants";
import { useSelector } from "react-redux";
import {
  projectData,
  setProjectData as setProjectReduxData,
} from "../../store/slices/project-slice";
import { userData } from "../../store/slices/user-slice";
import { useDispatch } from "react-redux";
import AssigneeAvatar from "./AssigneeAvatar";
import dayjs from "dayjs";
import "../../common/common.css";


const { Option } = Select;

const Toothbar = ({
  page,
  setTaskData,
  setSelectedAssignee,
  taskData,
  setTitleImg,
  setDefaultUser,
  projectname,
  handleAvatarClick,
  setFormData,
  setAssigne,
  showLoading,
  projectassigneedata,
  setProjectId,
  localproject,
  setlocalporject,
  todo,
  userfilterpid,
  setFromdate,
  setTodate,
  isloading,
  setPriority,
  selectedAvatarId,
  setSelectedAvatarId,
  setReloadKey
}: any) => {
  const dispatch = useDispatch();
  const [projectsData, setProjectData] = useState<any[]>([]);
  // const [userOptions, setUserOptions] = useState<any[]>(UD);
  const [projectassigneearr, setprojectassigneearr] = useState<any[]>([]);
  const [projectdataassignee, setprojectdataassignee] = useState<any[]>([]);
  const [isOpen2, setIsOpen2] = useState(false);
  const openModal2 = useCallback(() => setIsOpen2(true), []);
  const closeModal2 = useCallback(() => setIsOpen2(false), []);
  // const [index, setindex] = useState(0);
  // const [width, setWidth] = useState<number>(window.innerWidth);
  const [assigneeavatardata, setassigneeavatardata] = useState<any[]>([]);
  const [changeassignee, setchangeassignee] = useState(false);
  // const [defaultUser, setdefaultUser] = useState(null);
  const user = useSelector(userData);
  const project = useSelector(projectData);

  // let selectedvalue: string = "";
  const fetchassignee = async () => {
    try {
      const res = await BooksService.getUserByProjectId(project?.id);
      // console.log("res.result", res.result);

      setassigneeavatardata(res.result[0].assignee);
      setprojectassigneearr(
        res.result[0].assignee.map((a: any) => {
          return {
            value: a?.id,
            label: a?.name,
          };
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (page !== "viewall") {
      let arr = projectname.split(" ");
      if (arr.length > 1) {
        setTitleImg((arr[0].charAt(0) + arr[1].charAt(0)).toUpperCase());
      } else {
        setTitleImg((arr[0].charAt(0) + arr[0].charAt(1)).toUpperCase());
      }
      fetchassignee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectname, changeassignee, project.id]);

  const fetchProjects = async () => {
    try {
      const res = await BooksService.getProjectByUser(user?.id);
      const userdatatype = res.result.map((item: any) => ({
        label: item.name,
        value: item?.id,
        ...item,
      }));
      setProjectData(userdatatype);
      setprojectdataassignee(res?.result);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  // window.addEventListener("resize", () => setWidth(window.innerWidth));

  const priorityOptions = [
    { label: "All", value: -1 },
    { label: "High", value: 2 },
    { label: "Medium", value: 1 },
    { label: "Low", value: 0 },
  ];

  // const statusOptions = [
  //   { label: "Done", value: "done" },
  //   { label: "Working on it", value: "working on it" },
  //   { label: "In Progress", value: "in progress" },
  //   { label: "To Do", value: "todo" },
  // ];

  // const UTypeOptions = [
  //   { label: "Story", value: 2 },
  //   { label: "Task", value: 1 },
  //   { label: "Bug", value: 0 },
  // ];
  const [selectedProject, setSelectedProject] = useState({
    value: project?.id,
    label: project?.name,
  });


  const [searchTerm, setSearchTerm] = useState("");


  // priority Search
  const handleDateChange = (dates: any) => {
    if (dates === null) {
      console.log("No dates selected, showing total data.");
      setFromdate(null);
      setTodate(null);
    } else if (dates && dates.length === 2) {
      const fromDate = dayjs(dates[0]);
      const toDate = dayjs(dates[1]);
      setFromdate(fromDate.format("DD/MM/YYYY"));
      setTodate(toDate.format("DD/MM/YYYY"));
      console.log("From Date:", fromDate.format("DD/MM/YYYY"));
      console.log("To Date:", toDate.format("DD/MM/YYYY"));
    }
  };
  const { RangePicker } = DatePicker;

  
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              colorTextPlaceholder: "#95a5a6",
              fontSize: 14,
              colorPrimaryHover: "none",
           
              fontSizeIcon: 10,
            
              paddingSM: 0,
              paddingXS: 0,
              paddingXXS: 0,
              lineWidth: 1,
              borderRadius: 0,
              borderRadiusLG: 0,
              borderRadiusSM: 0,
              borderRadiusXS: 0,
              controlPaddingHorizontal: 12,
              controlPaddingHorizontalSM: 12,
              controlHeight: 27,
              controlHeightLG: 27,

         
              algorithm: true,
              fontWeightStrong: 600,
            },
            DatePicker: {
              controlHeight: 27,
              controlHeightLG: 27,
            },
            Input: {
              controlHeight: 27,
              controlHeightLG: 27,
            },
          },
        }}
      >
        <div
         
        >
          
          <Row gutter={[16, 16]} align="middle">
            <Col>
              <Select
              
                className="capTask-custom-select"
                allowClear
                style={{ width: "180px" }}
                placeholder="Select a Project"
                labelInValue
                options={projectsData}
                showSearch
                onChange={(v) => {
                  console.log("vv", v);
                  setSelectedProject(v);
                  dispatch(
                    setProjectReduxData({
                      id: v?.value,
                      name: v?.label,
                    })
                  );
                }}
                value={selectedProject}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Col>
            <Col>
              <Select
                className="capTask-custom-select"
                style={{ width: 180 }}
                defaultValue={UD[0]}
                onChange={(item) => setDefaultUser(item)}
                placeholder="Please Select Users"
                options={UD}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
              />
            </Col>
            <Col>
              <Select
                className="capTask-custom-select"
                style={{ width: 140 }}
                // defaultValue={priorityOptions[0]}
                onChange={(item: any) => {
                  // console.log("Selected", item);
                  setPriority(item, "priority");
                }}
                placeholder="Priority"
                options={priorityOptions}
                showSearch
              />
            </Col>
            <Col>
              <RangePicker
               
                format="DD/MM/YYYY"
            
                onChange={handleDateChange}
                
              />
            </Col>
            <Col>
              <Button
                onClick={() => window.location.reload()}
                style={{ height: 27 }}
                icon={<ReloadOutlined style={{ color: "rgb(61, 153, 37)" }} />}
              /> 
  
    
            </Col>
            <Col>
              <Input
                style={{ border: "1px solid #d9d9d9", width: 150 }}
                suffix={<SearchOutlined />}
                // className="toothbarinput"
                placeholder="Search Task..."
                allowClear
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  var array: any = taskData ?? [...taskData];
                  if (e.target.value === "") {
                    array.TODO = todo;
                  } else {
                    const regex = new RegExp(e.target.value, "i");
                    array.TODO = array?.TODO?.filter(
                      (str: any) =>
                        regex.test(str?.Description) || regex.test(str?.Title)
                    );
                  }
                  setTaskData(array ?? [...array]);
                  setTimeout(() => {}, 100);
                }}
              />
            </Col>
            <Col>
              {/* <Avatar.Group
                max={{
                  count: 6,
                  popover: { trigger: "click", placement: "bottom" },
                }}
              >
                {assigneeavatardata.map((assignee: any, index: any) => (
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedAvatarId === assignee.id
                          ? "red"
                          : "transparent",
                    }}
                    key={index}
                    onClick={() => handleAvatarClick(assignee?.id)}
                  >
                    <AssigneeAvatar
                      size={35}
                      assignee={assignee}
                      index={index}
                    />
                  </div>
                ))}
              </Avatar.Group> */}
              <Select
                className="capTask-custom-select"
                style={{ width: 170, margin: "4px 0px" }}
                placeholder="Select an assignee"
                // value={selectedAvatarId}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
                allowClear
                onSelect={(value)=>{
                  console.log("value =",value);
                  
                  handleAvatarClick(value)
                }}
                onClear={()=>{
                  handleAvatarClick(undefined) 
                }}
              >
                {assigneeavatardata.map((assignee: any, index: number) => (
                  <Option label={assignee?.name} key={assignee?.id} value={assignee?.id}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                
                    >
                      <AssigneeAvatar
                        size={23}
                        assignee={assignee}
                        index={index}
                      />
                      <span style={{ marginLeft: 8 }}>
                        {getEllipsisText(assignee?.name, 10)}
                      </span>
                    </div>
                  </Option>
                ))}
              </Select>

              {isUserAdmin() && (
                <Tooltip title="Add user">
                  <span style={{ cursor: "pointer" }} onClick={openModal2}>
                    <Avatar
                      size={30}
                      style={{
                        backgroundColor: "rgb(240, 242, 244)",
                        // margin: "6px 0px",
                        cursor: "pointer",
                      }}
                      className="ml-2 mb-1"
                      src={<UserAddOutlined style={{ color: "#b9bfc9" }} />}
                    />
                  </span>
                </Tooltip>
              )}
            </Col>
          </Row>
        </div>
        {isOpen2 && (
          <Adduser
            projectassigneearray={projectassigneearr}
            changeassignee={changeassignee}
            setchangeassignee={setchangeassignee}
            fetchassignee={fetchassignee}
            projectdataassignee={projectdataassignee}
            isOpen2={isOpen2}
            openModal2={openModal2}
            closeModal2={closeModal2}
          />
        )}
      </ConfigProvider>
    </>
  );
};
export default Toothbar;
