import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Divider,
  Avatar,
  Modal,
  Button,
  Breadcrumb,
} from "antd";
import { useSelector } from "react-redux";
import { userData } from "../store/slices/user-slice";
import { ArrowLeftOutlined, EnterOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { getAvatarUrl } from "../utils/Util";

const { Title, Text } = Typography;

const Cardstyld = {
  fontSize: "13px",
  // fontFamily: "Open Sans",
};
const CardItems = {
  fontSize: "16px",
  fontWeight: 600,
};
//Card-Peoples-stylds
const CardPeoplestyld = {
  margin: "0 7px",
  border: "1px solid rgb(243, 245, 249)",
  borderRadius: 10,
  width: 84,
  padding: 7,
  backgroundColor: "rgb(245, 248, 255)",
};
const Profile = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const user = useSelector(userData);
  const navigate = useNavigate();
  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "Profile",
    },
  ];

  return (
    <Row gutter={[16, 16]} justify={"center"}>
      <Col span={21}>
        <Button type="link" onClick={() => navigate("/")}>
          <ArrowLeftOutlined />
        </Button>
      </Col>
      <Col span={3} className="pl-15">
        <Breadcrumb items={breadcrumbItems} />
      </Col>

      {/* Left Column: Profile Picture and Basic Info */}
      <Col xs={24} sm={24} md={8} lg={6}>
        <Card
          style={{
            width: 400,
            height: 400,
            // height: 300,
            marginRight: 5,
            // margin: "0px 25px",
            margin: "0px 18px",
          }} //margin: "0px 20px"
          className="Crad-body"
          // actions={[
          //   <div style={CardPeoplestyld}>
          //     <div style={CardItems}></div>
          //     <div style={{ ...Cardstyld, color: "#a986d4" }}>To Do</div>
          //   </div>,
          //   <div style={CardPeoplestyld}>
          //     <div style={CardItems}> </div>
          //     <div style={{ ...Cardstyld, color: "#64b4fe" }}>In Progress</div>
          //   </div>,
          //   <div style={CardPeoplestyld}>
          //     <div style={CardItems}></div>
          //     <div style={{ ...Cardstyld, color: "#a986d4" }}>Done</div>
          //   </div>,
          // ]}
        >
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                paddingTop: 31,
                backgroundColor: "rgb(237, 240, 245)",
                height: 100,
                borderRadius: 5,
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <div
                  style={{ display: "inline-block", cursor: "pointer" }}
                  onClick={() => setModalVisible(true)}
                >
                  <Avatar
                    size={100}
                    className="hoverprofile"
                    src={user?.imageThumb}
                  />
                </div>
                <Modal
                  title={user?.name}
                  open={modalVisible}
                  onCancel={() => setModalVisible(false)}
                  footer={null}
                >
                  <img
                    src={user?.imageThumb}
                    alt="Avatar"
                    style={{ width: "100%" }}
                  />
                </Modal>
              </Col>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <Col
                span={24}
                style={{
                  paddingTop: 33,
                  fontSize: 18,
                  fontFamily: "Open Sans",
                  color: "rgb(79, 93, 118)",
                  fontWeight: "bold",
                }}
              >
                {user?.name}
              </Col>
              <Col span={24}>
                <Typography.Link type="secondary">
                  {user?.email}
                </Typography.Link>
              </Col>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
