import React, { useEffect, useState } from "react";
import BooksService from "../../Services/Books";
import { Button, Col, ConfigProvider, Form, notification, Row, Select, TreeSelect, Typography } from "antd";
import { statusconvertor } from "../../utils/Util";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import "../../components/Reports/Reports.css";
const Userratingtoothbar = ({
  fetchInHandData,
  statusFilter,
  page,
  setstatusFilter,
  projectid,
}: any) => {
  const [filterData, setFilterData] = useState<any>({
    projectid: "",
    assigneeId: "",
    status: 3,
  });
  const [form] = Form.useForm(); 
  const [assigneeavatardata, setassigneeavatardata] = useState([]);
  const [projectDrowpdown, setProjectDropdown] = useState<any>([]);
  const [width, setWidth] = useState<any>(window.innerWidth);
  const [assigneeselect, setAssigneeselect] = useState("");
  const user = useSelector(userData);
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  //getProjectByUser Api
  const fetchProjects = async () => {
    try {
      const res = await BooksService.getProjectByUser(user?.id);
      let userdatatype: any = [];
      res.result.map((item: any) => {
        userdatatype.push({
          label: item.name,
          value: item.id,
          ...item,
        });
      });
      setProjectDropdown([...userdatatype]);
    } catch (err) { }
  };
  //GetUser Api
  const fetchassignee = () => {
    BooksService.getUser(100).then((res: any) => {
      let RANDOM_USERS: any = [];

      if (res.result?.length > 0) {
        for (let el in res.result) {
          RANDOM_USERS.push({
            id: res.result[el].id,
            name: res.result[el].name,
            thumb: res.result[el].imageThumb,
          });
        }
        let assigneeavatar: any = [{ label: "All", value: "" }];
        res.result.map((item: any) => {
          assigneeavatar.push({
            label: item.name,
            value: item.id,
            ...item,
          });
        });
        setassigneeavatardata(assigneeavatar);
      }
    });
  };

  useEffect(() => {
    fetchassignee();
    fetchProjects();
  }, [projectid]);
const handleSearchClick = () => {
  form
    .validateFields()
    .then((values) => {
      if (!filterData?.projectid) {
      } else {
        fetchInHandData(filterData.projectid, assigneeselect, statusFilter);
      }
    })
    .catch((errorInfo) => {
      notification.error({
        message: "Select Project",
        description: "Please fill in the required fields correctly.",
        placement: "bottomLeft",
      });
    });
};

  window.addEventListener("resize", () => setWidth(window.innerWidth));
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#95a5a6",
            fontSize: 14,
            colorPrimaryHover: "none",
            // controlHeight: 32,
            fontSizeIcon: 10,
            // borderRadius: 4,
            paddingSM: 4,
            paddingXS: 4,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 27,
            controlHeightLG: 27,

            // fontSize: 14,
            // controlHeight: 28,
            // borderRadius: 1,
            algorithm: true,
            fontWeightStrong: 600,
          },
          DatePicker: {
            controlHeight: 27,
            controlHeightLG: 27,
          },
          Input: {
            controlHeight: 27,
            controlHeightLG: 27,
          },
        },
      }}
    >
      <div>
        <div>
          <div>
            <div className="pt-3 pb-3 pl-8 Modelupmeeting">
              <Row justify={"space-between"} gutter={[16, 16]}>
                {/* Form Component */}
                <Col>
                  <Form layout="inline" form={form}>
                    <Form.Item
                      name="project"
                      rules={[{ required: true, message: "" }]}
                    >
                      <Select
                        placeholder="Select..."
                        style={{
                          width: 180,
                          // height: 35,
                        }}
                        // className="handsearchh"
                        maxTagCount={2}
                        options={projectDrowpdown}
                        onChange={(value: any) => {
                          setFilterData({
                            ...filterData,
                            projectid: value,
                          });
                        }}
                        showSearch
                        onSearch={onSearch}
                        optionFilterProp="label"
                      />
                    </Form.Item>
                    {/* TreeSelect */}
                    <Form.Item name="tstatus">
                      <Typography.Text className="mr-4">Status</Typography.Text>
                      <TreeSelect
                        // className="handsearchh"
                        treeData={[
                          { title: statusconvertor(1), value: 1 },
                          { title: statusconvertor(2), value: 2 },
                          { title: statusconvertor(3), value: 3 },
                          { title: statusconvertor(7), value: 7 },
                          { title: statusconvertor(4), value: 4 },
                          { title: statusconvertor(5), value: 5 },
                          { title: statusconvertor(6), value: 6 },
                        ]}
                        defaultValue={[
                          { title: statusconvertor(1), value: 1 },
                          { title: statusconvertor(2), value: 2 },
                          { title: statusconvertor(3), value: 3 },
                          { title: statusconvertor(7), value: 7 },
                          { title: statusconvertor(4), value: 4 },
                          { title: statusconvertor(5), value: 5 },
                        ]}
                        value={statusFilter}
                        treeCheckable
                        showCheckedStrategy="SHOW_PARENT"
                        placeholder="Status Filter"
                        maxTagCount={1}
                        onChange={setstatusFilter}
                        style={{
                          width: 180,
                          // height: 35,
                        }}
                        showSearch
                        onSearch={onSearch}
                        treeNodeFilterProp="title"
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: `${width > 1100 ? "flex" : "none"}`,
                        // alignItems: "center",
                        // marginLeft: "20px",
                        paddingTop: `${page === "logdetail" && "10px"}`,
                      }}
                    >
                      <Typography.Text className="mr-4 mt-1">
                        Assignee
                      </Typography.Text>
                      <Select
                        // className="handsearchh"
                        className=" mt-1"
                        style={{ width: 180 }}
                        placeholder="Please Select"
                        options={assigneeavatardata}
                        onChange={(value) => {
                          setAssigneeselect(value);
                        }}
                        showSearch
                        onSearch={onSearch}
                        optionFilterProp="label"
                      />
                    </div>
                    <Form.Item className="ml-5">
                      <Button
                        className="pl-5 pr-5 "
                        type="primary"
                        style={{ borderRadius: "3px" }}
                        onClick={handleSearchClick}
                        htmlType="submit"
                        size={"small"}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Userratingtoothbar;
