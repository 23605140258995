import SidebarBranch from "./SidebarBranch/SidebarBranch"


const Branch = () => {
  return (
    <div>
      <SidebarBranch/>
    </div>
  )
}

export default Branch
