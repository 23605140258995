import React from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "antd";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={process.env.PUBLIC_URL + "/error-404.avif"}
        alt="Error 404"
        height={400}
        style={{ marginBottom: "20px" }}
      />
      <div>
        <Button
          type="default"
          size="large"
          shape="round"
          className="custom-buttonnn"
          onClick={() => navigate("/")}
        >
          Back Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
