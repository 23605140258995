import React, { useEffect, useState } from "react";
import "../common/report.css";
import {
  getAvatarUrl,
  getEllipsisText,
  randomhax,
  statusbackgroundgenerator,
  statusconvertor,
  substringmethod,
  substringmethodname,
} from "../utils/Util";
import { format } from "date-fns";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HTTPSCalls from "../Services/HTTPCalls";
// import BooksService from "../Services/Books";
import {
  Avatar,
  Card,
  Flex,
  Col,
  Row,
  Typography,
  Table,
  Tooltip,
  Divider,
  Modal,
  Button,
  Breadcrumb,
  TableProps,
} from "antd";
import { ArrowLeftOutlined, ReloadOutlined } from "@ant-design/icons";
import Filter from "./PeoplesReportToothbar/PeoplesReportToothbar";
import TableSkeleton from "./dashboard/Common/TableSkeleton";

const Cardstyld = {
  fontSize: "13px",
  // fontFamily: "Open Sans",
};
const CardItems = {
  fontSize: "16px",
  fontWeight: 600,
};
//Card-Peoples-stylds
const CardPeoplestyld = {
  margin: "0 7px",
  border: "1px solid rgb(243, 245, 249)",
  borderRadius: 10,
  width: 84,
  padding: 7,
  backgroundColor: "rgb(245, 248, 255)",
};
// UserRelatedTasks Css //
const reUserrelatedtaskspotCard = {
  height: "40px",
  width: "40px",
  borderRadius: 5,
  fontSize: 14,
  backgroundColor: "#eef2f9",
  fontWeight: 600,
  marginLeft: 10,
  marginRight: 10,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
// const Tree = {
//   borderRadius: "3px",
// };
function Report() {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as {
    userid: string;
    // email: string;
    // name: string;
    // thumb: string;
    // todo: number;
    // inprogress: number;
    // done: number;
    // testing: number;
    // UAT: string;
    // needtodiscuss: number;
  };
  const [statusFilter, setStatusFilter] = useState<Array<number>>([
    1, 2, 3, 4, 5,
  ]);
  const [userRelatedTasksFilter, setUserRelatedTasksFilter] = useState<
    Record<string, any>
  >({ projectid: "", userid: state?.userid });
  const [userRelatedTasks, setUserRelatedTasks] = useState<any>([]);
  const [userRelatedProject, setUserRelatedProject] = useState<any>([]);
  const [loading, setloading] = useState(false);
  const [cardWidth, setcardWidth] = useState(window.innerWidth);
  const [modalVisible, setModalVisible] = useState(false);
  const [userData, setUserData] = useState<any>({});
  window.addEventListener("resize", () => {
    setcardWidth(window.innerWidth);
  });

  const GetUserRelatedTasks = async () => {
    setloading(true);
    try {
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.userReportData + "?",
        { statusFilter },
        userRelatedTasksFilter
      );
      if (response.result) {
        setUserRelatedTasks(response.result);
        // console.log("ash", response.result);
        const tasks = response.result.map((task: any, i: any) => {
          return {
            key: i,
            ukey: (
              <Row className="table-Title">
                <Col>
                  <Button
                    size="small"
                    className="resume-btn-sm mt-1"
                    style={{
                      // backgroundColor: "rgb(204, 229, 255)",
                      color: "rgb(16, 124, 16)",
                      backgroundColor: "rgb(223, 246, 221)",
                      width: "50px",
                      border: "none",
                    }}
                    onClick={() => {
                      navigate("/task/" + task.uKey);
                    }}
                  >
                    {task.uKey}
                  </Button>
                </Col>
              </Row>
            ),
            name: (
              <Row className="table-Title">
                <Col>
                  <Tooltip title={task?.name}>
                    <Typography.Text
                      style={{
                        // marginLeft: 10,
                        marginTop: 0,
                        fontSize: 14,
                        color: "#636e72",
                        cursor: "pointer",
                      }}
                    >
                      {substringmethodname(task?.name)}
                    </Typography.Text>
                  </Tooltip>
                </Col>
              </Row>
            ),
            //title
            title: (
              <Row>
                <Col lg={24}>
                  <Tooltip title={task?.title}>
                    <Typography.Text style={{ cursor: "pointer" }}>
                      {substringmethod(task?.title)}
                    </Typography.Text>
                  </Tooltip>
                </Col>
              </Row>
            ),
            //status
            status: (
              <Row style={{ width: "110px" }}>
                <Button
                  size="small"
                  className="resume-btn-sm"
                  style={{
                    backgroundColor: statusbackgroundgenerator(
                      statusconvertor(task.status)
                    ),
                    color: "rgb(12, 84, 96)",
                    width: "110px",
                    border: "none",
                  }}
                >
                  {statusconvertor(task.status)}
                </Button>
              </Row>
            ),
            //priority
            priority: (
              <>
                <Typography.Text className="pr-2">
                  {priorityIcon(task.priority)}
                </Typography.Text>
                <Typography.Text style={{ cursor: "pointer" }}>
                  {prioritycheck(task?.priority)}
                </Typography.Text>
              </>
            ),
            // sprintName
            sprintName: (
              <div style={{ cursor: "pointer", width: "150px" }}>
                <Tooltip title={task?.name}>
                  <Typography.Text>
                    {/* {task?.sprint[0]?.name ? task.sprint[0].name : 'Backlog'} */}
                      {getEllipsisText(task?.sprint && task.sprint.length > 0
                        ? task.sprint[0].name
                        : "Backlog", 15)}
                  </Typography.Text>
                </Tooltip>
              </div>
            ),
            //startDate
            startDate: (
              <Row style={{ cursor: "pointer", width: "100px" }}>
                <Typography.Text style={{ cursor: "pointer", width: "100px" }}>
                  {task?.sprint.length > 0
                    ? format(task?.sprint[0].startDate, "dd MMM yyyy")
                    : "-"}

                  {/* {format(new Date(task?.startDate), "dd MMM yyyy")} */}
                </Typography.Text>
              </Row>
            ),
            // endDate
            endDate: (
              <Row style={{ cursor: "pointer", width: "100px" }}>
                <Typography.Text style={{ cursor: "pointer", width: "100px" }}>
                  {task?.sprint.length > 0
                    ? format(task?.sprint[0].endDate, "dd MMM yyyy")
                    : "-"}
                </Typography.Text>
              </Row>
            ),
          };
        });

        setUserRelatedTasks(tasks);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setloading(false);
    }
  };
  const GetUserRelatedProject = async () => {
    try {
      setloading(true);
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.project.getAll + "?",
        { start: 0, length: 3000, uid: state?.userid, isAllRecord: true }
      );
      if (response.result) {
        setUserRelatedProject(response.result);
      }
    } catch (err) {
    } finally {
      setloading(false);
    }
  };

  // console.log("userRelatedProject", userRelatedProject);

  // console.log("projectData", projectData);
  const prioritycheck = (priority: any) => {
    switch (priority) {
      case 2:
        return "High";
      case 1:
        return "Medium";
      case 0:
        return "Low";
      default:
        return "Unknown";
    }
  };

  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "7%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "Ukey",
      dataIndex: "ukey",
      isSortable: false,
      width: "7%",
    },
    {
      title: "Name",
      dataIndex: "name",
      isSortable: false,
      width: "10%",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.Name - b.Name,
    },
    {
      title: "Title",
      dataIndex: "title",
      isSortable: false,
      width: "25%",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.title - b.title,
    },
    {
      title: "Status",
      dataIndex: "status",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.status - b.status,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.priority - b.priority,
    },
    {
      title: "Sprint name",
      dataIndex: "sprintName",
      width: "11%",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.sprintName - b.sprintName,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.startDate - b.startDate,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.endDate - b.endDate,
    },
  ];

  // function StatusSelectHandler(selectedValue: any) {
  //   setStatusFilter(selectedValue.map((item: any) => item.value));
  // }

  useEffect(() => {
    GetUserRelatedTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRelatedTasksFilter]);

  const getUserData = async () => {
    try {
      setloading(true);
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.user.getAssigneeByTaskCount + "?",
        {
          assigneeid: state?.userid,
        }
      );
      if (response.result) {
        // console.log("response.result", response.result);
        setUserData(response.result[0]);

        // setUserRelatedProject(response.result);
        GetUserRelatedProject();
      }
    } catch (err) {
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const breadcrumbItems = [
    {
      title: (
        <Link to={"/peoples"} className="BreadcrumbLink">
          Peoples
        </Link>
      ),
    },
    {
      title: "reporting",
    },
  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
  });
  const [refresh,setRefresh] = useState<boolean>(false)
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
  return (
    <>
      <>
        <Row justify={"end"} className="mb-2">
          <Col span={21}>
            <Button type="link" onClick={() => navigate("/peoples")}>
              <ArrowLeftOutlined />
            </Button>
          </Col>
          <Col
            className="pl-15"
            span={3}
            style={{ fontSize: "12px", textAlign: "center" }}
          >
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>

        <Row className="roundedCornerSmall bg-white" style={{ padding: 14 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Col span={24}>
                <Filter
                  onSearchbar={GetUserRelatedTasks}
                  page={undefined}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
              </Col>
            </Row>
            {/* <Loader loading={isLoading} /> */}
          </Col>

          <Col span={5}>
            <div>
              <Card
                style={{
                  width: 296,
                  // height: 300,
                  marginRight: 5,
                  // margin: "0px 25px",
                  margin: "0px 18px",
                }} //margin: "0px 20px"
                className="Crad-body"
                actions={[
                  <div style={CardPeoplestyld}>
                    <div style={CardItems}>{userData?.todo}</div>
                    <div style={{ ...Cardstyld, color: "#a986d4" }}>To Do</div>
                  </div>,
                  <div style={CardPeoplestyld}>
                    <div style={CardItems}> {userData?.inprogress}</div>
                    <div style={{ ...Cardstyld, color: "#64b4fe" }}>
                      In Progress
                    </div>
                  </div>,
                  <div style={CardPeoplestyld}>
                    <div style={CardItems}> {userData?.done}</div>
                    <div style={{ ...Cardstyld, color: "#a986d4" }}>Done</div>
                  </div>,
                ]}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                      paddingTop: 31,
                      backgroundColor: "rgb(237, 240, 245)",
                      height: 100,
                      borderRadius: 5,
                    }}
                  >
                    <Col span={24} style={{ textAlign: "center" }}>
                      <div
                        style={{ display: "inline-block", cursor: "pointer" }}
                        onClick={() => setModalVisible(true)}
                      >
                        <Avatar
                          size={100}
                          src={
                            getAvatarUrl(userData)
                            // userData?.thumb === null ||
                            // userData?.thumb === "" ||
                            // userData?.thumb === undefined
                            //   ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                            //       userData?.name
                            //     )}-${textnumbergenerator(
                            //       userData?.name
                            //     )}.png?ssl=1`
                            //   : userData?.thumb
                          }
                        />
                      </div>
                      <Modal
                        title={userData?.name}
                        open={modalVisible}
                        onCancel={() => setModalVisible(false)}
                        footer={null}
                      >
                        <img
                          src={getAvatarUrl(userData)}
                          alt="Avatar"
                          style={{ width: "100%" }}
                        />
                      </Modal>
                    </Col>
                  </Col>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Col
                      span={24}
                      style={{
                        paddingTop: 33,
                        fontSize: 18,
                        fontFamily: "Open Sans",
                        color: "rgb(79, 93, 118)",
                        fontWeight: "bold",
                      }}
                    >
                      {userData?.name}
                    </Col>
                    <Col span={24}>
                      <Typography.Link type="secondary">
                        {userData?.email}
                      </Typography.Link>
                    </Col>
                  </Col>
                </Row>
              </Card>
              <div
                style={{
                  marginLeft: `${cardWidth < 550 ? "-6px" : "none"}`,
                  margin: "16px 31px 0px 19px",
                }}
              >
                <div
                  style={{
                    width: `${
                      cardWidth < 990 && cardWidth > 551 ? "240px" : "290"
                    }`,
                    // height: "20vh",
                  }}
                >
                  <Card
                    style={{
                      height: "40.5vh",
                      width: 296,
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                    className="Card-refresh"
                  >
                    <Flex vertical gap="middle" justify="center">
                      <Row>
                        <Col span={20}>
                          <Typography.Link
                            style={{
                              fontSize: 14.5,
                              fontWeight: 600,
                              margin: 9,
                            }}
                          >
                            Projects Worked on
                          </Typography.Link>
                        </Col>
                        <Col span={2} offset={2}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setUserRelatedTasksFilter((prevState) => ({
                                ...prevState,
                                projectid: "",
                              }));
                            }}
                          >
                            <ReloadOutlined style={{ color: "#9da5b4" }} />
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ maxHeight: "50vh", overflowY: "auto" }}>
                        {userRelatedProject.length > 0 ? (
                          userRelatedProject.map(
                            (project: any, index: number) => (
                              <React.Fragment key={index}>
                                <Row
                                  onClick={() => {
                                    setUserRelatedTasksFilter((prevState) => ({
                                      ...prevState,
                                      projectid: project.id,
                                    }));
                                  }}
                                  style={{
                                    alignItems: "center",
                                    cursor: "pointer",
                                    marginBottom: 11,
                                  }}
                                >
                                  <Col>
                                    <Card
                                      style={{
                                        ...reUserrelatedtaskspotCard,
                                        color: `${randomhax(index)}`,
                                      }}
                                    >
                                      {project.alias}
                                    </Card>
                                  </Col>
                                  <Col>
                                    <Typography.Text
                                      style={{ fontWeight: 400 }}
                                    >
                                      {project.name}
                                    </Typography.Text>
                                  </Col>
                                </Row>
                                <Divider style={{ margin: 11 }} />
                              </React.Fragment>
                            )
                          )
                        ) : (
                          <Row style={{ textAlign: "center" }}>
                            <img
                              style={{
                                paddingTop: "40px",
                                width: 140,
                                marginLeft: 55,
                              }}
                              // src={process.env.PUBLIC_URL + "/noTask.svg"}
                              src={process.env.PUBLIC_URL + "/img-antd.svg"}
                              alt="No Task"
                            />
                          </Row>
                        )}
                      </Row>
                    </Flex>
                  </Card>
                </div>
              </div>
            </div>
          </Col>
          <Col span={19}>
            {loading ? (
              <TableSkeleton
                columns={columns}
                scroll={{ y: "75vh" }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
              />
            ) : (
              <Table
              className="capTask-custom-table"
                style={{
                  paddingTop: 5,
                  marginRight: -2,
                  height: "auto",
                  // borderTop: "solid 1px #b2bec3",
                }}
                onChange={onChangeTable}
                pagination={{
                  showTotal: (total: any, range: any) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
                columns={columns}
                dataSource={userRelatedTasks}
                loading={loading}
                scroll={{ y: "78vh" }}
                locale={{
                  emptyText: (
                    <img
                      style={{ paddingTop: "40px", width: 140 }}
                      // src={process.env.PUBLIC_URL + "/noTask.svg"}
                      src={process.env.PUBLIC_URL + "/img-antd.svg"}
                      alt="No Task"
                    />
                  ),
                }}
              />
            )}
          </Col>
        </Row>
      </>
    </>
  );
}
export default Report;
