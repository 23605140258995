// import Modal, {
//     ModalBody,
//     ModalFooter,
//     ModalHeader,
//     ModalTitle,
//     ModalTransition,
// } from '@atlaskit/modal-dialog';

// import DynamicTable from "@atlaskit/dynamic-table";
import { useState } from 'react';
import { Button, Table } from 'antd';

const AdminAlertModal = ({ isOpen, closeModal, openModal, userName }: any) => {

    const [projectData, setProjectData] = useState([])

    const columns = [
        {
            title: 'Assignee',
            dataIndex: 'Assignee',
            key: 'name',
            render: (item: any) => <div className="pl-4">{item.name}</div>,
        },
    ];

    const createHead = (withWidth: boolean) => {

        return {
            cells: [
                {
                    key: 'Assignee',
                    content: 'Assignee',
                    isSortable: true,
                    width: 0.5
                },
                {
                    key: 'Tasks',
                    content: 'Task',
                    isSortable: true,
                    width: 0.2
                },

            ],
        };
    };

    const createHead2 = (withWidth: boolean) => {

        return {
            cells: [
                {
                    key: 'Project',
                    content: 'Project',
                    isSortable: true,
                    width: 0.5
                },
                {
                    key: 'Tasks',
                    content: 'Task',
                    isSortable: true,
                    width: 0.2
                },

            ],
        };
    };

    const head2 = createHead2(true);
    const head = createHead(true);
    return (
        <>
            {/* <ModalTransition>
                {isOpen && (
                    <Modal onClose={closeModal}>
                        <ModalBody>
                            <div>
                                {
                                    userName === "project" ?
                                        <div style={{ padding: "20px" }}>
                                            <Table
                                                pagination={false}
                                                bordered
                                                scroll={{ y: 500 }}
                                                columns={columns}
                                                dataSource={projectData}

                                            />
                                             <DynamicTable
                                                head={head}
                                                rows={projectData?.map((data: any, index: number) => {


                                                    return ({
                                                        key: `row-${index}-${data.id}`,
                                                        isHighlighted: false,
                                                        cells: [
                                                            {
                                                                content:
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <div style={{ textAlign: "center" }}>
                                                                            <p style={{ fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>Prashant Soni</p>
                                                                        </div>
                                                                    </div>

                                                            },
                                                            {
                                                                content:
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <div style={{ textAlign: "center" }}>
                                                                            <p style={{ fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>Prashant Soni</p>
                                                                        </div>
                                                                    </div>
                                                            },

                                                        ],
                                                    })

                                                }

                                                )
                                                }
                                                loadingSpinnerSize="large"
                                            /> 
                                        </div> :
                                        <div style={{ padding: "20px" }}>
                                             <DynamicTable
                                                head={head2}
                                                rows={projectData?.map((data: any, index: number) => {


                                                    return ({
                                                        key: `row-${index}-${data.id}`,
                                                        isHighlighted: false,
                                                        cells: [
                                                            {
                                                                content:
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <div style={{ textAlign: "center" }}>
                                                                            <p style={{ fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>Prashant Soni</p>
                                                                        </div>
                                                                    </div>

                                                            },
                                                            {
                                                                content:
                                                                    <div style={{ display: "inline-block" }}>
                                                                        <div style={{ textAlign: "center" }}>
                                                                            <p style={{ fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>Prashant Soni</p>
                                                                        </div>
                                                                    </div>
                                                            },

                                                        ],
                                                    })

                                                }

                                                )
                                                }
                                                loadingSpinnerSize="large"
                                            /> 
                                        </div>
                                }

                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={closeModal}>
                                Cancel
                            </Button>
                            <Button onClick={closeModal} autoFocus>
                                Submit
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </ModalTransition> */}
        </>
    )
}

export default AdminAlertModal