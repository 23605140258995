
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, notification, Select, } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../Services/HTTPCalls";

const { Option } = Select;

const AddWatching = ({ closeModal, isOpen, taskDetail, taskId }: any) => {
  const [loading, setLoading] = useState(false);
  const [watching, setWatching] = useState([]);
  const [userWatching, setUserWatching] = useState<any[]>(
    taskDetail?.assignee || []
  );
  const [form] = Form.useForm();

  const fetchWatching = async () => {
    try {
      const res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", {
        length: 15000,
      });

      const userdatatype = res.result.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setWatching(userdatatype);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  useEffect(() => {
    fetchWatching();
  }, [isOpen, taskDetail]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.watching, {
        id: taskId,
        users: userWatching,
      });

      if (res.result) {
        // console.log("vk", response?.result);
        notification.success({
          message: "Success",
          description: "Watching updated successfully",
          placement:'bottomLeft',
          icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
        });
        closeModal();
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update watching",
        placement: "bottomLeft",
      });
      console.error("Failed to update watching:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Watching"
      open={isOpen}
      width={500}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="save"
          htmlType="submit"
          type="primary"
          loading={loading}
          form="watchingForm"
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        id="watchingForm"
        layout="vertical"
        form={form}
        onFinishFailed={(errorInfo) => {
          console.log("Failed:", errorInfo);
        }}
      >
        <Form.Item
          name="watchers"
          label="Select Watchers"
          className="mb-5 mt-5"
          rules={[{ required: true, message: "Please select watchers!" }]}
        >
          <Select
            mode="multiple"
            className="Watchers"
            placeholder="Choose Watchers"
            options={watching}
            value={userWatching}
            onChange={(value) => setUserWatching(value)}
            style={{ width: "100%" }}
            allowClear
            onSearch={onSearch}
            optionFilterProp="label"
            defaultValue={taskDetail?.assignee ? [...taskDetail?.assignee] : []}
          >
            {watching.map((option: any) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddWatching;
