const LocalStorage = {
    setItem: (name: string, object: any) => {
        localStorage.setItem(name, JSON.stringify(object));
    },
    getItem: (name: string, type: any) => {
        if (type === "string")
            return localStorage.getItem(name)
        else if(type === "JSON")
            return JSON.parse(localStorage.getItem(name) || "{}");
    }
}

export default LocalStorage