import { CloseCircleOutlined } from '@ant-design/icons';
import ImageUploading, { ImageListType, ImageType } from "react-images-uploading";
import { formatBytes } from '../../../utils/Util';
import NoData from '../../NoData';

interface Props {
    value: ImageListType,
    onChange: (file: ImageListType) => void,
    content?: string,

}

const ImageUpload = ({ value, onChange, content = "Drag or click to upload document" }: Props) => {

    return (
        <ImageUploading
            multiple
            value={value}
            onChange={onChange}
            allowNonImageType={true}
            // acceptType={["xlsx", "png", "svg", "jpg", "jpge"]}
            dataURLKey="data_url"
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps
            }) => (
                <div className="upload__image-wrapper">

                    <div
                        onClick={onImageUpload}
                        style={{ width: "100%", height: "100%", border: "2px dotted #f2f4f1", borderRadius: 10, paddingTop: 5, marginBottom: 10 }}
                        {...dragProps}
                    >
                        <NoData message={`${isDragging ? "Dragging" : content}`} />
                    </div>

                    <div style={{ maxHeight: 250,  display: "flex",  justifyContent: "center" }}>
                        <div
                            style={{ alignItems: "center" }}
                        >
                            {
                                imageList.map((item: any, index: number) => (
                                    <div style={{
                                        display: "flex", textAlign: "center", padding: 5,
                                        justifyContent: "space-between", alignItems: "center", marginBottom: 5,
                                        border: "2px solid #f2f4f1", borderRadius: 10, minWidth: 470, marginLeft: 10
                                    }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <img src={process.env.PUBLIC_URL + "/xlxs.svg"} alt="" />
                                            <p style={{ marginLeft: 5, marginTop: 0, marginRight: 5, marginBottom: 0 }}>
                                                {item.file.name.length > 15 ? item.file.name.slice(0, 15) + "..." : item.file.name}
                                            </p>
                                            ({formatBytes(item.file.size)})
                                        </div>
                                        <div style={{ cursor: "pointer" }} onClick={() => onImageRemove(index)}>
                                            {/* <CrossIcon label="remove" primaryColor="#95A1AE" /> */}
                                            <CloseCircleOutlined style={{ color: '#95A1AE' }} />
                                        </div>
                                    </div>
                                ))
                            }


                        </div>
                    </div>
                </div>

            )}
        </ImageUploading>
    )
}

export default ImageUpload;

