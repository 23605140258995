
import { Table } from "antd"
import { formatDatedatetimeyeargforcreatetask } from "../../../utils/DateConvertor"
import "./AdminMilestone.css"


const AdminMilestoneData = ({ getExpireMilestone }: any) => {


    const columns = [

        {
            title: "Project",
            dataIndex: "project",
            key: "project",
            width: "50%",
            render: (item: any) => <div className="pl-4">{item?.name}</div>,
        },
        {
            title: "Milestone Name",
            dataIndex: "name",
            key: "name",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Expired On",
            dataIndex: "deadline",
            key: "deadline",
            width: "25%",
            render: (item: any) => <div className="pl-4">{formatDatedatetimeyeargforcreatetask(item, "")}</div>,
        },


    ];

    // const createHead = (withWidth: boolean) => {
    //     return {
    //         cells: [

    //             {
    //                 key: 'Project',
    //                 content: 'Project',
    //                 width: 4
    //             },
    //             { 
    //                 key: 'Milestone Name',
    //                 content: 'Milestone Name',
    //                 width: 1
    //             },
    //             {
    //                 key: 'Expired On',
    //                 content: 'Expired On',
    //                 width: 1
    //             }

    //         ],
    //     };
    // };

    // const head = createHead(true);
    return (
        <>


            <div style={{
                fontSize: 14,
                height: "177px"
            }}>
                {

                    getExpireMilestone &&
                    <div>
                        <Table
                            bordered
                            scroll={{ y: 600 }}
                            dataSource={getExpireMilestone}
                            pagination={false}
                            columns={columns}

                        />
                    </div>

                    // <DynamicTable
                    //     head={head}
                    //     rows={getExpireMilestone?.map((item: any, index: number) => ({
                    //         key: `row-${index}-${item?.id}`,
                    //         isHighlighted: false,
                    //         cells: [
                    //             {
                    //                 content:
                    //                     <p style={{ marginTop: "0px", marginBottom: "0px",  fontSize: "14px" }}>{item?.project?.name}</p>
                    //             },
                    //             {
                    //                 content: <p style={{ marginTop: "10px", marginBottom: "0px", fontSize: "14px" }}>{item?.name}</p>
                    //             },
                    //             {
                    //                 content: <span style={{ fontSize: "14px" }}>{formatDatedatetimeyeargforcreatetask(item?.deadline, "")}</span>
                    //             },

                    //         ],
                    //     }))}
                    //     defaultPage={1}
                    //     loadingSpinnerSize="large"
                    // />
                }
            </div>


        </>
    )
}

export default AdminMilestoneData