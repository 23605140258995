import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

type page = {
  board: boolean;
  backlog: boolean;
  taskDrawer: boolean;
 meeting:boolean
 active:boolean
};

const initialState: page = {
  board: false,
  backlog: false,
  taskDrawer: false,
  meeting:false,
  active:false
};

const refreshSlice = createSlice({
  name: "refresh",
  initialState,
  reducers: {
    refreshBoard: (state) => {
      state.board = !state.board;
    },
    refreshTaskDrawer: (state) => {
      state.taskDrawer = !state.taskDrawer;
    },
    refreshBacklog: (state) => {
      state.backlog = !state.backlog;
    },
    refreshMeeting: (state) => {
      state.meeting = !state.meeting;
    },
    refreshActive: (state) => {
      state.active = !state.active;
    },
    resetRefreshState: (state) => {
      state.board = false;
      state.backlog = false;
    },
  },
});

export const {
  refreshBoard,
  refreshBacklog,
  resetRefreshState,
  refreshTaskDrawer,
  refreshMeeting,
  refreshActive
} = refreshSlice.actions;

export const boardRefreshState = (state: RootState): boolean =>
  state.refresh.board;
export const backlogRefreshState = (state: RootState): boolean =>
  state.refresh.backlog;
export const taskDrawerRefreshState = (state: RootState): boolean =>
  state.refresh.taskDrawer;
export const meetingRefreshState = (state: RootState): boolean =>
  state.refresh.meeting;
export const activeRefreshState = (state: RootState): boolean =>
  state.refresh.active;

export default refreshSlice.reducer;
