// import Button from '@atlaskit/button';
// import Modal, {
//     ModalBody,
//     ModalFooter,
//     ModalHeader,
//     ModalTitle,
//     ModalTransition,
// } from '@atlaskit/modal-dialog';
// import CrossIcon from '@atlaskit/icon/glyph/cross';
// import { token } from '@atlaskit/tokens';
// import { N500 } from '@atlaskit/theme/colors';
import { useState } from 'react';
import LocalStorage from '../../../Services/Localstorage';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Table } from 'antd';


const AllProjectList = ({ isOpen, closeModal, projectList }: any) => {

    const columns = [

        {
            title: "Name",
            dataIndex: "Name",
            key: "name",
            width: "20%",
            render: (item: any) => <div className="pl-4">{item.name}</div>,
        },
        {
            title: "Todo",
            dataIndex: "Todo",
            key: "todo",
            width: "20%",
            render: (item: any) => <div className="pl-4">{item.todo}</div>,
        },
        {
            title: "InProgress",
            dataIndex: "InProgress",
            key: "inprogress",
            width: "20%",
            render: (item: any) => <div className="pl-4">{item.inprogress}</div>,
        },
        {
            title: "Done",
            dataIndex: "Done",
            key: "done",
            width: "20%",
            render: (item: any) => <div className="pl-4">{item.done}</div>,
        },


    ];

    const createHead = (withWidth: boolean) => {
        return {
            cells: [
                {
                    key: '',
                    content: '',
                    width: 5
                },
                {
                    key: "Name",
                    content: "Name",
                    width: 25
                },
                {
                    key: "Todo",
                    content: "Todo",
                    width: 6
                },
                {
                    key: "InProgress",
                    content: "InProgress",
                    width: 6
                },
                {
                    key: "Done",
                    content: "Done",
                    width: 6
                },

            ],
        };
    };

    const head = createHead(true);


    return (
        <>
            {

                isOpen &&
                <Modal onCancel={closeModal} width="large">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e8ecf3",
                        paddingLeft: 10,
                        fontSize: 14,
                        paddingTop: 5,
                        paddingRight: 10,
                        justifyContent: "space-between"
                    }}>
                        <p style={{ fontSize: 14, fontWeight: 600 }}>Project list</p>
                        <div>
                            <Button onClick={() => {

                                closeModal()

                            }}>
                                {/* <CrossIcon
                                    label="Close Modal"
                                    primaryColor={token('color.text.subtle', N500)}
                                /> */}
                            </Button>
                        </div>
                    </div>


                    <div>
                        {
                            projectList.length > 0 ?
                                <div style={{
                                    height: "58vh",
                                    overflowX: "scroll",
                                    padding: "25px"
                                }}>

                                    <Table
                                        bordered
                                        scroll={{ y: 600 }}
                                        dataSource={projectList}
                                        pagination={false}
                                        columns={columns}

                                    />
                                    {/* <DynamicTable
                                                head={head}
                                            rows={projectList.map((data: any, index: number) => ({
                                                    key: `row-${index}-${data.id}`,
                                                    isHighlighted: false,
                                                cells: [
                                                    {
                                                        content: <div style={{
                                                            borderRadius: 50,
                                                            fontSize: 13,
                                                            backgroundColor: "#eef2f9",
                                                            fontWeight: 500,
                                                            color: "#5770d9",
                                                            height: "30px",
                                                            width: "30px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}>{data.alias}</div>
                                                    },
                                                        {
                                                            content: <p  style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans", cursor: "pointer" }}>{data.name}</p>
                                                    },
                                                    {
                                                        content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{data.todo}</p>
                                                    },
                                                    {
                                                        content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{data.inprogress}</p>
                                                    },
                                                    {
                                                        content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{data.done}</p>
                                                    },

                                                    ],
                                                }))}
                                                defaultPage={1}
                                                loadingSpinnerSize="large"
                                            /> */}
                                </div>
                                :
                                <div style={{ marginBottom: "10%" }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "10%"
                                    }}>
                                        <img src={process.env.PUBLIC_URL + "/noTask.svg"} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <h3 style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}>No task yet</h3>
                                        <p style={{ marginTop: 4, fontFamily: "Open Sans", fontSize: 14 }}>You have no available task to display</p>
                                    </div>
                                </div>
                        }
                    </div>



                </Modal>

            }

        </>
    )
}

export default AllProjectList