import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  TimePicker,
  Input,
  Modal,
  FormInstance,
  message,
} from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import BooksService from "../../Services/Books";
import { useDispatch, useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import {
  meetingRefreshState,
  refreshMeeting,
} from "../../store/slices/refresh-slice";
import { PlusOutlined } from "@ant-design/icons";
interface MeetingTime {
  startTime: string; // Example format: '12:30'
  endTime: string; // Example format: '14:00'
}
// Date
const today = new Date();
const minDate = dayjs(today);
const { RangePicker } = DatePicker;

const MeetingToothbar = ({
  setMeetingId,
  setFromDate,
  setToDate,
  getMeetingData,
  meetingId,
  fromDate,
  toDate,
}: any) => {
  // const refreshmeeting = useSelector(meetingRefreshState);
  const dispatch = useDispatch();
  const formRef = React.createRef<FormInstance>();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(userData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Project Selection Api
  const [displayValueProject, setDisplayValueProject] = useState<any>([]);
  const [displayValuePlace, setDisplayValuePlace] = useState();
  const [meetingPlace, setMeetingPlace] = useState("");
  const [projectFilter, setProjectFilter] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [projectoption, setProjectOption] = useState([]);
  // Meeting Goal
  const [meetingGoal, setMeetingGoal] = useState("");
  // Meeting Lead
  const [displayValueLead, setDisplayValueLead] = useState<any>([]);
  const [meetingLead, setMeetingLead] = useState<
    { id: any; name: any; email: any; profileImage: any; File: any }[]
  >([]);
  const [projectuserarrfilter, setprojectuserarrfilter] = useState([]);
  const [assigneeOption, setAssigneeOption] = useState([]);
  // Attendees
  const [displayValueParticipent, setDisplayValueParticipent] = useState<any>(
    []
  );
  const [userList, setUserList] = useState([]);
  // Date
  const [date, setDate] = useState("");
  // Time Range
  const [meetingTime, setMeetingTime] = useState<MeetingTime>({
    startTime: "",
    endTime: "",
  });
  const handleTimeChange = (value: any, timeString: string[]) => {
    // console.log("value", timeString);
    if (timeString.length === 2) {
      setMeetingTime({ startTime: timeString[0], endTime: timeString[1] });
    } else {
      console.error("Invalid time range selected");
    }
  };

  // Modle
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = (values: any) => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //Form
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    //  console.log("Form values:", values);
    const projectData: any = projectoption.find(
      (p: any) => p.value === values?.projectDetail[0]
    );
    const attendeesData: any = assigneeOption.find(
      (p: any) => p.value === values?.Attendees[0]
    );
    const meetingLeadData: any = assigneeOption.find(
      (p: any) => p.value === values?.MeetingLead[0]
    );
    const data = {
      projectDetail: [
        {
          id: values?.projectDetail[0],
          name: projectData?.label,
        },
      ],
      Attendees: [
        {
          id: values?.Attendees[0],
          name: attendeesData?.label,
          email:
            attendeesData && attendeesData.email ? attendeesData.email : "",
          profileImage:
            attendeesData && attendeesData.profileImage
              ? attendeesData.profileImage
              : "",
        },
      ],
      // PMaster: "SomeValue",
      Description: "",
      MeetingTime: {
        endTime: meetingTime.endTime,
        startTime: meetingTime.startTime,
      },
      MeetingGoal: meetingGoal,
      MeetingPlace: displayValuePlace,
      MeetingLead: [
        {
          id: values?.MeetingLead[0],
          name: meetingLeadData?.label,
          email:
            meetingLeadData && meetingLeadData.email
              ? meetingLeadData.email
              : "",
          profileImage:
            meetingLeadData && meetingLeadData.profileImage
              ? meetingLeadData.profileImage
              : "",
        },
      ],
      Date: dayjs(date).toISOString(),
      File: meetingLeadData?.File || [],
    };

    console.log("data", assigneeOption);

    // Api Call addMeeting
    try {
      const result = await BooksService.addMeeting(data);
      handleOk(values);
      // Submit button Notification
      if (result?.result) {
        form.resetFields();
        message.success("Meeting created successfully");
      } else {
        message.error("Failed to create Meeting. Please try again.");
      }
    } catch (error) {
      console.error("Error adding meeting:", error);
      message.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };
  // Project Selection Api
  const fetchProjects = async () => {
    try {
      const res = await BooksService.getProjectByUser(user?.id);
      const userdatatype = res.result.map((value: any) => ({
        label: value.name,
        value: value.id,
      }));
      setProjectOption(userdatatype);
    } catch (err) {
      console.error(err);
      dispatch(refreshMeeting());
    }
  };

  // Meeting Lead Api
  const getAllUsers = async () => {
    await BooksService.getUser(2000).then((res: any) => {
      // console.log("resresres = ", res);
      setprojectuserarrfilter(res.result);
      setAssigneeOption(
        res?.result?.map((item: any) => ({
          label: item.name,
          value: item.id,
          email: item.email,
          profileImage: item.profileImage,
          File: item.File,
        }))
      );
    });
  };

  useEffect(() => {
    fetchProjects();
    getAllUsers();
  }, []);

  // Place Selection
  const handlePlaceSelect = (value: any) => {
    setDisplayValuePlace(value);
    setMeetingPlace(value);
  };

  // Project Selection
  const handleProjectSelect = (values: any) => {
    setDisplayValueProject(values);
    let filterstring = "";
    let projectDetail = values.map((values: any) => {
      if (filterstring === "") {
        filterstring += values.id;
      } else filterstring += values.id + ",";
      return {
        id: values.id,
        name: values.name,
      };
    });
    setProjectFilter(filterstring);
    setProjectList(projectDetail);
  };

  // Meeting Lead
  const handleMeetingLead = (value: any) => {
    setDisplayValueLead(value);
    if (!Array.isArray(value)) {
      console.error("Value is not an array:", value);
      return;
    }
    const payloadObject = value.map((item: any) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      profileImage: item.profileImage ? item.profileImage : "",
      File: item.File ? item.File : "",
    }));

    console.log(payloadObject);
    setMeetingLead(payloadObject);
  };

  // Attendees
  const handleParticipentSelect = (value: any) => {
    setDisplayValueParticipent(value);

    let payloadObject = value.map((values: any) => ({
      id: values.id,
      name: values.name,
      email: values.email,
      profileImage: values.profileImage ? values.profileImage : "",
    }));

    // console.log(payloadObject);
    setUserList(payloadObject);
  };

  //SearchFilter
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  return (
    <div
      className="pt-5 pb-4 pr-4 Modelupmeeting"
      style={{ backgroundColor: "#f3f4fa" }}
    >
      {/* Input, RangePicker, search */}
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={22} lg={19} xl={15} xxl={12}>
          <Form
            layout="inline"
            onFinish={(data) => {
              // console.log("data",data);

              getMeetingData();
            }}
          >
            <Form.Item name="project" label="">
              <Select
                placeholder="All"
                // className="meeting"
                style={{ width: 300, paddingLeft: 20, height: "2.80em" }}
                options={[{ label: "All", value: "" }, ...projectoption]}
                onChange={(value) => setMeetingId(value)}
                onSearch={onSearch}
                optionFilterProp="label"
                showSearch
              />
            </Form.Item>
            <Form.Item name="dateRange" label="From">
              <RangePicker
                // className="custom-range-picker"
                style={{ height: "2.59em" }}
                format="DD/MM/YYYY"
                placeholder={["DD/MM/YYYY", "DD/MM/YYYY"]}
                onChange={(dates) => {
                  if (dates && dates.length === 2) {
                    const fromDate = dayjs(dates[0]).format("YYYY-MM-DD");
                    const toDate = dayjs(dates[1]).format("YYYY-MM-DD");
                    setFromDate(fromDate);
                    setToDate(toDate);
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Col
                xs={4}
                sm={24}
                md={2}
                lg={2}
                xl={12}
                xxl={12}
                style={{ textAlign: "end" }}
              >
                <Button
                  style={{ borderRadius: "3px", marginTop: "2px" }}
                  type="primary"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Col>
            </Form.Item>
          </Form>
        </Col>
        <Col
          xs={12}
          sm={24}
          md={2}
          lg={4}
          xl={5}
          xxl={12}
          style={{ textAlign: "end" }}
        >
          <Button
            icon={<PlusOutlined style={{fontSize:'medium'}} />}
            onClick={showModal}
            style={{
              fontWeight: 500,
              padding: " 2px 12px",
              background: "#f3f4fa",
              borderColor: " #d3e3ff",
              color: "#377dff",
            }}
          >
            Schedule a Meeting
          </Button>
        </Col>
      </Row>
      <Modal
        bodyStyle={{
          overflowY: "scroll",
          maxHeight: "calc(100vh - 200px)",
          scrollbarWidth: "none",
        }}
        title={<span style={{ fontFamily: "Open Sans" }}>Schedule a Meeting</span>}
        open={isModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        width={450}
      >
        {/* Form Component */}
        <Form form={form} layout="vertical" onFinish={onFinish} ref={formRef}>
          {/* Project Selection */}
          <Form.Item
            name="projectDetail"
            label="Project"
            rules={[{ required: true, message: "Please enter project" }]}
          >
            <Select
              className="meetingselect"
              placeholder="Select..."
              mode="multiple"
              maxTagCount={2}
              defaultValue={displayValueProject}
              options={projectoption}
              onChange={handleProjectSelect}
              allowClear={true}
            ></Select>
          </Form.Item>

          {/* Meeting Goal */}
          <Form.Item
            name="MeetingGoal"
            label="Meeting Goal"
            rules={[{ required: true, message: "Please enter goal" }]}
          >
            <Input
              className="meetinginput"
              value={meetingGoal}
              autoComplete="none"
              onChange={(e: any) => {
                setMeetingGoal(e.target.value);
              }}
              name="basic"
              id="basic-textfield"
            />
          </Form.Item>

          {/* Meeting Lead */}
          <Form.Item
            name="MeetingLead"
            label="Meeting Lead"
            rules={[{ required: true, message: "Please enter lead" }]}
          >
            <Select
              className="meetingselect"
              placeholder="Select..."
              mode="multiple"
              maxTagCount={2}
              defaultValue={displayValueLead}
              options={assigneeOption}
              onChange={handleMeetingLead}
              onSearch={onSearch}
              optionFilterProp="label"
            />
          </Form.Item>
          {/* Attendees */}
          <Form.Item
            name="Attendees"
            label="Attendees"
            rules={[{ required: true, message: "Please enter attendees" }]}
          >
            <Select
              className="meetingselect"
              mode="multiple"
              placeholder="Select..."
              maxTagCount={2}
              defaultValue={displayValueParticipent}
              options={assigneeOption}
              onChange={handleParticipentSelect}
              onSearch={onSearch}
              optionFilterProp="label"
            ></Select>
          </Form.Item>

          {/* Date */}
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Please enter date" }]}
            hasFeedback
          >
            <DatePicker
              style={{ cursor: "pointer" }}
              className="meetingdatepicker"
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              onChange={(e: any) => setDate(e)}
              minDate={minDate}
              autoFocus={true}
            />
          </Form.Item>
          {/* Time Range */}
          <Form.Item
            name="MeetingTime"
            label="Select Time"
            rules={[{ required: true, message: "Please enter valid time" }]}
            hasFeedback
          >
            <TimePicker.RangePicker
              style={{ cursor: "pointer" }}
              className="meetingTimePicker"
              format="h:mm a"
              minuteStep={30}
              onCalendarChange={handleTimeChange}
              autoFocus={true}
            // inputReadOnly={false}
            />
          </Form.Item>

          {/* Place Selection */}
          <Form.Item
            name="MeetingPlace"
            label="Place"
            rules={[{ required: true, message: "Please enter place" }]}
            hasFeedback
          >
            <Select
              className="meetingSelect"
              placeholder="Select..."
              defaultValue={displayValuePlace}
              options={[
                { label: "Board room", value: "BoardRoom" },
                { label: "Board room mini", value: "Board Room mini" },
              ]}
              onChange={handlePlaceSelect}
            ></Select>
          </Form.Item>
          {/* Form Actions */}
          <Form.Item style={{ textAlign: "right", marginTop: "10px" }}>
            <Button onClick={handleCancel} style={{ marginRight: "10px" }}>
              Cancel
            </Button>

            <Button
              htmlType="submit"
              type="primary"
            // onClick={() => form.resetFields()}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MeetingToothbar;
