import * as XLSX from 'xlsx';

export function readFile(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        // Create file reader
        let reader = new FileReader();

        // Register event listeners
        reader.addEventListener("loadend", (e) => {
            if (e.target?.result) {
                resolve(e.target.result as ArrayBuffer);
            } else {
                reject(new Error("File reading failed"));
            }
        });
        reader.addEventListener("error", reject);

        // Read file
        reader.readAsArrayBuffer(file);
    });
}

export async function getAsByteArray(file: File): Promise<Uint8Array> {
    const arrayBuffer = await readFile(file);
    return new Uint8Array(arrayBuffer);
}

export const exportToExcel = (
    data: Array<Array<any>>,
    filename: string,
    defaultSheetName: string = "Sheet1"
): void => {
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, defaultSheetName);

    // Save the file
    XLSX.writeFile(wb, filename);
};
