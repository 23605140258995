import { Spin } from "antd";

interface LoaderProps {
  loading: boolean;
  message?: string;
}

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <Spin 
    spinning={props.loading}
    size="large"
     fullscreen >
      {props.message ? props.message:"Loading..." }
     </Spin>
  );
};

export default Loader;
