import React from 'react'
import LogDetailList from './dashboard/LogsDetail/LogDetailList'

const Log = () => {
    return (
        <>
            <LogDetailList />
        </>
    )
}

export default Log