import IssueList from "./createIssue/IssueList"

const Issue = () => {
  return (
  <>
  <IssueList/>
  </>
  )
}

export default Issue