import React, { useState } from "react";
import InHand from "../components/Reports/InHandTabel";
import BooksService from "../Services/Books";
import Userratingtoothbar from "../components/commoncomponents/InhandToothbar";
import { Breadcrumb, Col, Row, Spin } from "antd";
import { userData } from "../store/slices/user-slice";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const InHandReport = () => {
  const user = useSelector(userData);
  const [projectdata, setptojectdata] = useState([]);
  const [projectname, setprojectname] = useState(user?.id);
  const [projectid1, setprojectid1] = useState("");
  const [userbyproject, setuserbyproject] = useState<any>([]);
  const [statusFilter, setstatusFilter] = useState("0,1,2,3,4,5,7");
  const [isloading, setisloading] = useState(false);

  const fetchInHandData = async (projectid: string, assigneeId: string) => {
    try {
      setisloading(true);
      let res = await BooksService.getinHandTask(
        projectid,
        assigneeId,
        statusFilter
      );
      setuserbyproject(res.result);
      setisloading(false);
    } catch (err) { }
  };

  return (
    <>
      <div>
        <Row
          gutter={[24, 16]}
          justify={"space-between"}
          style={{ marginBottom: 6 }}
        >
          <Col>
            <Link to={"/reports"}>
              <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
            </Link>
          </Col>
          <Col>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to={"/dashboard"} className="BreadcrumbLink">
                      Home
                    </Link>
                  ),
                },
                {
                  title: (
                    <Link to={"/reports"} className="BreadcrumbLink">
                      Reports
                    </Link>
                  ),
                },
                {
                  title: "InHand",
                },
              ]}
            />
          </Col>
        </Row>

        <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
          <Col span={24}>
            <Row justify={"space-between"}>
              <Userratingtoothbar
                projectdata={projectdata}
                page={"InHand"}
                setstatusFilter={setstatusFilter}
                setuserbyproject={setuserbyproject}
                setptojectdata={setptojectdata}
                projectid={projectid1}
                setprojectid={setprojectid1}
                projectname={projectname}
                setprojectname={setprojectname}
                fetchInHandData={fetchInHandData}
              />
            </Row>
          </Col>

          <Col span={24}>
            <InHand projectid1={projectid1} userbyproject={userbyproject} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InHandReport;
