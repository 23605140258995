export const MOBILE_VIEW_WIDTH = 1024;

export enum userType {
  All,
  Assignee,
  AssignedBy,
  Reporter,
}

export const userOptions = [
  { label: "All", value: 0 },
  { label: "Assignee", value: 1 },
  { label: "Assigned By", value: 2 },
];

export enum STATUS {
  BACKLOG = 0,
  TODO = 1,
  NEEDTODISCUSS = 2,
  INPROGRESS = 3,
  UAT = 4,
  TESTING = 5,
  DONE = 6,
  DEVELOPED = 7,
  DUPLICATE = 8,
}

export const taskCategories = [
  "BACKLOG",
  "TODO",
  "NEEDTODISCUSS",
  "INPROGRESS",
  "UAT",
  "TESTING",
  "DONE",
  "DEVELOPED",
  "DUPLICATE",
];

export const statusArray = [
  { value: STATUS.BACKLOG, label: "Backlog" },
  { value: STATUS.TODO, label: "Todo" },
  { value: STATUS.NEEDTODISCUSS, label: "Need To Discuss" },
  { value: STATUS.INPROGRESS, label: "In Progress" },
  { value: STATUS.UAT, label: "UAT" },
  { value: STATUS.TESTING, label: "Testing" },
  { value: STATUS.DONE, label: "Done" },
  { value: STATUS.DEVELOPED, label: "Developed" },
  { value: STATUS.DUPLICATE, label: "Duplicate" },
];


export const STATUS_ARRAY = [
  "BACKLOG",
  "TODO",
  "NEEDTODISCUSS",
  "INPROGRESS",
  "UAT",
  "TESTING",
  "DONE",
  "DEVELOPED",
  "DUPLICATE",
];


export const GRID_VIEW = "grid";
export const LIST_VIEW = "list";

export const userRole={
  admin:"Admin",
  staff:"Staff",
  teamLeader:"Teamleader"
}