import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, FormProps, message, Upload, UploadProps } from "antd";
import React, { ReactNode } from "react";

interface IFileUploader {
  Form: FormProps;
  Upload?: UploadProps;
  fieldName: string;
  children?: ReactNode;
}

const FileUploader = ({
  Form: FormProps,
  Upload: UploadProps,
  fieldName,
  children,
}: IFileUploader) => {
  const { form, ...restFormProps } = FormProps;
  const [frm] = Form.useForm(form);
  const normFile = (e: any) => {
    if (e?.fileList?.length <= 0) {
      frm.setFieldValue(fieldName, []);
      return [];
    }
    Promise.all(
      e?.fileList?.map(async (itm: any) => await getBase64(itm))
    ).then((values: any) => {
      const convertedData = values?.map((x: any) => ({
        ...x?.file,
        convertedFile: {
          name: x?.file?.name,
          existingType: 1,
          path: x?.base64,
        },
      }));
      frm.setFieldValue(fieldName, [...convertedData]);
    });

    return e?.fileList;
  };
  const getBase64 = async (file: any) => {
    if (file?.originFileObj) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader?.readAsDataURL(file?.originFileObj);
        reader.onload = () => {
          resolve({ file, base64: reader.result });
        };
        reader.onerror = reject;
      });
    }
    return file;
  };
  return (
    <>
      <Form form={frm} {...restFormProps}>
        <Form.Item
          name={fieldName}
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            {...UploadProps}
            listType={UploadProps?.listType ?? "picture"}
            multiple={UploadProps?.multiple ?? true}
            maxCount={UploadProps?.maxCount ?? 20}
            onPreview={async (file: any) => {
              let newWindow = window.open();
              if (newWindow) {
                newWindow?.document.write(
                  `<iframe src="${file?.convertedFile?.path}" alt=${file?.name} style="width:100%;height:100%;border:none;" />`
                );
                newWindow.document.body.style.margin = "0px";
                newWindow.document.title = file?.name;
              } else {
                message.error(`Error during preview of ${file?.name}`);
              }
            }}
            beforeUpload={() => false}
          >
            {children ?? (
              <Button type="primary" icon={<UploadOutlined />}>
                Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
      </Form>
    </>
  );
};
export default React.memo(FileUploader);
