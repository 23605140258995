import { Card, Col, Row, Skeleton } from "antd";
import * as React from "react";
import Meta from "antd/es/card/Meta";

interface ISkeletonTableProps {
  rows?: number;
  rowBorder?: boolean;
  loading?:boolean
}

const ProjectCradSkeleton = ({ rows = 10, rowBorder = true,loading=true }: ISkeletonTableProps) => {
  return (
    <Card className="main-border mt-1 mb-2 ml-1 mr-1 " >
      <Row align="middle" style={{ width: "100%" }}>
        <Col>
          <div
            style={{
              width: "8px",
              height: "50px",
            //   backgroundColor: "#dfe6e9",
              borderRadius: "7px",
              marginRight: "16px",
              marginLeft:" 16px"
            }}
          >
              <Skeleton.Input  className="rotate-360 "  style={{ width: "100%" }} active />
          </div>
        </Col>
        <Col flex="auto">
          <Meta
            title={
              <Row justify="space-between" align="middle">
                <Col span={4} >
                {/* <Skeleton.Image  active /> */}
                <Skeleton.Avatar size={38} active shape="square" />
                </Col>
                <Col span={16}>
               
                  
                    <Skeleton.Input style={{ width: "100%", }} active />
              
                    <Skeleton.Input style={{ width: "95%", marginTop: 4, marginRight: "40%" }} active />
                        

                </Col>
                <Col span={3} >
                  <Skeleton.Avatar size={25} active shape="circle" />
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default React.memo(ProjectCradSkeleton);
