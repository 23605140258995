import React, { useMemo } from "react";
import { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import SunEditor from "suneditor-react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  Html5Outlined,
  JavaScriptOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { getPostFormatChild, getPostFormatParent } from "../../../utils/Util";
import { ModalTemplate } from "../Modal/Modal";
import NoData from "../../NoData";
import { exportToExcel } from "../../../utils/fileUtils";
import {
  Col,
  Divider,
  Row,
  Typography,
  Button,
  Table,
  Popconfirm,
  notification,
  Drawer,
  Space,
  message,
  Spin,
} from "antd";
import { Form, Input, Select, DatePicker, Checkbox } from "antd";
import { PlusOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Tabs, Tooltip } from "antd";
import { FileExcelOutlined, DownloadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { userData } from "../../../store/slices/user-slice";
import {
  projectData as PD,
  projectData,
} from "../../../store/slices/project-slice";
import { IApiResponse } from "../../../Services/ApiUtility";
import { dayjs } from "../../../utils/dayjs";
import AddEditBranch from "../../SidebarBranch/AddEditBranch";
import { AddEditApis, SaveType } from "../../../Services/RQTaskService";
import FileUploader from "../../../common/FilesUploader";
interface Props {
  createType: CreateType;
  callback?: () => void;
  configData?: Config;
  data?: any;
  onDismiss?: () => void;
}

interface Config {
  parantTaskId: string;
}

export enum TaskSaveType {
  Save,
  SaveAndNew,
}
export enum CreateType {
  Task,
  Child,
  Copy,
}
export enum ProjectStatus {
  Active,
  InActive,
}
const TaskModel = ({
  data,
  createType,
  configData = { parantTaskId: "" },
  onDismiss = undefined,
}: Props) => {
  const [form] = Form.useForm();
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const [selectedProject, setSelectedProject] = useState<any>(project?.id);
  const [currentUKey, setCurrentUKey] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<{
    branch: {
      search: undefined;
    };
    issue: {
      search: undefined;
    };
    user: {
      search: undefined;
    };
    assignee: {
      search: undefined;
    };
    project: {
      search: undefined;
    };
  }>({
    branch: {
      search: undefined,
    },
    issue: {
      search: undefined,
    },
    user: {
      search: undefined,
    },
    assignee: {
      search: undefined,
    },
    project: {
      search: undefined,
    },
  });

  const {
    useTask,
    useBranch,
    useIssue,
    useProject,
    useUser,
    useAssignee,
    useUkey,
  } = AddEditApis;

  const { mutateAsync: mutateAsyncParent, isPending: isTaskPostParent } =
    useTask(SaveType.Single);
  const {
    mutateAsync: mutateAsyncParentChild,
    isPending: isTaskPostParentChild,
  } = useTask(SaveType.Multiple);
  const { data: branchDataList, isPending: isBranchPending } = useBranch(
    searchFilter?.branch
  );
  const { data: issueDataList, isPending: isIssuePending } = useIssue();
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
    id: selectedProject,
    ...searchFilter?.assignee,
  });
  const { data: projectDataList, isPending: isProjectPending } = useProject({
    id: user?.id,
    ...searchFilter?.project,
  });

  const { data: uKeyData, isPending: isuKeyPending } = useUkey(
    selectedProject,
    currentUKey
  );

  const projectOptions = useMemo(() => {
    return projectDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [projectDataList]);

  const issueTypesOptions = useMemo(() => {
    return issueDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [issueDataList]);

  const branchOptions = useMemo(() => {
    return branchDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [branchDataList]);

  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);

  const assigneeOptions = useMemo(() => {
    return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
  }, [userAssigneeList, selectedProject]);

  const uKey = useMemo(() => {
    return uKeyData?.result || "";
  }, [uKeyData, selectedProject]);

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(true);
  const [myDescription, setMyDescription] = useState<any>();
  const [ChildTaskSheetData, setChildTaskSheetData] = useState<ImageListType>(
    []
  );

  const [childAttachmentIndex, setChildAttachmentIndex] = useState<number>(-1);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [isExcelModel, setIsExcelModel] = useState<boolean>(false);

  const [childForm] = Form.useForm();
  const [isCreateBranch, setIsCreateBranch] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const assigneeToMe = useCallback(
    (assignToMe: { label: string; value: string } | undefined) => {
      const assignees = form.getFieldValue("assigneeIds");
      const isAlready = assignees?.find((x: any) => x?.value === user?.id);
      if (!!assignToMe) {
        if (assignees?.length > 0) {
          if (!isAlready)
            form.setFieldValue("assigneeIds", [assignToMe, ...assignees]);
        } else form.setFieldValue("assigneeIds", [assignToMe]);
        setAssignToMeChecked(true);
      } else {
        const restAssignees = assignees?.filter(
          (x: any) => x?.value !== user?.id
        );
        if (assignees?.length > 0)
          form.setFieldValue(
            "assigneeIds",
            restAssignees?.length > 0 ? restAssignees : undefined
          );
        setAssignToMeChecked(false);
      }
    },
    []
  );

  const isAssigneeLoginUser = useCallback(
    (): boolean | undefined =>
      form
        ?.getFieldValue("assigneeIds")
        ?.find((x: any) => x?.value === user?.id),
    []
  );
  const [assignToMeChecked, setAssignToMeChecked] =
    useState<any>(isAssigneeLoginUser);

  const ExcelImport = async () => {
    return new Promise((resolve, reject) => {
      let file = ChildTaskSheetData[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          try {
            const binaryString = e.target.result;
            var workbook = XLSX.read(binaryString, {
              type: "binary",
            });
            var first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
            var jsonArr = XLSX.utils.sheet_to_json(first_worksheet, {
              header: 1,
            });
            resolve(jsonArr);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsBinaryString(file.file as unknown as Blob);
      } else {
        reject("File not available");
      }
    });
  };

  const HandleExcelImport = async () => {
    try {
      const response = await ExcelImport();
      if (response && Array.isArray(response)) {
        const childTaskClone = [
          ...(childForm?.getFieldsValue()?.childTasks ?? []),
        ];
        response?.forEach((ele: any, index: number) => {
          if (index !== 0)
            childTaskClone.push({
              index: childTaskClone?.length,
              title: ele[ele?.length - 1],
              attachments: [],
            });
        });
        childForm.setFieldValue("childTasks", [...childTaskClone]);
        // setChildTasks(childTaskClone);
        setChildTaskSheetData([]);
        setIsExcelModel(false);

        // Success notification
        notification.success({
          message: "Excel Import Successful",
          description:
            "The tasks have been successfully imported from the Excel file.",
          placement: "bottomLeft",
        });
      } else {
        // Handle case where response is not as expected
        notification.warning({
          message: "Excel Import Warning",
          description:
            "The data from the Excel file is not in the expected format.",
          placement: "bottomLeft",
        });
      }
    } catch (error: any) {
      // Error notification
      notification.error({
        message: "Excel Import Error",
        description: `An error occurred while importing the Excel file: ${error.message}`,
        placement: "bottomLeft",
      });
    }
  };

  const HandleExcelFormatExport = () => {
    let data = [["S.No", "Title"]];
    exportToExcel(data, "format.xlsx", "defaultSheet");
  };

  //Add child Task
  const ChildTaskGenerator: React.FC = () => {
    return (
      <>
        <Form form={childForm} name="dynamic_form_nest_item" autoComplete="off">
          <Form.List name="childTasks">
            {(fields, { add, remove }) => (
              <>
                <Form.Item>
                  <Row align={"middle"} gutter={[10, 16]}>
                    <Col>
                      <Tooltip title="add child task">
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add();
                          }}
                        >
                          Task
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip title="import spreadsheet">
                        <Button
                          icon={<FileExcelOutlined />}
                          onClick={() => setIsExcelModel(true)}
                        >
                          Spread sheet
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip title="download template">
                        <Button
                          icon={<DownloadOutlined />}
                          onClick={HandleExcelFormatExport}
                        >
                          Download
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Form.Item>
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Row
                      key={key}
                      gutter={[0, 0]}
                      style={{ alignItems: "baseline" }}
                    >
                      <Col span={1}>
                        <div style={{ marginTop: 2 }}>{name + 1}</div>
                      </Col>
                      <Col span={19}>
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Title"
                            variant="borderless"
                            className="borderLessInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item name={[name, "attachments"]}>
                          <ImageUploading
                            multiple
                            value={childForm.getFieldValue([
                              name,
                              "attachments",
                            ])}
                            onChange={(imagelist: ImageListType) => {
                              childForm.setFieldValue(
                                [name, "attachments"],
                                imagelist
                              );
                            }}
                            allowNonImageType={true}
                            dataURLKey="data_url"
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemove,
                              onImageRemoveAll,
                            }) => (
                              <Row
                                gutter={[0, 0]}
                                justify={"space-evenly"}
                                key={`childTaskAtt-${key}`}
                              >
                                <Col
                                  span={1}
                                  className="cursor"
                                  onClick={() => {
                                    onImageRemoveAll();
                                    remove(name);
                                  }}
                                >
                                  <Button
                                    type="text"
                                    danger
                                    size="small"
                                    icon={<DeleteOutlined />}
                                  />
                                </Col>

                                <Col
                                  span={2}
                                  className="cursor"
                                  onClick={onImageUpload}
                                >
                                  <Button
                                    size="small"
                                    type="text"
                                    icon={<PaperClipOutlined />}
                                  />
                                </Col>
                                <Col
                                  span={1}
                                  className="cursor"
                                  onClick={() => {
                                    setCurrentIndex(name);
                                    setChildAttachmentIndex(name);
                                  }}
                                >
                                  <Button
                                    size="small"
                                    type="text"
                                    icon={<EyeOutlined />}
                                  >
                                    {childForm.getFieldValue([
                                      name,
                                      "attachments",
                                    ])?.length > 0 &&
                                      childForm.getFieldValue([
                                        name,
                                        "attachments",
                                      ])?.length}
                                  </Button>
                                </Col>
                                {childAttachmentIndex === name && (
                                  <ModalTemplate
                                    width="800px"
                                    closeModal={() =>
                                      setChildAttachmentIndex(-1)
                                    }
                                    title="Attachment"
                                    shouldShowFooter={false}
                                  >
                                    <ChildTaskAttachmentRender
                                      onDelete={(index: any) =>
                                        onImageRemove(index)
                                      }
                                      currentIndex={currentIndex}
                                    />
                                  </ModalTemplate>
                                )}
                              </Row>
                            )}
                          </ImageUploading>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </>
    );
  };

  interface ChildTaskAttachmentRenderProps {
    currentIndex: any;
    onDelete: (index: any) => void;
  }
  const formatBytes = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const getIcon = (fileName: string) => {
    const extension = fileName?.split(".")?.[1]?.toLocaleLowerCase();
    let icon = <FileUnknownOutlined style={{ fontSize: 16, color: "red" }} />;
    if (extension) {
      if (extension === "xlsx") icon = <FileExcelOutlined />;
      else if (extension === "gif") icon = <FileGifOutlined />;
      else if (
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === ".apng" ||
        extension === ".avif" ||
        extension === "webp" ||
        extension === "ico" ||
        extension === "svg"
      )
        icon = <FileImageOutlined />;
      else if (extension === "pdf" || extension === "pdfx")
        icon = <FilePdfOutlined style={{ fontSize: 16, color: "red" }} />;
      else if (extension === "ppt" || extension === "pptx")
        icon = <FilePptOutlined />;
      else if (extension === "txt") icon = <FileTextOutlined />;
      else if (extension === "doc" || extension === "docx")
        icon = <FileWordOutlined />;
      else if (extension === "zip") icon = <FileZipOutlined />;
      else if (extension === "html")
        icon = <Html5Outlined style={{ fontSize: 16, color: "red" }} />;
      else if (extension === "js") icon = <JavaScriptOutlined />;
    }

    return icon;
  };
  const ChildTaskAttachmentRender: React.FC<ChildTaskAttachmentRenderProps> = ({
    currentIndex,
    onDelete,
  }) => {
    const columns = [
      {
        title: "",
        dataIndex: "icon",
        key: "icon",
        width: 25,
        render: (_: any, record: any) => {
          return <>{getIcon(record?.name)}</>;
        },
      },
      {
        title: "File",
        dataIndex: "file",
        key: "file",
        render: (_: any, record: any) => {
          return <>{record?.name?.split(".")?.[0]}</>;
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        ellipsis: true,
      },
      {
        title: "Size",
        dataIndex: "index",
        key: "size",
        width: 150,
        render: (text: any, record: any) => formatBytes(record?.size),
      },
      {
        title: "",
        key: "action",
        width: 30,
        render: (text: any, record: any) => (
          <Popconfirm
            title="Are you sure you want to delete this file?"
            onConfirm={() => {
              childForm.setFieldValue(
                [currentIndex, "attachments"],
                childForm
                  .getFieldValue([currentIndex, "attachments"])
                  ?.filter((x: any) => x?.data_url !== record?.data_url)
              );
              onDelete(currentIndex);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ];
    let att: any = childForm?.getFieldValue([currentIndex, "attachments"]);

    const dataSource =
      currentIndex !== null && att?.length > 0
        ? att?.map((data: any, index: number) => ({
            key: `row-att-${index}`,
            name: data?.file?.name,
            size: data?.file?.size,
            type: data?.file?.type,
            data_url: data?.data_url,
          }))
        : [];
    return (
      <>
        {dataSource.length > 0 ? (
          <Table
            scroll={{ y: 300 }}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            loading={false}
            className="capTask-custom-table"
          />
        ) : (
          <NoData message="No Attachments" />
        )}
      </>
    );
  };

  const addParent = async (data: any) => {
    try {
      const convertedData = await getPostFormatParent(
        data,
        myDescription,
        selectedProject,
        user
      );
      const response = await mutateAsyncParent({...convertedData,taskId:configData?.parantTaskId});
      return response;
    } catch (e) {
      message.error("Error in add parent task => " + e);
      console.error("Error in add parent task => ", e);
    }
  };
  const addParentWithChild = async (parent: any, child: any) => {
    try {
      const response = await mutateAsyncParentChild({
        parant: {
          ...(await getPostFormatParent(
            parent,
            myDescription,
            selectedProject,
            user
          )),
        },
        childs: [...(await getPostFormatChild(child))],
      });

      return response;
    } catch (e) {
      message.error("Error in add child task => " + e);
      console.error("Error in add child task => ", e);
    }
  };
  const onFinish = async (isSave: boolean) => {
    let apiRes: IApiResponse<any> | undefined = undefined;
    const parentTaskValues = await form.validateFields();
    const childTaskValues = await childForm.validateFields();

    // checking if parent and child
    if (
      Object.keys(childTaskValues)?.length > 0 &&
      childTaskValues?.childTasks?.length > 0 &&
      Object.keys(parentTaskValues)?.length > 0
    ) {
      apiRes = await addParentWithChild(parentTaskValues, childTaskValues);
    } else if (Object.keys(parentTaskValues)?.length > 0) {
      apiRes = await addParent(parentTaskValues);
    }
    // check response of api
    if (apiRes?.result) {
      if (!isSave) setCurrentUKey(uKey);
      message.success("Task created successfully");
      setTimeout(() => {
        onDrawerClose(isSave);
      }, 800);
    } else {
      message.error(`Error =>  ${apiRes?.message}`);
    }
  };
  const tabItems: any = [
    {
      key: "1",
      label: "Description",
      children: (
        <>
          <SunEditor
            setContents={myDescription}
            height="180"
            width="100%"
            setOptions={{
              buttonList: [
                ["undo", "redo"],
                ["font", "fontSize"],
                ["bold", "underline", "italic"],
                ["fontColor", "hiliteColor"],
                ["align", "list", "lineHeight"],
                ["outdent", "indent"],
                ["link", "image"],
                ["removeFormat"],
              ],
              defaultTag: "div",
              showPathLabel: false,
            }}
            onChange={(value) => {
              setMyDescription(value);
            }}
          />
        </>
      ),
    },
    // Add childTask TAB
    {
      key: "2",
      label: "Add child task",
      children: (
        <>
          <ChildTaskGenerator />
        </>
      ),
    },
    {
      key: "3",
      label: "Attachment",
      children: (
        <>
          <FileUploader Form={{ form: form }} fieldName="file" />
        </>
      ),
    },
  ];
  const onDrawerClose = (isSave: boolean = true) => {
    form.resetFields();
    childForm.resetFields();
    setAssignToMeChecked(false);
    if (isSave) {
      form.resetFields();
      setIsOpenDrawer(!isOpenDrawer);
      setTimeout(() => {
        onDismiss && onDismiss();
      }, 300);
    }
  };

  const preserveAssignToMe = useCallback(() => {
    if (isAssigneeLoginUser()) {
      form.setFieldValue(
        "assigneeIds",
        form
          .getFieldValue("assigneeIds")
          ?.filter((x: any) => x?.value === user?.id)
      );
    } else form.resetFields(["assigneeIds"]);
  }, []);

  return (
    <>
      {/* Modal Antd */}
      <Drawer
        title={
          <>
            Add a Issue
            <Typography.Text
              style={{ fontWeight: 600, fontSize: 15, marginLeft: "10px" }}
            >
              {uKey !== "" ? `(${uKey})` : ""}
            </Typography.Text>
          </>
        }
        extra={
          <CloseOutlined
            className="cursor"
            onClick={() => {
              onDrawerClose();
            }}
          />
        }
        closeIcon={false}
        width={800}
        // className="model-takbord"
        maskClosable={false}
        onClose={() => {
          onDrawerClose(false);
        }}
        open={isOpenDrawer}
        footer={
          <>
            <Row gutter={10} justify={"end"}>
              <Col>
                <Button
                  className="capTask-btn-light btn-m-sm"
                  onClick={() => {
                    onDrawerClose();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  loading={isTaskPostParent || isTaskPostParentChild}
                  className="btn-m-sm"
                  type="primary"
                  onClick={() => {
                    onFinish(true);
                  }}
                >
                  Save
                </Button>
              </Col>
              <Col>
                <Button
                  loading={isTaskPostParent || isTaskPostParentChild}
                  className="btn-m-sm"
                  type="primary"
                  onClick={() => {
                    onFinish(false);
                  }}
                >
                  Save and New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <>
          <Spin
            spinning={
              isAssigneePending ||
              isBranchPending ||
              isIssuePending ||
              isuKeyPending ||
              isProjectPending ||
              isUserPending
            }
            indicator={<LoadingOutlined spin={false} />}
            className="DrawerPadding"
          >
            <Form
              // onFinish={onFinish}
              // ref={formRef}
              form={form}
              labelAlign="left"
              layout="horizontal"
              colon={false}
              labelCol={{ span: 5 }}
              requiredMark={false}
              initialValues={{
                dueDate: dayjs(),
                reportersIds: {
                  label: user?.name,
                  value: user?.id,
                },
                orderNo: 0,
                projectIds: {
                  label: project?.name,
                  value: project?.id,
                },
                priority:0
              }}
              // onFinish={() => message.success("Submit success!")}
              // onFinishFailed={() => message.error("Submit failed!")}
              className="DrawerPadding"
            >
              <>
                <div>
                  {createType !== CreateType.Child && (
                    <>
                      <div>
                        <Form.Item name="projectIds" label="Project">
                          <Select
                            loading={isProjectPending}
                            searchValue={searchFilter?.project?.search}
                            variant="borderless"
                            className="borderLessInput w100"
                            options={projectOptions}
                            onSelect={(value: any) => {
                              preserveAssignToMe();
                              setSelectedProject(value);
                            }}
                            placeholder="Choose Project"
                            showSearch
                            onSearch={(value) => {
                              setSearchFilter((pre: any) => ({
                                ...pre,
                                project: {
                                  search: value,
                                },
                              }));
                            }}
                            optionFilterProp="label"
                          />
                        </Form.Item>
                      </div>
                    </>
                  )}

                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input the Title!",
                      },
                      {
                        whitespace: true,
                        message: "Title cannot be empty!",
                      },
                      {
                        min: 3,
                        message: "Title must be at least 3 characters long!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Title"
                      variant="borderless"
                      className="borderLessInput w100"
                      autoFocus
                    />
                  </Form.Item>

                  <Form.Item
                    name="priority"
                    label="Priority"
                    rules={[
                      {
                        required: true,
                        message: "this field is required",
                      },
                    ]}
                  >
                    <Select
                      variant="borderless"
                      className="borderLessInput w100"
                      options={[
                        { label: "High", value: 2 },
                        { label: "Medium", value: 1 },
                        { label: "Low", value: 0 },
                      ]}
                      placeholder="Choose Priority"
                      optionFilterProp="label"
                    />
                  </Form.Item>

                  <Form.Item label="Due Date" name="dueDate">
                    <DatePicker
                      className="borderLessInput w100"
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>

                  <Form.Item
                    name="labelIds"
                    label="Issue Type"
                    rules={[{ required: true, message: "Select label!" }]}
                  >
                    <Select
                      mode="multiple"
                      maxCount={5}
                      labelInValue
                      variant="borderless"
                      className="borderLessInput w100"
                      placeholder="Choose issue Type"
                      allowClear
                      searchValue={searchFilter?.issue?.search}
                      options={issueTypesOptions}
                      showSearch
                      loading={isIssuePending}
                      onSearch={(value) => {
                        setSearchFilter((pre: any) => ({
                          ...pre,
                          issue: {
                            search: value,
                          },
                        }));
                      }}
                      optionFilterProp="label"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Branch"
                    name="branch"
                    rules={[
                      { required: false, message: "Please select label!" },
                    ]}
                  >
                    <Select
                      allowClear
                      variant="borderless"
                      className="borderLessInput w100"
                      maxTagCount={5}
                      searchValue={searchFilter?.branch?.search}
                      options={branchOptions}
                      placeholder="Choose Branch"
                      labelInValue
                      showSearch
                      mode="multiple"
                      optionFilterProp="label"
                      loading={isBranchPending}
                      onSearch={(value) => {
                        setSearchFilter((pre: any) => ({
                          ...pre,
                          branch: {
                            search: value,
                          },
                        }));
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <Space style={{ padding: "0 0px 4px" }}>
                            <Button
                              size="small"
                              type="link"
                              icon={<PlusOutlined />}
                              onClick={() => setIsCreateBranch(true)}
                            >
                              Add Branch
                            </Button>
                          </Space>
                        </>
                      )}
                    />
                  </Form.Item>

                  <Form.Item label="Reporter" name="reportersIds">
                    <Select
                      labelInValue
                      variant="borderless"
                      className="borderLessInput w100"
                      placeholder="Choose reporter"
                      allowClear
                      options={reporterOptions}
                      loading={isUserPending}
                      searchValue={searchFilter?.user?.search}
                      showSearch
                      onSearch={(value) => {
                        setSearchFilter((pre: any) => ({
                          ...pre,
                          user: {
                            search: value,
                          },
                        }));
                      }}
                      optionFilterProp="label"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Assignee"
                    name="assigneeIds"
                    rules={[{ required: true, message: "Select-assignee!" }]}
                  >
                    <Select
                      onChange={(
                        selectedOptions: { label: string; value: string }[]
                      ) => {
                        assigneeToMe(
                          selectedOptions?.find(
                            (x: any) => x?.value === user?.id
                          )
                        );
                      }}
                      searchValue={searchFilter?.assignee?.search}
                      mode="multiple"
                      maxCount={5}
                      labelInValue
                      variant="borderless"
                      className="borderLessInput w100"
                      placeholder="Choose assignee"
                      allowClear
                      loading={isAssigneePending}
                      options={assigneeOptions}
                      showSearch
                      onSearch={(value) => {
                        setSearchFilter((pre: any) => ({
                          ...pre,
                          assignee: {
                            search: value,
                          },
                        }));
                      }}
                      optionFilterProp="label"
                    />
                  </Form.Item>

                  <Row justify="start">
                    <Col offset={5}>
                      <Checkbox
                        checked={assignToMeChecked}
                        onClick={(e: any) => {
                          if (e?.target?.checked)
                            assigneeToMe({
                              label: user?.name,
                              value: user?.id,
                            });
                          else assigneeToMe(undefined);
                        }}
                      >
                        <span style={{ color: "#091E42", fontSize: "13px" }}>
                          Assign to me
                        </span>
                      </Checkbox>
                    </Col>
                  </Row>
                </div>

                {/* Description Antd */}
              </>
              {/* Save,Save and New button  */}
            </Form>

            <Tabs
              className="DrawerPadding"
              style={{ marginTop: 50 }}
              items={
                configData?.parantTaskId === ""
                  ? tabItems
                  : tabItems?.filter((x: any) => x?.key === "1")
              }
            />
          </Spin>
        </>
      </Drawer>
      {isExcelModel && (
        <ModalTemplate
          closeModal={() => setIsExcelModel(false)}
          title="Add Excel"
          shouldShowFooter={true}
          buttonText="Upload"
          onClose={() => setChildTaskSheetData([])}
          onSubmit={HandleExcelImport}
        >
          <ImageUploading
            multiple
            value={ChildTaskSheetData}
            onChange={(file: ImageListType) => setChildTaskSheetData(file)}
            maxNumber={1}
            allowNonImageType={true}
            acceptType={["xlsx"]}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper">
                {imageList.length > 0 ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {imageList.map((item: any) => (
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ width: 400, height: 200 }}
                          src={process.env.PUBLIC_URL + "/xlxs.svg"}
                          alt=""
                        />
                        <p>{item.file.name}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    onClick={onImageUpload}
                    style={{ width: "100%", height: "100%" }}
                    {...dragProps}
                  >
                    <NoData message={`${isDragging ? "Dragging" : ""}`} />
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        </ModalTemplate>
      )}
      {isCreateBranch && (
        <AddEditBranch
          onDismiss={() => {
            setIsCreateBranch(false);
          }}
          data={undefined}
        />
      )}
    </>
  );
};
export default React.memo(TaskModel);
