import React from "react";
import { Col, Drawer, message, notification, Row } from "antd";
import {
  ArrowLeftOutlined,

  LoadingOutlined,
  RestOutlined,
  TagOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";
import { useState } from "react";
import SunEditor from "suneditor-react";
import {
  Popconfirm,
  Select,
  Table,
  TabsProps,
  Tooltip,
  TreeSelect,
} from "antd";
import BooksService from "../../Services/Books";
import "../../pages/Dashboardtablecomponent/DashboardMain.css";
// import "./DashboardMain.css";
import {
  addLog,
  addSprintChangeLog,
  addStatusChangeLog,
  formatDate,
  iconPriority,
  initialsgenerator,
  priorityconvertor,
  selectvalue,
  statusbackgroundgenerator,
  statuscolorfont,
  statusconvertor,
  statusvalueconvertor,
  textnumbergenerator,
} from "../../utils/Util";
import { formatDatedatetimeyear } from "../../utils/DateConvertor";
import HTTPSCalls from "../../Services/HTTPCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageListType, ImageType } from "react-images-uploading";
import { Input, Space, Spin, Typography, Button, Avatar, Tabs } from "antd";
import {
  DownloadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../../../src/common/common.css";
import { List } from "antd";
import { useSelector } from "react-redux";
import { loading, userData } from "../../store/slices/user-slice";
import TabPane from "antd/es/tabs/TabPane";
import NoData from "../../pages/NoData";
import { ModalTemplate } from "../../pages/Dashboardtablecomponent/Modal/Modal";
import ImageUpload from "../../pages/Dashboardtablecomponent/ImageUpload/ImageUpload";
import TaskModel, {
  CreateType,
} from "../../pages/Dashboardtablecomponent/Modal/TaskModel";
import FilePreview from "../../pages/Dashboardtablecomponent/FIlePreview";
import { useDispatch } from "react-redux";
import {

  refreshActive,
  refreshBacklog,
  refreshBoard,
  taskDrawerRefreshState,
} from "../../store/slices/refresh-slice";
import Addwatching from "./Addwatching";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
enum LogType {
  All,
  Comment,
  History,
  Child,
  Attachment,
}
const labelColSpan = 4;
const valueColSpan = 19;
const offSet = 1;
const SidebarDrawerbacklog = ({ open, setOpen, selectid }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const urlArray = location.pathname.split("/");
  const UrlParam = selectid; //urlArray[urlArray.length - 1];
  const tashDrawerRefresh = useSelector(taskDrawerRefreshState);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const openModal = () => setIsOpen(true);
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const user = useSelector(userData);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDisabledissue, setIsDisabledissue] = useState(true);
  const [isDisabledassignee, setIsDisabledassignee] = useState(true);
  //options states
  const [sprintOptions, setSprintOptions] = useState<any[]>([]);
  // const [sprintName, setSprintName] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState<any[]>([]);
  const [assigneeName, setAssigneeName] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [sprintIds, setsprintIds] = useState([]);
  const [issueTypeOptions, setIssueTypeOptions] = useState<any[]>([]);
  const [issueTypeName, setIssueTypeName] = useState([]);
  const [originalValue, setOriginalValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // Data States
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [updatedTaskDetail, setUpdatedTaskDetail] = useState<any>({
    title: "",
  });
  const [detailstate, setDetailstate] = useState<any>({});
  const [taskId, setTaskId] = useState<string>("");
  const [uKey, setUKey] = useState<string>("");
  // const [form, setForm] = useState<string>("");
  // const [to, setTo] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [commentData, setCommentData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [htmlHistory, setHtmlHistory] = useState<any>([]);
  const [docList, setDocList] = useState<ImageListType>([]);
  const [taskAttachment, setTaskAttachment] = useState([]);
  const [currentDocToPreview, setCurrentDocToPreview] = useState<any>(null);
  const [reporterOpt, setReporterOpt] = useState<any>([]);

  //Togglers
  const [isComment, setIsComment] = useState<LogType>(LogType.Comment);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isCommentEditor, setIsCommentEditor] = useState<boolean>(false);
  const [isDescriptionEditor, setIsDescriptionEditor] =
    useState<boolean>(false);
  const [isDocumentUpload, setIsDocumentUpload] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  //Loader States
  // const [globalLoading, setGlobalLoading] = useState<boolean>(false);
  const [activityLoading, setactivityloading] = useState<boolean>(false);
  // const [apicallLoader, setApicallLoader] = useState<boolean>(false);
  const [isChildLoading, setIsChildLoading] = useState<boolean>(false);
  const [isDocsLoading, setIsDocsLoading] = useState<boolean>(false);
  // const [isloading, setisloading] = useState(false);
  const [isCommentIconLoader, setIsCommentIconLoader] = useState(false);
  const [createChild, setCreateChild] = useState<boolean>(false);
  const [branchData, setBranchData] = useState<any>([]);
  // const [selectChildIssue, setselectChildIssue] = useState(false);
  const [childTask, setChildTask] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [latestassigneechange, setlatestassigneechange] = useState<any>(null);
  // const [cardWidth, setCardWidth] = useState(window.innerWidth);
  // dayjs.extend(customParseFormat);
  //SearchFilter
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };

  function LogRender(data: any, logtype: any) {
    const renderComment = () => (
      <div style={{ marginBottom: 5, fontSize: 14, fontFamily: "Open Sans" }}>
        <List.Item>
          <List.Item.Meta
            style={{ display: "flex" }}
            avatar={
              <Avatar
                style={{ marginRight: 20 }}
                icon={<UserOutlined />}
                size={35}
                src={
                  data.thumb === null ||
                  data.thumb === "" ||
                  data.thumb === undefined
                    ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                        data.assigneeName
                      )}-${textnumbergenerator(data.assigneeName)}.png?ssl=1`
                    : data.thumb
                }
              />
            }
            description={
              <>
                <Typography.Text strong className="mr-1">
                  {data?.assigneeName}
                </Typography.Text>
                <Typography.Text>
                  {formatDatedatetimeyear(data?.cDate)}
                </Typography.Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.remarks.replace("p>", "span>"),
                  }}
                />
              </>
            }
          />
        </List.Item>
      </div>
    );

    const renderHistory = () => (
      <div
        style={{
          margin: 8,
          marginBottom: 20,
        }}
      >
        <>
          <p
            style={{
              fontSize: 14,
              fontFamily: "Open Sans",
              marginBottom: 1,
              color: "#091e42",
            }}
          >
            {<>{data.remarks}</>}
          </p>
          <p
            style={{
              fontSize: "12px",
              color: "#8b99a7",
              margin: 0,
              marginBottom: 3,
            }}
          >
            {formatDatedatetimeyear(data?.cDate)}
          </p>
          <p
            style={{
              fontSize: 14,
              color: "#6c798e",
              margin: 0,
            }}
          >
            {(data.cngType === 3 || data.cngType === 2) && (
              <>
                {" "}
                <span
                  style={{
                    backgroundColor: `${statusbackgroundgenerator(data?.from)}`,
                    color: `${statuscolorfont(data.from)}`,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 2,
                    fontSize: 12,
                  }}
                >
                  {data?.from ? data?.from : "None"}
                </span>{" "}
                {`->`}{" "}
                <span
                  style={{
                    backgroundColor: `${statusbackgroundgenerator(data?.to)}`,
                    color: `${statuscolorfont(data?.to)}`,
                    paddingTop: 1,
                    paddingBottom: 1,
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 2,
                    fontSize: 12,
                  }}
                >
                  {data.to ? data.to : "None"}
                </span>
              </>
            )}
          </p>
        </>
      </div>
    );

    const renderAll = () => (
      <Row className="m-2 ">
        <Col span={24}>
          <div>
            {data.from === "" && data.to === "" ? (
              <div
                style={{
                  marginBottom: "-5px",
                  fontSize: 14,
                  fontFamily: "Open Sans",
                }}
              >
                <List.Item>
                  <List.Item.Meta
                    style={{ display: "flex" }}
                    description={
                      <>
                        <Typography.Text strong className="mr-1">
                          {data.assigneeName}
                        </Typography.Text>
                        <Typography.Text
                          style={{
                            fontSize: "14px",
                            color: "#8b99a7",
                            margin: 0,
                            marginBottom: 3,
                          }}
                        >
                          {"-"}
                          {formatDatedatetimeyear(data.cDate)}
                        </Typography.Text>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.remarks.replace("p>", "span>"),
                          }}
                        />
                      </>
                    }
                  />
                </List.Item>
              </div>
            ) : (
              <>
                <p
                  style={{
                    fontSize: 14,
                    fontFamily: "Open Sans",
                    marginBottom: 1,
                    color: "#091e42",
                  }}
                >
                  {<>{data.remarks}</>}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#8b99a7",
                    margin: 0,
                    marginBottom: 3,
                  }}
                >
                  {formatDatedatetimeyear(data.cDate)}
                </p>
                <p
                  style={{
                    fontSize: 14,
                    color: "#6c798e",
                    margin: 0,
                  }}
                >
                  {(data.cngType === 3 || data.cngType === 2) && (
                    <>
                      {" "}
                      <span
                        style={{
                          backgroundColor: `${statusbackgroundgenerator(
                            data.from
                          )}`,
                          color: `${statuscolorfont(data.from)}`,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 5,
                          paddingRight: 5,
                          borderRadius: 2,
                          fontSize: 12,
                        }}
                      >
                        {data.from ? data.from : "None"}
                      </span>{" "}
                      {`->`}{" "}
                      <span
                        style={{
                          backgroundColor: `${statusbackgroundgenerator(
                            data.to
                          )}`,
                          color: `${statuscolorfont(data.to)}`,
                          paddingTop: 1,
                          paddingBottom: 1,
                          paddingLeft: 5,
                          paddingRight: 5,
                          borderRadius: 2,
                          fontSize: 12,
                        }}
                      >
                        {data.to ? data.to : "None"}
                      </span>
                    </>
                  )}
                </p>
              </>
            )}
          </div>
        </Col>
      </Row>
    );
    switch (logtype) {
      case LogType.Comment:
        return renderComment();
      case LogType.History:
        return renderHistory();
      case LogType.All:
        return renderAll();
      default:
        return null;
    }
  }

  function getObjectChanges(oldObj: any, newObj: any) {
    const changes: any = {};
    for (const key in oldObj) {
      if (!newObj.hasOwnProperty(key)) {
        changes[key] = { from: oldObj[key], to: undefined };
      }
    }

    // Check for properties in the new object that are not present in the old object
    for (const key in newObj) {
      if (!oldObj.hasOwnProperty(key)) {
        changes[key] = { from: undefined, to: newObj[key] };
      }
    }

    // Check for properties that exist in both objects and have different values
    for (const key in newObj) {
      if (oldObj.hasOwnProperty(key) && newObj[key] !== oldObj[key]) {
        changes[key] = { from: oldObj[key], to: newObj[key] };
      }
    }

    return changes;
  }

  function LogConvertor(value: any, key: string) {
    // console.log(value, "value");

    if (key === "title" || key === "description") return value ? value : "none";
    else if (key === "tStatus")
      return value !== null && value !== undefined
        ? statusconvertor(value)
        : "backlog";
    else if (key === "dueDate") return value ? formatDate(value) : "none";
    else if (key === "priority")
      return value !== null && value !== undefined ? priorityconvertor(value) : "none";
    else if (key === "sprintIds") {
      if (value && value?.length > 0) {
        const sprint = sprintOptions?.find((s: any) => s?.value === value[0]);
        return sprint?.label || "Backlog";
      }
      return "Backlog";
    }
    else if (
      key === "label" ||
      key === "assignee" ||
      key === "branch" ||
      key === "reporter" ||
      key === "categoryIds"
    )
      return value ? value : "none";
    return value.map((obj: any) => obj.label).join(", ");
  }
  // useEffect(() => {
  //   const handleResize = () => {
  //     setCardWidth(window.innerWidth);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const TabRender = (item: any) => {
    if (item.shouldRender) {
      return (
        <div
          onClick={() =>
            item?.filtervalue !== null && setIsComment(item?.filtervalue)
          }
        >
          {item?.label}
        </div>
      );
    }
    return null;
  };
  // Tabel Data
  const columns = [
    {
      dataIndex: "attachment",
      render: (_: any, record: { image: any }) => (
        <div>
          {record.image && (
            <div onClick={() => {}} style={{ paddingLeft: "10px" }}>
              {record.image.contentType === "video/webm"
                ? imagePath(record.image.name)
                : imagePath(record.image.name)}
            </div>
          )}
        </div>
      ),
    },
    {
      dataIndex: "name",
      render: (name: string | number | boolean | React.ReactElement) => (
        <p style={{ fontWeight: 400, margin: 0 }}>{name}</p>
      ),
    },
    {
      dataIndex: "actions",
      align: "right" as "right",
      render: (_: any, record: { image: any }) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <Button
            type="link"
            onClick={() => {
              setCurrentDocToPreview(record?.image);
              setShowPreview(true);
            }}
          >
            <FullscreenOutlined
              style={{ fontSize: "17px", color: "#8c8c8c" }}
            />
          </Button> */}

          <Button
            type="link"
            onClick={() => {
              const link = document.createElement("a");
              link.href = record?.image?.downloadURL;
              link.download = "filename";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            <DownloadOutlined style={{ fontSize: "17px", color: "#8c8c8c" }} />
          </Button>
          <Popconfirm
            title="Are you sure to delete this attachment?"
            onConfirm={() => DeleteAttachment(taskId, record.image.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <RestOutlined style={{ fontSize: "17px", color: "#8c8c8c" }} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  //Antd All
  const item = [
    {
      key: LogType.All,
      label: "All",
      shouldRender: true,
      filtervalue: LogType.All,
      children: (
        <Row>
          <Col
            className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
            style={{
              maxHeight: "65vh",
              marginTop: 10,
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {htmlHistory.length === 0 ? (
              <Row
                style={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                align="middle"
              >
                <img
                  style={{ margin: "0px 37px" }}
                  src={process.env.PUBLIC_URL + "/noTask.svg"}
                  alt="No Task"
                />
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontFamily: "Open Sans",
                      margin: 0,
                      fontSize: 16,
                      color: "rgb(72, 82, 110)",
                    }}
                  >
                    No Data yet
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    You have no available Data to display
                  </Typography.Text>
                </Col>
              </Row>
            ) : (
              <Row>
                {activityLoading ? (
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15%",
                    }}
                  >
                    {/* <Spin size="small" /> */}
                  </Col>
                ) : (
                  htmlHistory.map((value: any, index: any) => {
                    //  console.log('LL',htmlHistory);
                    return (
                      <Col span={24} key={index}>
                        {LogRender(value, LogType.All)}
                      </Col>
                    );
                  })
                )}
              </Row>
            )}
          </Col>
        </Row>
      ),
    },

    // Comment Ant
    {
      key: LogType.Comment,
      label: "Comment",
      shouldRender: true,
      filtervalue: LogType.Comment,
      children: (
        <Row>
          <Col
            className=""
            style={{
              maxHeight: "65vh",
              marginTop: 10,
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {commentData.length === 0 ? (
              <Row
                style={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                className="ml-16 pl-16 mt-16 "
              >
                <img
                  style={{ margin: "0px 37px" }}
                  src={process.env.PUBLIC_URL + "/Comment.svg"}
                  alt="No Task"
                />
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: 16,
                      color: "rgb(72, 82, 110)",
                    }}
                  >
                    No Comment is available
                  </Typography.Title>
                </Col>
              </Row>
            ) : (
              <Row>
                {activityLoading ? (
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15%",
                    }}
                  >
                    <Spin size="small" />
                  </Col>
                ) : (
                  commentData.map((value, index) => {
                    //  console.log('LL',commentData);

                    return (
                      <Col span={24} key={index}>
                        {LogRender(value, LogType.Comment)}
                      </Col>
                    );
                  })
                )}
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
    // History Ant
    {
      key: LogType.History,
      label: "History",
      shouldRender: true,
      filtervalue: LogType.History,
      children: (
        <Row>
          <Col
            className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
            style={{
              maxHeight: "70vh",
              marginTop: 10,
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {historyData.length === 0 ? (
              <Row
                style={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                align="middle"
              >
                <img
                  style={{ margin: "0px 37px" }}
                  src={process.env.PUBLIC_URL + "/noTask.svg"}
                  alt="No Task"
                />
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontFamily: "Open Sans",
                      margin: 0,
                      fontSize: 16,
                      color: "rgb(72, 82, 110)",
                    }}
                  >
                    No Data yet
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    You have no available Data to display
                  </Typography.Text>
                </Col>
              </Row>
            ) : (
              <Row>
                {activityLoading ? (
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15%",
                    }}
                  >
                    {/* <Spin size="small" /> */}
                  </Col>
                ) : (
                  historyData.map((value, index) => (
                    <Col span={24} key={index}>
                      {LogRender(value, LogType.History)}
                    </Col>
                  ))
                )}
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
  ];

  const showError = (message: any) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };

  const GetTaskDetailByUkey = async () => {
    setIsLoading(true);
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.task.getbyukey + "/" + UrlParam
    );

    if (response.result) {
      let initialValue = {
        createdBy: response?.result?.createdBy?.date,
        updatedBy: response?.result?.updatedBy?.date,
        id: response?.result?.id,
        title: response?.result?.title,
        description: response?.result?.description,
        dueDate: response?.result?.dueDate,
        // priority: {
        //   label: priorityconvertor(response?.result?.priority),
        //   value: response?.result?.priority,
        // },
        sprintIds: response?.result?.sprint?.map((d: any) => d.id),
        priority: response?.result?.priority,
        tStatus: response?.result?.tStatus,
        // sprint: response?.result?.sprint?.map((item: any) => ({
        //   label: item?.name,
        //   value: item?.id,
        // })),
        project: response?.result?.project?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        })),
        // file: response?.result?.file?.map((item: any) => item.id),
        file: response?.result?.file?.map((item: any) => ({
          id: item?.id,
          name: item?.name,
          contentType: item?.contentType,
          length: item?.length,
          path: item?.path,
          existingType: item?.existingType,
          downloadURL: item?.downloadURL,
        })),
        assignee: response?.result?.assignee?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          ...item,
        })),
        assignedBy: response?.result?.assignedBy?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          ...item,
        })),
        label: response?.result?.label?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        })),
        branch: response?.result?.branch?.map((item: any) => item?.id),
        reporter: response?.result?.reporter?.map((item: any) => item?.id),
        categoryIds: response?.result?.category?.map((c: any) => c.id),
      };
      setTaskId(response?.result?.id);
      setUKey(response.result.uKey);
      setTaskDetail(initialValue);
      setAssigneeName(initialValue?.assignee);
      setCategoryIds(initialValue?.categoryIds);
      setsprintIds(initialValue?.sprintIds);
      setUpdatedTaskDetail(initialValue);
      setDetailstate(initialValue);
      setIsEditable(false);
      Promise.all([
        GetSprintOptions(response.result.project[0].id),
        GetAssigneeOptions(response.result.project[0].id),
        GetLogs(response.result.id),
        getBatchData(),
        getChildTask(response?.result?.id),
        getTaskAttachment(response.result.id),
        // setLabelCategoryOptions(response.result.id),
        GetIssueType(),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  };
  // get all users list
  const getAllUsers = async () => {
    await BooksService.getUser(2000).then((res: any) => {
      setReporterOpt(
        res?.result?.map((item: any) => ({ label: item.name, value: item.id }))
      );
    });
  };
  const getBatchData = () => {
    BooksService.getBranch(1500).then((res: any) => {
      let branchArr: any = [];
      res.result.map((item: any) => {
        branchArr.push({ label: item.name, value: item.id, ...item });
      });
      setBranchData(branchArr);
    });
  };

  const GetSprintOptions = async (projectid: string) => {
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.sprint.getAll + "?",
      { start: 0, length: 100, projectid }
    );

    setSprintOptions(
      response.result.map((arrayItem: any) => ({
        label: arrayItem.name,
        value: arrayItem.id,
      }))
    );
    // setSprintName(response.result.map((item: any) => item.name));
  };

  const GetAssigneeOptions = async (projectid: string) => {
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.user.getByProject + "/" + projectid,
      {}
    );
    setAssigneeOptions(
      response.result.map((Item: any) => ({ label: Item.name, value: Item.id }))
    );
  };
  const GetIssueType = async () => {
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.issueType.getAll + "?",
      { start: 0, length: 100 }
    );
    if (response.result.length > 0)
      setIssueTypeOptions(
        response.result.map((arrayItem: any) => ({
          label: arrayItem.name,
          value: arrayItem.id,
        }))
      );
    setIssueTypeName(response.result.map((item: any) => item.name));
  };

  // Log APi
  const GetLogs = async (taskid: string = "") => {
    // console.log("Taskkkk =",taskid);
    
    try {
      let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.log.get + "?", {
        start: 0,
        length: 1500,
        tid: taskid === "" ? taskId : taskid,
        iscomment: isComment,
      });
      setHtmlHistory(response?.result);
      if (response?.result?.length > 0) {
        switch (isComment) {
          case LogType.Comment:
            return setCommentData(response?.result);
          case LogType.History:
            return setHistoryData(response?.result);
          case LogType.Child:
            return setHistoryData(response?.result);
          case LogType.Attachment:
            return setHistoryData(response?.result);
        }
      } else if (response?.errors) {
        // showAlert(response.message, AlertType.ERROR)
      }
    } catch (err) {
      // showAlert(err, AlertType.ERROR)
    }
  };
  const getChildTask = async (taskid: string = "", uKey: string = "") => {
    setIsChildLoading(true);
    try {
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.task.getAll + "/" + taskid + uKey + "?",

        { childTask: 1 }
      );
      if (response.status) setChildTask(response.result);
      // console.log("Abhi", response.result);
    } catch (err) {}
    setIsChildLoading(false);
  };

  const getTaskAttachment = async (taskid: string = "") => {
    // setIsLoading(true);
    try {
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.task.getAttachments + "/" + taskid
      );
      //  console.log("ashish", response.result);

      if (response.result) setTaskAttachment(response.result);
    } catch (err) {}
    // setIsLoading(false);
  };
  const showNotification = (type: "success" | "error", message: any) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
      duration: 3,
    });
  };

  const AddLog = async () => {

    let logObject: any = {};
    let requestBody: {
      taskId: string;
      user: { id: string; name: string };
      project: { id: string; name: string };
      logs: Array<any>;
    } = {
      taskId: taskId,
      user: { id: user?.id, name: user?.name },
      project: {
        id: updatedTaskDetail?.project?.[0].value,
        name: updatedTaskDetail?.project?.[0].label,
      },
      logs: [],
      
    };
    Object.assign(logObject, getObjectChanges(taskDetail, updatedTaskDetail));
    requestBody.logs = Object.keys(logObject).map((key: any) => {
      let logTemplate = {
        tId: taskId,
        pId: updatedTaskDetail?.project?.[0].value,
        uKey: uKey,
        assigneeId: user?.id,
        assigneeName: user?.name,
        remarks: "",
        from: "",
        isComment: 2,
        to: "",
        cngType: 3,
      };
      logTemplate.from = LogConvertor(logObject[key].from, key);
      logTemplate.to = LogConvertor(logObject[key].to, key);
      logTemplate.remarks = `${user?.name} updated  ${key}`;
      // console.log(logTemplate, "logTemplate");

      return logTemplate;
      
    });
    return await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add, requestBody);
  };
   
  const AddComment = async () => {
    let commentTemplate = {
      taskId: taskId,
      user: {
        id: user?.id,
        name: user?.name,
      },
      project: {
        id: user?.id,
        name: user?.id,
      },
      logs: [
        {
          tId: taskId,
          PId: updatedTaskDetail?.project?.[0].value,
          uKey: uKey,
          assigneeId: user?.id,
          assigneeName: user?.name,
          email: user?.email,
          remarks: comment,
          from: "",
          isComment: 1,
          to: "",
          cngType: 4,
         company: "63a959074e041fdc2ae91ce8",
        },
      ],
    };
    setIsCommentIconLoader(true);
    let response = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.log.add,
      commentTemplate
    );
    setComment("");
    if (response.result) {
      notification.success({
        message: "Success",
        description: "Description updated successfully",
      });
    } else if (response.errors) {
      notification.error({
        message: "Error",
        description: response?.message,
      });
    }
    setIsCommentIconLoader(false);
    setIsCommentEditor(false);
    GetLogs();
  };
  const AddAttachment = async (taskId: string = "") => {
    setAttachmentLoading(true);
  
    // Check if docList is null or empty
    if (!docList || docList.length === 0) {
      setAttachmentLoading(false);
      notification.error({
        message: "Upload Error",
        description: "No document selected for upload.",
        placement: "bottomLeft",
      });
      return; // Exit the function early
    }
  
    try {
      const response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.addFile + `/${taskId}/UploadFile`,
        docList.map((item: ImageType) => ({
          name: item?.file?.name,
          contentType: item?.file?.type,
          existingType: 1,
          path: item?.data_url,
        }))
      );
  
      if (response?.result?.length !== 0) {
        // Update attachment list or perform any other actions
        getTaskAttachment(taskId);
        notification.success({
          message: "Success",
          description: "Attachment uploaded successfully!",
          placement: "bottomLeft",
        });
      }
  
      setIsDocumentUpload(false);
    } catch (error: any) {
      // Error notification
      notification.error({
        message: "Excel Import Error",
        description: `An error occurred while importing the Excel file: ${error.message}`,
        placement: "bottomLeft",
      });
    } finally {
      setAttachmentLoading(false);
    }
  };
  

  const DeleteAttachment = async (taskid: string, fileid: string) => {
    let response = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.task.attachment + "?",
      {},
      { tid: taskid, fileid: fileid }
    );
    if (response.result) {
      notification.success({
        message: "Success",
        description: "Attachment Delete successfully!",
        placement: "bottomLeft",
      });
      getTaskAttachment(taskId);
    }
  };

  function imagePath(imageName: string) {
    const extensionMap: { [key: string]: string } = {
      pdf: "pdf.svg",
      png: "png.svg",
      doc: "doc.svg",
      xlxs: "xlxs.svg",
      xls: "xls.svg",
      jpg: "jpg.svg",
    };

    const extension = imageName.split(".").pop()?.toLowerCase();
    const imagePath =
      extension && extensionMap[extension]
        ? extensionMap[extension]
        : "other.svg";

    return (
      <img
        style={{ paddingTop: "2px", height: "20px" }}
        src={process.env.PUBLIC_URL + "/" + imagePath}
        alt=""
      />
    );
  }
  function substringmethod(data: string) {
    if (data?.length > 14) {
      return data.substring(0, 14) + "...";
    } else {
      return data;
    }
  }

  // const [errassignee, seterrassignee] = useState(false)
  // const columns = createHead(true);
  useEffect(() => {
    GetTaskDetailByUkey();
  }, [location.pathname, UrlParam, tashDrawerRefresh]);

  useEffect(() => {
    taskId !== "" && GetLogs();
  }, [isComment, location.pathname, UrlParam]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const [activeKey, setActiveKey] = useState<string>("1");
  //  Overview Child Tasks Files Activity code

  const [changeLabelCategory, setChangeLabelCategory] = useState(0);
  const [labelCategoryOptions, setLabelCategoryOptions] = useState<any>();

  useEffect(() => {
    BooksService.getLabelCategory().then((data: any) => {
      const labelCategories = data.result.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setLabelCategoryOptions(labelCategories);
    });
  }, [changeLabelCategory]);
  // Api AddTask
  const addTask = async () => {
    try {
      let requestObject = { ...updatedTaskDetail };
      //  console.log(updatedTaskDetail, "veeru");

      // Create an object to keep track of changes
      const changes: { [key: string]: string } = {};

      // Compare fields and record changes
      Object.keys(updatedTaskDetail).forEach((key: any) => {
        if (updatedTaskDetail[key] !== originalValue[key]) {
          changes[key] = `${
            key.charAt(0).toUpperCase() + key.slice(1)
          } updated successfully`;
        }
      });

      if (Object.keys(changes).length > 0) {
        const response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.task.addFile,
          {
            id: requestObject?.id,
            title: requestObject?.title,
            description: requestObject?.description,
            sprintIds: requestObject?.sprintIds || [""],
            dueDate: requestObject?.dueDate,
            priority: requestObject?.priority,
            tStatus: requestObject?.tStatus,
            watchingIds: [],
            categoryIds: requestObject?.categoryIds || [null],
            assigneedBy: requestObject?.assigneedBy || [],
            file: requestObject?.file || [],
            projectIds: requestObject?.project?.map((item: any) => item.value),
            assignedByIds: [user?.id],
            assigneeIds: requestObject?.assignee?.map(
              (item: any) => item?.value
            ),
            labelIds: requestObject.label.map((item: any) => item?.value),
            branch: requestObject?.branch?.map((item: any) => ({
              id: item,
              name: branchData?.find((b: any) => b.value === item)?.label,
            })),
            reportersIds: requestObject?.reporter,
            company: "63a959074e041fdc2ae91ce8",
          }
        );

        if (response?.result) {
          Object.values(changes).forEach((message) =>
            showNotification("success", message)
          );

          AddLog();
          GetTaskDetailByUkey();
          dispatch(refreshBoard());
          dispatch(refreshBacklog());
          
        } else if (response.errors) {
          showNotification("error", response.message);
        }
      }
    } catch (error) {
      console.error(error);
      showNotification("error", "Failed to add task");
      
    }
  };

  // const handleButtonClick = async (e: any) => {
  //   // Perform some action when the button is clicked
  //   // console.log("Button clicked!");
  //   // UpdateTask();
  //   await addTask();
  //   setOriginalValue(updatedTaskDetail);
  //   setOriginalValue(e.target.value);
  //   setIsDisabledassignee(false);
  //   setIsDisabledissue(false);
  // };

  const handleChangestatus = async (data: any, childTaskId: string   ) => {
    try {
    setIsLoading(true);
      const res = await BooksService.updateTask({
        tid: childTaskId,
        tstatus: data,

      });
      // console.log(" reeeeeee=" , res);
      
      if (res?.status) {
        notification.success({
          message: "Success",
          description: "ChildTask status has been updated successfully.",
          placement: "bottomLeft",
        });
        let task: any = {
          ...res?.result,
          UKey: res?.result?.uKey,
          _id: res?.result?.id,
        };
        await getChildTask(taskId);
        //  await addStatusChangeLog(task, task?.childTaskId, task?.tstatus?.value ,3 );
        // await addLog(task, task?.childTaskId, task?.tstatus?.value,)
      } else {
        notification.error({
          message: "Error",
          description: "There was an error updating the ChildTask status.",
          placement: "bottomLeft",
        });
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: "There was an error updating the ChildTask status.",
        placement: "bottomLeft",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange = (selectValue: []) => {
    if (selectValue.length <= 1) {
      setUpdatedTaskDetail({
        ...updatedTaskDetail,
        categoryIds: selectValue,
      });
    } else {
      setUpdatedTaskDetail({
        ...updatedTaskDetail,
        categoryIds: [selectValue[selectValue.length - 1]],
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Overview",

      children: (
        <>
          {/* Status */}
          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan} className="items-center">
              Status
            </Col>
            <Col span={valueColSpan} offset={offSet}>
              <Select
                variant="borderless"
                // style={{ width: 150 }}
                className="borderLessInput w100"
                defaultValue={taskDetail?.tStatus}
                options={selectvalue}
                placeholder="Choose status"
                onBlur={() => {
                  addTask()
                }}
                value={updatedTaskDetail.tStatus}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(selectValue: any) => {
                  if (selectValue === 8) setIsDuplicate(true);
                  else
                    setUpdatedTaskDetail({
                      ...updatedTaskDetail,
                      tStatus: selectValue,
                    });
                }}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
              />
            </Col>
          </Row>
          <Row className="mb-4" align={"middle"}>
            <Col span={labelColSpan}>Title</Col>
            <Col span={valueColSpan} offset={offSet}>
              <Input
                variant="borderless"
                className="borderLessInput w100"
                value={updatedTaskDetail?.title}
                onBlur={() => {
                  if (updatedTaskDetail?.title?.length>= 3) {
                    addTask()
                  }else{
                    notification.error({
                      message: 'Error',
                      description: "Title cannot exceed 3 characters!",
                    placement:'bottomLeft'
                  })
                }}}
               
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(e) => {
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    title: e.target.value,
                  });
                }}
                required
              />
            </Col>
          </Row>

          {/* Reporter */}
          <Row className="mb-5" align={"middle"}>
            <Col span={labelColSpan}>Reporter</Col>
            <Col span={valueColSpan} offset={offSet}>
              <Select
                variant="borderless"
                className="borderLessInput w100"
                placeholder="Choose reporter"
                defaultValue={updatedTaskDetail?.reporter}
                options={reporterOpt}
                onBlur={() => {
                  addTask();
                }}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(value) => {
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    reporter: [value],
                  });
                }}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
              />
            </Col>
          </Row>

          {/* Sprint  */}
          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Sprint</Col>
            <Col span={valueColSpan} offset={offSet}>
              <Select
                variant="borderless"
                className="borderLessInput w100"
                // defaultValue={taskDetail?.sprint}
                value={updatedTaskDetail?.sprintIds}
                // spacing="compact"
                placeholder="Choose sprint"
                options={sprintOptions}
                onBlur={() => {
                  addTask();
                }}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(selectValue: any) => {
                  // console.log("change");
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    sprintIds: [selectValue],
                  });
                }}
                showSearch
                onSearch={onSearch}
                optionFilterProp="label"
              />
            </Col>
          </Row>
          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Priority</Col>
            <Col span={valueColSpan} offset={offSet}>
              <Select
                variant="borderless"
                className="borderLessInput w100"
                options={[
                  { label: "High", value: 2 },
                  { label: "Medium", value: 1 },
                  { label: "Low", value: 0 },
                ]}
                // defaultValue={taskDetail?.priority}
              
                value={updatedTaskDetail?.priority}
                onBlur={() => addTask()}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(selectValue) => {
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    priority: selectValue,
                  });
                }}
              />
            </Col>
          </Row>
          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Label</Col>
            <Col span={valueColSpan} offset={offSet}>
              <Select
                variant="borderless"
                mode="tags"
                placeholder="Select Label"
                className="borderLessInput w100"
                options={labelCategoryOptions}
                value={updatedTaskDetail.categoryIds}
                onBlur={() => addTask()}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(selectValue) => {
                  handleChange(selectValue);
                }}
                allowClear
              />
            </Col>
          </Row>
          {/* Priority ,Label , Due Date  */}
          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Due Date</Col>
            <Col span={valueColSpan} offset={offSet} className="borderLessInput " >
              <DatePicker
                //  defaultValue={updatedTaskDetail?.dueDate}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                className="borderLessInput w100"
                dateFormat="dd MMM yyyy"
                //  showIcon
                toggleCalendarOnIconClick
                onBlur={addTask}
                selected={updatedTaskDetail?.dueDate}
                onChange={(dueDate: any) => {
                  // console.log("dueDate", dueDate);
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    dueDate: dueDate,
                  });
                }}
              />
            </Col>
          </Row>

          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Branch</Col>
            <Col span={valueColSpan} offset={offSet}>
              <TreeSelect
                variant="borderless"
                className="borderLessInput w100"
                value={updatedTaskDetail.branch}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={branchData}
                placeholder="Choose branches"
                maxTagCount={1}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                onBlur={() => {
                  addTask();
                }}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(selectValue: any) => {
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    branch: selectValue,
                  });
                }}
                showSearch
                onSearch={onSearch}
                treeNodeFilterProp="label"
              />
            </Col>
          </Row>
          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Assignee</Col>
            <Col span={valueColSpan} offset={offSet}>
              <TreeSelect
                variant="borderless"
                className="borderLessInput w100"
                maxTagCount={1}
                treeData={assigneeOptions}
                placeholder="Choose assignee"
                treeCheckable={true}
                value={updatedTaskDetail?.assignee}
                defaultValue={
                  taskDetail?.assignee ? [...taskDetail?.assignee] : []
                }
                // onBlur={UpdateTask}
                onBlur={() => {
                  addTask();
                }}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(value) => {
                  const selectValue = value?.map((item: any) => {
                    const found = assigneeOptions.find(
                      (s: any) => s.value === item
                    );
                    return {
                      label: found.label,
                      value: found.value,
                    };
                  });
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    assignee: selectValue,
                  });
                  setIsDisabledassignee(false);
                }}
                showSearch
                onSearch={onSearch}
                treeNodeFilterProp="label"
              />
            </Col>
          </Row>

          <Row align={"middle"} className="mb-5">
            <Col span={labelColSpan}>Issue Type</Col>
            <Col span={valueColSpan} offset={offSet}>
              <TreeSelect
                variant="borderless"
                className="borderLessInput w100"
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                placeholder="Choose Issue Type"
                value={updatedTaskDetail.label}
                defaultValue={taskDetail?.label ? [...taskDetail?.label] : []}
                treeData={issueTypeOptions}
                // onBlur={UpdateTask}
                onBlur={() => {
                  addTask();
                }}
                onFocus={() => setOriginalValue(updatedTaskDetail)}
                onChange={(value) => {
                  const labels = value?.map((item: any) => {
                    const foundItem = issueTypeOptions.find(
                      (i: any) => i.value === item
                    );
                    return {
                      label: foundItem.label,
                      value: foundItem.value,
                    };
                  });
                  setUpdatedTaskDetail({
                    ...updatedTaskDetail,
                    label: labels,
                  });
                  setIsDisabledissue(false);
                }}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                showSearch
                onSearch={onSearch}
                treeNodeFilterProp="label"
              />
            </Col>
          </Row>
          <Row align={"middle"} className="">
            <Col span={24}>Description</Col>
            <Col span={24}>
              {isDescriptionEditor ? (
                <div style={{ backgroundColor: "#ffffff" }}>
                  <SunEditor
                    setContents={
                      updatedTaskDetail?.description || "<p><br></p>"
                    }
                    // setContents={taskDetail?.description}
                    setOptions={{
                      buttonList: [
                        ["undo", "redo"],
                        ["font", "fontSize"],
                        ["bold", "underline", "italic", "strike"],
                        ["fontColor", "hiliteColor"],
                        ["align", "list", "lineHeight"],
                        ["outdent", "indent"],
                        ["print"],
                        ["removeFormat"],
                      ],
                      defaultTag: "div",
                      showPathLabel: false,
                    }}
                    height="180px"
                    width="100%"
                    onChange={(selectValue: any) => {
                      if (selectValue) {
                        setUpdatedTaskDetail({
                          ...updatedTaskDetail,
                          description: selectValue,
                        });
                      } else {
                        setUpdatedTaskDetail({
                          ...updatedTaskDetail,
                          description: "<p><br></p>",
                        });
                      }
                    }}
                    onFocus={() => setOriginalValue(updatedTaskDetail)}
                  />
                  <Space direction="horizontal" size="middle" className="mt-2">
                    <Button
                      type="primary"
                      onClick={() => {
                        addTask();
                        setIsDescriptionEditor(false);
                      }}
                      // loading={!isDescriptionEditor}
                    >
                      Save
                    </Button>
                    <Button
                      type="text"
                      onClick={() => setIsDescriptionEditor(false)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              ) : (
                <div
                  className="borderLessInput"
                  onClick={() => setIsDescriptionEditor(true)}
                  style={{
                    padding: 10,
                    minHeight: "100px",
                    maxHeight: "calc(100vh - 520px)",
                    overflowX: "scroll",
                    scrollbarWidth:"none"
                  }}
                  dangerouslySetInnerHTML={{
                    __html: taskDetail?.description,
                  }}
                ></div>
              )}
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Child Tasks",
      children: (
        <>
          {childTask.length === 0 ? (
            <Row
              style={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "15em",
              }}
              align="middle"
            >
              <img
                style={{ paddingTop: "2px" }}
                // src={process.env.PUBLIC_URL + "/noTask.svg"}
                src={process.env.PUBLIC_URL + "/Child-task.svg"}
                alt="No Task"
              />
              <Col>
                <Typography.Title
                  level={4}
                  style={{
                    fontFamily: "Open Sans",
                    margin: 0,
                    fontSize: 16,
                    color: "rgb(78, 92, 118)",
                  }}
                >
                  No child task is available
                </Typography.Title>
              </Col>
            </Row>
          ) : isChildLoading ? (
            <Row>
              <Col className="pt-10" span={12} offset={12}>
                <Spin size="small" />
              </Col>
            </Row>
          ) : (
            childTask?.map((item: any) => (
              <Row
                gutter={[0, 0]}
                key={item.id}
                justify={"space-between"}
                align={"middle"}
              >
                <Col span={1}>{iconPriority(item?.priority)}</Col>
                <Col span={4}>
                  <Typography.Text
                    className="fw500"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTaskAttachment([]);
                      setChildTask([]);
                      setHistoryData([]);
                      setCommentData([]);
                      navigate(`/task/${item?.uKey}`);
                    }}
                  >
                    {item?.uKey}
                  </Typography.Text>
                </Col>
                <Col span={6}>
                  <Tooltip
                    title={item?.title}
                    overlayStyle={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    <span>{substringmethod(item?.title)}</span>
                  </Tooltip>
                </Col>
                <Col span={5} className="Child-issues">
                  <Select
                   style={{width:120}}
                    variant="borderless"
                    options={selectvalue}
                    placeholder="Choose status"
                    defaultValue={[
                      {
                        label: statusconvertor(item?.tStatus),
                        value: statusvalueconvertor(item?.tStatus),
                      },
                    ]}
                    onChange={(e) => {
                      handleChangestatus(e, item?.id);
                    }}
                 
                 
                 
                    showSearch
                    onSearch={onSearch}
                    optionFilterProp="label"
                  />
                </Col>
                <Col span={3}>
                  {item?.assignee.length > 0 ? (
                    <Avatar.Group
                      //  size={55}
                      max={{
                        count: 2,
                        popover: {
                          trigger: "click",
                          placement: "bottom",
                        },
                      }}
                    >
                      {item?.assignee?.map((assignee: any, index: any) => (
                        <Tooltip
                          title={
                            <div>
                              <div>{assignee.name}</div>
                              <img
                                src={
                                  assignee?.thumb === null ||
                                  assignee?.thumb === "" ||
                                  assignee?.thumb === undefined
                                    ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                                        assignee?.name
                                      )}-${textnumbergenerator(
                                        assignee?.name
                                      )}.png?ssl=1`
                                    : assignee?.thumb
                                }
                                alt={assignee?.name}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "100px",
                                }}
                              />
                            </div>
                          }
                          key={index}
                        >
                          <Avatar
                            src={
                              assignee?.thumb === null ||
                              assignee?.thumb === "" ||
                              assignee?.thumb === undefined
                                ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                                    assignee?.name
                                  )}-${textnumbergenerator(
                                    assignee?.name
                                  )}.png?ssl=1`
                                : assignee?.thumb
                            }
                            size={28}
                          />
                        </Tooltip>
                      ))}
                    </Avatar.Group>
                  ) : (
                    <Avatar size={28} />
                  )}
                </Col>
              </Row>
            ))
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Files",
      children: (
        <>
          <Row justify="center">
            <Col span={24}>
              <div
                style={{
                  maxHeight: "65vh",
                  marginTop: 10,
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                {taskAttachment?.length === 0 ? (
                  <Row
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      textAlign: "center",
                      marginTop: "15em",
                    }}
                    align="middle"
                  >
                    <img
                      style={{ paddingTop: "2px" }}
                      src={process.env.PUBLIC_URL + "/Files.svg"}
                      alt="No Task"
                    />
                    <Col>
                      <Typography.Title
                        level={4}
                        style={{
                          fontFamily: "Open Sans",
                          margin: 0,
                          fontSize: 16,
                          color: "rgb(78, 92, 118)",
                        }}
                      >
                        No file is available
                      </Typography.Title>
                    </Col>
                  </Row>
                ) : (
                  <Spin spinning={isDocsLoading} size="small">
                    <Table
                      columns={columns}
                      dataSource={taskAttachment.map((image: any, index) => ({
                        key: `row-${index}-${image.id}`,
                        image,
                        name: image.name,
                      }))}
                      loading={taskAttachment.length === 0}
                      pagination={false}
                    />
                  </Spin>
                )}
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "4",
      label: "Activity",
      children: (
        <>
          <Row>
            <Col
              className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
              style={{
                maxHeight: "110vh",
                marginTop: 10,
                overflowY: "scroll",
                scrollbarWidth: "none",
              }}
            >
              <Row justify="space-between" align="middle" className="pb-8 pt-0">
                <Typography.Text>Comment</Typography.Text>
                <Col
                  span={24}
                  style={{
                    marginTop: 8,
                    border: "1px solid #f4f5f7",
                    borderRadius: "5px",
                  }}
                >
                  {isCommentEditor ? (
                    <Row>
                      <Col span={24}>
                        <SunEditor
                          setContents={comment || "<div><br></div>"}
                          onChange={(value) =>
                            value
                              ? setComment(value)
                              : setComment("<div><br></div>")
                          }
                        />
                      </Col>
                      <Col span={24}>
                        <Button
                          type="primary"
                          loading={isCommentIconLoader}
                          className=" mt-3 mb-2 ml-2 mr-2 "
                          onClick={() => {
                            if (comment === "") {
                              showError("You have to add a comment");
                            } else {
                              AddComment();
                            }
                          }}
                        >
                          Send
                        </Button>

                      
                          <Button
                            type="text"
                            className=" mt-3 mb-2  "
                            onClick={() => {
                              setIsCommentEditor(false);
                              setComment("");
                            }}
                          >
                            Cancel
                          </Button>
                      
                      </Col>
                      {errorMessage && (
                        <Col
                          span={24}
                          className="ant-col ant-col-24 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
                          style={{ padding: "10px", margin: "10px 0" }}
                        >
                          <div
                            className="ant-col-inner"
                            style={{ color: "red" }}
                          >
                            {errorMessage}
                          </div>
                        </Col>
                      )}
                    </Row>
                  ) : (
                    <Input.TextArea
                      style={{ backgroundColor: "#f3f4fa" }}
                      autoSize={{ minRows: 2 }}
                      placeholder="Add a comment"
                      onClick={() => setIsCommentEditor(true)}
                      className="Commentsun"
                    />
                  )}
                </Col>
              </Row>

              {commentData.length !== 1 ? (
                <Row>
                  <Tabs defaultActiveKey="1" style={{ width: "39em" }}>
                    {item.map((tab) => (
                      <TabPane tab={<TabRender {...tab} />} key={tab.key}>
                        {tab?.children}
                      </TabPane>
                    ))}
                  </Tabs>
                </Row>
              ) : (
                <Row>
                  {activityLoading ? (
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15%",
                      }}
                    >
                      {/* <Spin size="small" /> */}
                    </Col>
                  ) : (
                    commentData.map((value, index) => {
                      //  console.log('LL',commentData);

                      return (
                        <Tabs defaultActiveKey="1">
                          {item.map((tab) => (
                            <TabPane tab={<TabRender {...tab} />} key={tab.key}>
                              {tab.children}
                            </TabPane>
                          ))}
                        </Tabs>
                      );
                    })
                  )}
                </Row>
              )}
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <Row>
       
      <Drawer
        // extra={
        //   <CloseOutlined className="cursor" onClick={() => setOpen(false)} />
        // }
        closable
        destroyOnClose
        width={800}
        maskClosable={false}
        mask={false}
        placement="right"
        open={open}
        onClose={() => setOpen(false)}
        closeIcon={<ArrowLeftOutlined />}
        className="drawerlook"
        title={
          <Row align={"middle"}>
            <TagOutlined className="sidebarcss mr-3" />

            <Button
              type="link"
              // className="fw500 fs20"
              size="small"
              onClick={() => navigate("/task/" + UrlParam)}
            >
              {UrlParam}
            </Button>
            <Typography.Text
              className="mr-4 ml-4"
              style={{
                fontSize: "11px",
                color: "#8b99a7",
              }}
            >
              Created Date {formatDatedatetimeyear(detailstate?.createdBy)}
            </Typography.Text>

            <Typography.Text
              style={{
                // marginTop: "0px",
                // marginBottom: "0px",
                // marginLeft: "62px",

                fontSize: "11px",
                color: "#8b99a7",
              }}
            >
              Last updated {formatDatedatetimeyear(detailstate?.updatedBy)}
            </Typography.Text>
          </Row>
        }
      >
         <Spin spinning={isLoading}  indicator={<LoadingOutlined spin />} size="default" >
        <div style={{ padding: "4px 16px" }}>
          {/* <Loader loading={attachmentLoading} /> */}
          <>
            {Object.keys(taskDetail).length !== 0 ? (
              <Col>
                <Tabs
                  className="mt-3"
                  tabBarExtraContent={
                    <>
                      {activeKey === "2" && (
                        <>
                          <Button
                            size="small"
                            icon={
                              <PlusOutlined style={{ fontSize: "medium" }} />
                            }
                            onClick={() => {
                              setCreateChild(true);
                            }}
                            style={{
                              fontWeight: 500,
                              // padding: " 2px 12px",
                              background: "#f3f4fa",
                              borderColor: " #d3e3ff",
                              color: "#377dff",
                            }}
                          >
                            Child
                          </Button>
                        </>
                      )}
                      {activeKey === "3" && (
                        <>
                          <Button
                            size="small"
                            icon={
                              <PlusOutlined style={{ fontSize: "medium" }} />
                            }
                            onClick={(e) => {
                              setIsDocumentUpload(true);
                            }}
                            style={{
                              fontWeight: 500,
                              // padding: " 2px 12px",
                              background: "#f3f4fa",
                              borderColor: " #d3e3ff",
                              color: "#377dff",
                            }}
                          >
                            Attachment
                          </Button>
                        </>
                      )}
                    </>
                  }
                  size="small"
                  defaultActiveKey="1"
                  items={items}
                  onChange={(k: string) => {
                    setActiveKey(k);
                  }}
                />
              </Col>
            ) : (
              <Row justify="center" align="middle" style={{ height: "75vh" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Typography.Title level={1}>
                    <NoData message="No task found" />
                  </Typography.Title>
                </Col>
              </Row>
            )}
          </>
          <>
            {isDocumentUpload && (
              <ModalTemplate
                title="Upload document"
                closeModal={() => setIsDocumentUpload(false)}
                onSubmit={() => AddAttachment(taskId)}
                // callback={() => AddAttachment(taskId)}
              >
                <ImageUpload
                  value={docList}
                  onChange={(file: ImageListType) => setDocList(file)}
                />
              </ModalTemplate>
            )}
          </>
          {createChild && (
            <TaskModel
              configData={{ parantTaskId: taskId }}
              callback={() => getChildTask(taskId)}
              onDismiss={() => setCreateChild(false)}
              createType={CreateType.Child}
            />
          )}
          {showPreview && currentDocToPreview && (
            <FilePreview
              file={currentDocToPreview}
              onClose={() => setShowPreview(false)}
            />
          )}
        </div>
        </Spin>
      </Drawer>
      <Addwatching
        openModal={openModal}
        closeModal={closeModal}
        isOpen={isOpen}
        taskDetail={taskDetail}
        taskId={taskId}
      />
    
    </Row>
  );
};

export default SidebarDrawerbacklog;
