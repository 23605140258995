import { useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";
// import axios from "axios";

//For Projects Add & Edit
export const addEditProject = async (payload: any) => {
  return HTTPSCalls.POST(`${HTTPSCalls.ENDPOINTS.project.addEdit}`, payload);
};
export const useProject = () =>
  useMutation({
    mutationFn: async (payload) => {
      const result = await addEditProject(payload);
      return result;
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({ queryKey: ["projectList"] });
      }
    },
  });
//For User Add & Edit
export const addEditUser = async (payload: any) => {
  return HTTPSCalls.POST(`${HTTPSCalls.ENDPOINTS.user.create}`, payload);  
};
export const useUser = () =>
  useMutation({
    mutationFn: async (payload) => {
      const result = await addEditUser(payload);
      return result;
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({ queryKey: ["userList"] });
      }
    },
  });

// For Issue Add & Edit
export const addEditIssue = async (payload: any) => {
  return HTTPSCalls.POST(`${HTTPSCalls.ENDPOINTS.issueType.getAll}`, payload);
};
export const useIssue = () =>
  useMutation({
    mutationFn: async (payload) => {
      const result = await addEditIssue(payload);
      return result;
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({ queryKey: ["issueList"] });
      }
    },
  });

//Get Issue List
export const getAllIssueList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.issueType.getAll + payload);

export const useGetAllIssueList = (payload: any) => {
  return useQuery({
    queryKey: ["issueList"],
    queryFn: () => getAllIssueList(payload),
  });
};

// For Branch Add & Edit
export const addEditBranch = async (payload: any) => {
  return HTTPSCalls.POST(`${HTTPSCalls.ENDPOINTS.BranchName.getAll}`, payload);
};
export const useAddBranch = () =>
  useMutation({
    mutationFn: async (payload) => {
      const result = await addEditBranch(payload);
      return result;
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({ queryKey: ["branchList"] });
      }
    },
  });

//Get Branch List
export const getAllBranchList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.BranchName.getAll + payload);

export const useGetAllBranchList = (payload: any) => {
  return useQuery({
    queryKey: ["branchList"],
    queryFn: () => getAllBranchList(payload),
  });
};

// For Sprint Add & Edit
export const addEditSprint = async (payload: any) => {
  return HTTPSCalls.POST(`${HTTPSCalls.ENDPOINTS.sprint.sprintPost}`, payload);
};
export const useSprint = () =>
  useMutation({
    mutationFn: async (payload) => {
      const result = await addEditSprint(payload);
      return result;
    },
    onSuccess: (data: any) => {
      if (!data?.error) {
        queryClient.invalidateQueries({
          queryKey: ["sprintList", data?.projectId],
        });
      }
    },
    // onError:(r)=>{

    // }
  });

//Get Sprint List
export const getSprintList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.sprint.getAll, {
    length: payload?.length,
    projectId: payload?.projectId,
  });
export const useGetSprintList = (payload: any) => {
  return useQuery({
    queryKey: ["sprintList", payload?.projectId],
    queryFn: () => getSprintList(payload),
    enabled: !!payload?.projectId,
  });
};

//Get Log List
export const getLogtList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.log.get + "?", payload);

export const useGetLog = (payload: any) => {
  return useQuery({
    queryKey: ["logList"],
    queryFn: () => getLogtList(payload),
    // enabled: !!payload?.projectId
  });
};
