import { useEffect } from "react";
import { useState } from "react";
import SunEditor from "suneditor-react";
import "../../common/common.css";
import {
  Breadcrumb,
  // DatePicker,
  notification,
  Popconfirm,
  Select,
  Table,
  Tooltip,
  TreeSelect,
} from "antd";
import BooksService from "../../Services/Books";
import "./DashboardMain.css";
import {
  formatDate,
  initialsgenerator,
  // priorityconvertor,
  selectvalue,
  
  statusbackgroundgenerator,
  statuscolorfont,
  statusconvertor,
  statusvalueconvertor,
  // substringmethod,
  textnumbergenerator,
} from "../../utils/Util";
import {
  formatDatedatetimeyear,
} from "../../utils/DateConvertor";
import { format } from "date-fns";
import HTTPSCalls from "../../Services/HTTPCalls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ImageListType, ImageType } from "react-images-uploading";
import {
  Col,
  Divider,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  Button,
  Card,
  Avatar,
  Tabs,
} from "antd";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FullscreenOutlined,
  LoadingOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import NoData from "../NoData";
import FilePreview from "./FIlePreview";
import { ModalTemplate } from "./Modal/Modal";
import Loader from "./../../components/commoncomponents/Loader";
import ImageUpload from "./ImageUpload/ImageUpload";
import TaskModel, { CreateType } from "./Modal/TaskModel";
import { List } from "antd";
import { useSelector } from "react-redux";
import { setLoading, userData } from "../../store/slices/user-slice";
import TabPane from "antd/es/tabs/TabPane";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { dayjs } from "../../utils/dayjs";
import Sprint from "../Sprint";
enum LogType {
  All,
  Comment,
  History,
  Child,
  Attachment,
}
function LogRender(data: any, logtype: LogType) {
  const renderComment = () => (
    <div style={{ marginBottom: 5, fontSize: 14 }}>
      <List.Item>
        <List.Item.Meta
          style={{ display: "flex" }}
          avatar={
            <Avatar
              style={{ marginRight: 20 }}
              icon={<UserOutlined />}
              size={35}
              src={
                data.thumb === null ||
                data.thumb === "" ||
                data.thumb === undefined
                  ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                      data.assigneeName
                    )}-${textnumbergenerator(data.assigneeName)}.png?ssl=1`
                  : data.thumb
              }
            />
          }
          description={
            <>
              <Typography.Text strong className="mr-1">
                {data.assigneeName}
              </Typography.Text>
              <Typography.Text>
                {formatDatedatetimeyear(data.cDate)}
              </Typography.Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.remarks.replace("p>", "span>"),
                }}
              />
            </>
          }
        />
      </List.Item>
    </div>
  );

  const renderHistory = () => (
    <div
      style={{
        margin: 8,
        marginBottom: 20,
      }}
    >
      <Typography.Text
        style={{
          fontFamily: "Open Sans",
        }}
      >
        {<>{data.remarks} </>}
      </Typography.Text>
      <p
        style={{
          fontSize: "10px",
          color: "#8b99a7",
          margin: 0,
          marginBottom: 3,
        }}
      >
        {formatDatedatetimeyear(data.cDate)}
      </p>
      <p
        style={{
          fontSize: 14,
          color: "#6c798e",
          margin: 0,
        }}
      >
        {(data.cngType === 3 || data.cngType === 2) && (
          <>
            {" "}
            <span
              style={{
                backgroundColor: `${statusbackgroundgenerator(data.from)}`,
                color: `${statuscolorfont(data.from)}`,
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 2,
                fontSize: 12,
              }}
              dangerouslySetInnerHTML={{
                __html: data.from ? data.from : "None",
              }}
            ></span>
            {`->`}
            <span
              style={{
                backgroundColor: `${statusbackgroundgenerator(data.to)}`,
                color: `${statuscolorfont(data.to)}`,
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 5,
                paddingRight: 5,
                borderRadius: 2,
                fontSize: 12,
              }}
              dangerouslySetInnerHTML={{ __html: data.to ? data.to : "None" }}
            ></span>
          </>
        )}
      </p>
    </div>
  );

  const renderAll = () => (
    <Row className="m-2 mb-5">
      {/* <Col span={24}>
        {data.from === "" && data.to === "" ? (
          <div className="font-14 Open Sans">
            <p className="font-14 mb-1 text-color-primary">
              <span>{data.assigneeName}</span>
              {" added a "}
              <span>comment </span>
            </p>
            <p className="font-10 text-color-secondary m-0 mb-3">
              {formatDatedatetimeyear(data.cDate)}
            </p>
            <p className="font-14 text-color-tertiary m-0">{data.remarks}</p>
          </div>
        ) : (
          <>
            <p className="font-14 Open Sans mb-1 text-color-primary">
              {data.remarks}
            </p>
            <p className="font-10 text-color-secondary m-0 mb-3">
              {formatDatedatetimeyear(data.cDate)}
            </p>
            <p className="font-14 text-color-tertiary m-0">
              {(data.cngType === 3 || data.cngType === 2) && (
                <>
                  <span
                    className={`status-badge ${statusbackgroundgenerator(
                      data.from
                    )} ${statuscolorfont(data.from)}`}
                  >
                    {data.from || "None"}
                  </span>
                  {" -> "}
                  <span
                    className={`status-badge ${statusbackgroundgenerator(
                      data.to
                    )} ${statuscolorfont(data.to)}`}
                  >
                    {data.to || "None"}
                  </span>
                </>
              )}
            </p>
          </>
        )}
      </Col> */}
    </Row>
  );
  switch (logtype) {
    case LogType.Comment:
      return renderComment();
    case LogType.History:
      return renderHistory();
    case LogType.All:
      return renderAll();
    default:
      return null;
  }
}

function getObjectChanges(oldObj: any, newObj: any) {
  const changes: any = {};
  for (const key in oldObj) {
    if (!newObj.hasOwnProperty(key)) {
      changes[key] = { from: oldObj[key], to: undefined };
    }
  }

  // Check for properties in the new object that are not present in the old object
  for (const key in newObj) {
    if (!oldObj.hasOwnProperty(key)) {
      changes[key] = { from: undefined, to: newObj[key] };
    }
  }

  // Check for properties that exist in both objects and have different values
  for (const key in newObj) {
    if (oldObj.hasOwnProperty(key) && newObj[key] !== oldObj[key]) {
      changes[key] = { from: oldObj[key], to: newObj[key] };
    }
  }

  return changes;
}


const priorityconvertor = (priority: any) => {
  let color: any;
  switch (priority) {
    case 2:
      color = "#ff8585";
      return (
        <span style={{ color }}>
          {/* <CaretUpOutlined label="high" /> */}
          High
        </span>
      );
    case 1:
      color = "#ffd19e";
      return (
        <span style={{ color }}>
          {/* <CaretDownOutlined label="medium" /> */}
          Medium
        </span>
      );
    case 0:
      color = "#d9d9d9";
      return (
        <span style={{ color }}>
          {/* <CaretDownOutlined label="low" /> */}
          Low
        </span>
      );
    default:
      return null;
  }
};
const DashboardMain = () => {
  // let { showAlert }: any = useAlert();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const urlArray = location.pathname.split("/");
  const UrlParam = urlArray[urlArray.length - 1];
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  //alert configuration
  const user = useSelector(userData);
  const [errorMessage, setErrorMessage] = useState(null);

  //options states
  const [sprintOptions, setSprintOptions] = useState<any[]>([]);
  const [sprintName, setSprintName] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState<any[]>([]);
  const [assigneeName, setAssigneeName] = useState([]);
  const [issueTypeOptions, setIssueTypeOptions] = useState<any[]>([]);
  const [issueTypeName, setIssueTypeName] = useState([]);
  const [originalValue, setOriginalValue] = useState("");
  const [sprintIds, setsprintIds] = useState([]);
  // Data States
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [updatedTaskDetail, setUpdatedTaskDetail] = useState<any>();
  const [taskId, setTaskId] = useState<string>("");
  const [uKey, setUKey] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [commentData, setCommentData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [htmlHistory, setHtmlHistory] = useState<any>([]);
  const [docList, setDocList] = useState<ImageListType>([]);
  const [taskAttachment, setTaskAttachment] = useState([]);
  const [currentDocToPreview, setCurrentDocToPreview] = useState<any>(null);
  const [reporterOpt, setReporterOpt] = useState<any>([]);
  // const [initaialValueChange, setinitaialValueChange] = useState<any>([]);

  //Togglers
  const [isComment, setIsComment] = useState<LogType>(LogType.Comment);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isCommentEditor, setIsCommentEditor] = useState<boolean>(false);
  const [isDescriptionEditor, setIsDescriptionEditor] =
    useState<boolean>(false);
  const [isDocumentUpload, setIsDocumentUpload] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  // const [downloadAttachment, setDownloadAttachment] = useState<any>();
  // const [historyloading, setHistoryLoading] = useState(false);
  //Loader States
  // const [globalLoading, setGlobalLoading] = useState<boolean>(false);
  const [activityLoading, setactivityloading] = useState<boolean>(false);
  // const [apicallLoader, setApicallLoader] = useState<boolean>(false);
  const [isChildLoading, setIsChildLoading] = useState<boolean>(false);
  const [isDocsLoading, setIsDocsLoading] = useState<boolean>(false);
  // const [isloading, setisloading] = useState(false);
  const [isCommentIconLoader, setIsCommentIconLoader] = useState(false);
  const [createChild, setCreateChild] = useState<boolean>(false);
  const [branchData, setBranchData] = useState<any>([]);
  // const [selectChildIssue, setselectChildIssue] = useState(false);
  const [childTask, setChildTask] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [cardWidth, setCardWidth] = useState(window.innerWidth);
  // dayjs.extend(customParseFormat);

  function LogConvertor(value: any, key: string) {
    // console.log("value =", value);
  
    if (key === "title" || key === "description") {
      return value ? value : "none";
    } else if (key === "tStatus") {
      return value ? statusconvertor(value) : "backlog";
    } else if (key === "dueDate") {
      return value ? formatDate(value) : "none";
    } else if (key === "sprintIds") {
      if (value && value.length > 0) {
        const sprint = sprintOptions?.find((s: any) => s.value === value[0]);
        return sprint?.label || "Backlog";
      }
      return "Backlog";
    } else if (key === "priority") {
      return value ? priorityconvertor(value) : "none";
    } else if (
      key === "label" ||
      key === "assignee" ||
      key === "branch" ||
      key === "reporter"
    ) {
      return value ? value : "none";
    }
    
    // Assuming that this condition is meant for when the value is an array of objects
    if (Array.isArray(value)) {
      return value.map((obj: any) => obj?.label).join(", ");
    }
  
    return value;
  }

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const TabRender = (item: any) => {
    if (item?.shouldRender) {
      return (
        <div
          onClick={() =>
            item?.filtervalue !== null && setIsComment(item?.filtervalue)
          }
        >
          {item?.label}
        </div>
      );
    }
    return null;
  };
  const handleChangestatus = async (data: any, childTaskId: string) => {
    try {
      setIsLoading(true);
      const res = await BooksService.updateTask({
        tid: childTaskId,
        tstatus: data,
      });
      if (res?.status) {
        notification.success({
          message: "Success",
          description: "ChildTask status has been updated successfully.",
          placement: "bottomLeft",
        });
        await getChildTask(taskId);
      } else {
        notification.error({
          message: "Error",
          description: "There was an error updating the ChildTask status.",
          placement: "bottomLeft",
        });
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: "There was an error updating the ChildTask status.",
        placement: "bottomLeft",
      });
    } finally {
      setIsLoading(false);
    }
  };
  // Tabel Data
  const columns = [
    {
      dataIndex: "attachment",
      render: (_: any, record: { image: any }) => (
        <div>
          {record.image && (
            <div onClick={() => {}} style={{ paddingLeft: "10px" }}>
              {record.image.contentType === "video/webm"
                ? imagePath(record.image.name)
                : imagePath(record.image.name)}
            </div>
          )}
        </div>
      ),
    },
    {
      dataIndex: "name",
      render: (name: string | number | boolean | React.ReactElement) => (
        <p style={{ fontWeight: 400, margin: 0 }}>{name}</p>
      ),
    },
    {
      dataIndex: "actions",
      align: "right" as "right",
      render: (_: any, record: { image: any }) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="link"
            onClick={() => {
              setCurrentDocToPreview(record.image);
              setShowPreview(true);
            }}
          >
            <FullscreenOutlined
              style={{ fontSize: "17px", color: "#8c8c8c" }}
            />
          </Button>
          <a href={record.image?.downloadURL} style={{ marginLeft: 8 }}>
            <Button type="link">
              <DownloadOutlined
                style={{ fontSize: "17px", color: "#8c8c8c" }}
              />
            </Button>
          </a>
          <Popconfirm
            title="Are you sure to delete this attachment?"
            onConfirm={() => DeleteAttachment(taskId, record.image.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <DeleteOutlined style={{ fontSize: "17px", color: "#8c8c8c" }} />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const item = [
    // Comment Ant
    {
      key: LogType.Comment,
      label: "Comment",
      shouldRender: true,
      filtervalue: LogType.Comment,
      children: (
        <Row>
          <Col
            className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
            style={{
              maxHeight: "49vh",
              marginTop: 10,
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <Row justify="space-between" align="middle" className="pb-8 pt-0">
              <Col
                span={24}
                style={{
                  marginTop: 8,
                  border: "1px solid #f4f5f7",
                  borderRadius: "5px",
              
                
                 
                }}
              >
                {isCommentEditor ? (
                  <Row style={{}}>
                    <Col span={24}>
                      <SunEditor
                        setContents={comment}
                        onChange={(value) => setComment(value)}
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                      loading={isCommentIconLoader}
                        type="primary"
                        className=" mt-3 mb-2 ml-2 mr-2 "
                        onClick={() => {
                          if (comment === "") {
                            showError("You have to add a comment");
                          } else {
                            AddComment();
                          }
                        }}
                      >
                        Send
                      </Button>

                    
                     
                        <Button
                          type="text"
                          className=" mt-3 mb-2  "
                          onClick={() => {
                            setIsCommentEditor(false);
                            setComment("");
                          }}
                        >
                          Cancel
                        </Button>
                    
                    </Col>
                    {errorMessage && (
                      <Col
                        span={24}
                        className="ant-col ant-col-24 ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
                        style={{ padding: "10px", margin: "10px 0" }}
                      >
                        <div className="ant-col-inner" style={{ color: "red" }}>
                          {errorMessage}
                        </div>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <Input.TextArea
                  style={{ backgroundColor: "#f3f4fa" }}
                    className="Commentsunn"
                    autoSize={{ minRows: 2 }}
                    placeholder="Add a comment"
                    onClick={() => setIsCommentEditor(true)}
                    
                  />
                )}
              </Col>
            </Row>

            {commentData.length === 0 ? (
              <Row
                style={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                align="middle"
              >
                <img
                  style={{ margin: "0px 37px" }}
                  src={process.env.PUBLIC_URL + "/noTask.svg"}
                  alt="No Task"
                />
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontFamily: "Open Sans",
                      margin: 0,
                      fontSize: 16,
                      color: "rgb(72, 82, 110)",
                    }}
                  >
                    No Data yet
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    You have no available Data to display
                  </Typography.Text>
                </Col>
              </Row>
            ) : (
              <Row>
                {activityLoading ? (
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15%",
                    }}
                  >
                    {/* <Spin size="small" /> */}
                  </Col>
                ) : (
                  commentData.map((value, index) => {
                    //  console.log('LL',commentData);

                    return (
                      <Col span={24} key={index}>
                        {LogRender(value, LogType.Comment)}
                      </Col>
                    );
                  })
                )}
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
    // History Ant
    {
      key: LogType.History,
      label: "History",
      shouldRender: true,
      filtervalue: LogType.History,
      children: (
        <Row>
          <Col
            className="ant-col ant-col-xs-24 ant-col-sm-24 ant-col-md-24 ant-col-lg-24 ant-col-xl-24 ant-col-xxl-24"
            style={{
              maxHeight: "49vh",
              marginTop: 10,
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {historyData.length === 0 ? (
              <Row
                style={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                align="middle"
              >
                <img
                  style={{ margin: "0px 37px" }}
                  src={process.env.PUBLIC_URL + "/noTask.svg"}
                  alt="No Task"
                />
                <Col>
                  <Typography.Title
                    level={4}
                    style={{
                      fontFamily: "Open Sans",
                      margin: 0,
                      fontSize: 16,
                      color: "rgb(72, 82, 110)",
                    }}
                  >
                    No Data yet
                  </Typography.Title>
                  <Typography.Text type="secondary">
                    You have no available Data to display
                  </Typography.Text>
                </Col>
              </Row>
            ) : (
              <Row>
                {activityLoading ? (
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15%",
                    }}
                  >
                    {/* <Spin size="small" /> */}
                  </Col>
                ) : (
                  historyData.map((value, index) => (
                    <Col span={24} key={index}>
                      {LogRender(value, LogType.History)}
                    </Col>
                  ))
                )}
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
    // Child Ant
    {
      key: "3",
      label: (
        <Row justify="center" align="middle">
          <Col>Child</Col>
          <Button
            type="link"
            className="pl-0 pr-0"
            onClick={(e) => {
              e.stopPropagation();
              setCreateChild(true);
            }}
          >
            <PlusOutlined className="icon-small  ml-1" />
          </Button>
        </Row>
      ),
      shouldRender: true,
      filtervalue: LogType.Child,
      children: (
        <Row>
          <Col span={24}>
            {/* <Panel> */}
            <Row>
              <Col span={24}>
                {/* <Panel> */}
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col
                        span={24}
                        style={{
                          maxHeight: "49vh",
                          marginTop: 10,
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                        }}
                      >
                        <Row>
                          {/* style={{ overflowY: "scroll" }} */}
                          <Col span={24}>
                            {childTask.length === 0 ? (
                              <Row
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  marginTop: "7.5%",
                                }}
                                align="middle"
                              >
                                <img
                                  style={{ margin: "0px 37px" }}
                                  src={process.env.PUBLIC_URL + "/noTask.svg"}
                                  alt="No Task"
                                />
                                <Col>
                                  <Typography.Title
                                    level={4}
                                    style={{
                                      fontFamily: "Open Sans",
                                      margin: 0,
                                      fontSize: 16,
                                      color: "rgb(72, 82, 110)",
                                    }}
                                  >
                                    No Data yet
                                  </Typography.Title>
                                  <Typography.Text type="secondary">
                                    You have no available Data to display
                                  </Typography.Text>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col span={24}>
                                  {isChildLoading ? (
                                    <Row>
                                      <Col
                                        className="pt-10"
                                        span={12}
                                        offset={12}
                                      >
                                        <Spin />
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Row>
                                      <Col
                                        span={24}
                                        style={{
                                          marginBottom: 10,
                                          fontSize: 14,
                                          fontFamily: "Open Sans",
                                          marginTop: "10px",
                                          height: "30vh",
                                        }}
                                      >
                                        {childTask.map((item: any) => (
                                          <Row key={item?.id}>
                                            <Col
                                              span={24}
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <Row gutter={[16, 32]}>
                                                <Col
                                                  span={24}
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    margin: "11px 0px",
                                                  }}
                                                  // onClick={() => {}}
                                                >
                                                  <Col span={24}>
                                                    <Typography.Text
                                                      style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Open Sans",
                                                        color: "#7D70DB",
                                                        paddingLeft: "5px",
                                                      }}
                                                      onClick={() => {
                                                        setTaskAttachment([]);
                                                        setChildTask([]);
                                                        setHistoryData([]);
                                                        setCommentData([]);
                                                        navigate(
                                                          `/task/${item?.uKey}`
                                                        );
                                                      }}
                                                    >
                                                      {item?.uKey}
                                                    </Typography.Text>
                                                  </Col>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col span={24}>
                                                  <Tooltip
                                                    title={item?.title}
                                                    overlayStyle={{
                                                      whiteSpace: "pre-wrap",
                                                    }}
                                                  >
                                                    <span>
                                                      {substringmethod(
                                                        item?.title
                                                      )}
                                                    </span>
                                                  </Tooltip>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col
                                                  span={24}
                                                  style={{ width: "66px" }}
                                                >
                                                  {priorityconvertor(
                                                    item?.priority
                                                  )}
                                                </Col>
                                              </Row>

                                              <Row>
                                                <Col
                                                  span={24}
                                                  style={{
                                                    paddingLeft: "10px",
                                                    width: "57px",
                                                  }}
                                                >
                                                  {item?.assignee?.length > 0 ? (
                                                    <Avatar.Group
                                                      size={24}
                                                      maxCount={1}
                                                      maxPopoverTrigger="click"
                                                      maxPopoverPlacement="bottom"
                                                    >
                                                      {item.assignee.map(
                                                        (
                                                          assignee: any,
                                                          index: any
                                                        ) => (
                                                          <Tooltip
                                                            title={
                                                              <div>
                                                                <div>
                                                                  {
                                                                    assignee.name
                                                                  }
                                                                </div>
                                                                <img
                                                                  src={
                                                                    assignee.thumb ===
                                                                      null ||
                                                                    assignee.thumb ===
                                                                      "" ||
                                                                    assignee.thumb ===
                                                                      undefined
                                                                      ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                                                                          assignee.name
                                                                        )}-${textnumbergenerator(
                                                                          assignee.name
                                                                        )}.png?ssl=1`
                                                                      : assignee.thumb
                                                                  }
                                                                  alt={
                                                                    assignee.name
                                                                  }
                                                                  style={{
                                                                    maxWidth:
                                                                      "100px",
                                                                    maxHeight:
                                                                      "100px",
                                                                  }}
                                                                />
                                                              </div>
                                                            }
                                                            key={index}
                                                          >
                                                            <Avatar
                                                              src={
                                                                assignee.thumb ===
                                                                  null ||
                                                                assignee.thumb ===
                                                                  "" ||
                                                                assignee.thumb ===
                                                                  undefined
                                                                  ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
                                                                      assignee.name
                                                                    )}-${textnumbergenerator(
                                                                      assignee.name
                                                                    )}.png?ssl=1`
                                                                  : assignee.thumb
                                                              }
                                                              size={24}
                                                            />
                                                          </Tooltip>
                                                        )
                                                      )}
                                                    </Avatar.Group>
                                                  ) : (
                                                    <Avatar size="small" />
                                                  )}
                                                </Col>
                                              </Row>

                                              <Row className="body-color">
                                                <Col
                                                  span={24}
                                                  style={{
                                                    width: "250px",
                                                  }}
                                                >
                                                  <Select
                                                    style={{ width: 150 }}
                                                    options={[...selectvalue]}
                                                    // spacing="compact"
                                                    placeholder="Choose status"
                                                    value={[
                                                      {
                                                        label: statusconvertor(
                                                          item?.tStatus
                                                        ),
                                                        value:
                                                          statusvalueconvertor(
                                                            item?.tStatus
                                                          ),
                                                      },
                                                    ]}
                                                    onChange={(e) => {
                                                      handleChangestatus(
                                                        e,
                                                        item?.id
                                                      );
                                                    }}
                                                    showSearch
                                                    onSearch={onSearch}
                                                    optionFilterProp="label"
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        ))}
                                      </Col>
                                    </Row>
                                  )}
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
               
              </Col>
            </Row>
         
          </Col>
        </Row>
      ),
    },
    // File Ant
    {
      key: "4",
      label: (
        <Row justify="start" align="middle" className="m-0">
          <Col>Files</Col>
          <Button
            type="link"
            className="pl-0 pr-0 "
            onClick={(e) => {
              e.stopPropagation();
              setIsDocumentUpload(true);
            }}
          >
            <PlusOutlined className="icon-small  ml-1" />
          </Button>
        </Row>
      ),
      shouldRender: true,
      filtervalue: LogType.Attachment,
      children: (
        <Row justify="center">
          <Col span={24}>
            <div
              style={{
                maxHeight: "49vh",
                marginTop: 10,
                overflowY: "scroll",
                scrollbarWidth: "none",
              }}
            >
              {/* <img
           src={process.env.PUBLIC_URL + "/noTask.svg"}
         /> */}
              {taskAttachment?.length === 0 ? (
                <Row
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "7.5%",
                  }}
                  align="middle"
                >
                  <img
                    style={{ margin: "0px 37px" }}
                    src={process.env.PUBLIC_URL + "/noTask.svg"}
                    alt="No Task"
                  />
                  <Col>
                    <Typography.Title
                      level={4}
                      style={{
                        fontFamily: "Open Sans",
                        margin: 0,
                        fontSize: 16,
                        color: "rgb(72, 82, 110)",
                      }}
                    >
                      No Data yet
                    </Typography.Title>
                    <Typography.Text type="secondary">
                      You have no available Data to display
                    </Typography.Text>
                  </Col>
                </Row>
              ) : (
                <Spin spinning={isDocsLoading} size="small">
                  <Table
                    columns={columns}
                    dataSource={taskAttachment.map((image: any, index) => ({
                      key: `row-${index}-${image.id}`,
                      image,
                      name: image.name,
                    }))}
                    loading={taskAttachment.length === 0}
                    pagination={false}
                  />
                </Spin>
              )}
            </div>
          </Col>
        </Row>
      ),
    },
  ];
  const showError = (message: any) => {
    setErrorMessage(message);

    // Clear the error message after 3000 milliseconds (3 seconds)
    setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };
  const showNotification = (type: "success" | "error", message: any) => {
    notification[type]({
      message: message,
      placement: "bottomLeft",
      duration: 3,
    });
  };
  const GetTaskDetailByUkey = async () => {
    setIsLoading(true);
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.task.getbyukey + "/" + UrlParam
    );
    //  console.log("response.result", response.result);

    if (response?.result) {
      let initialValue = {
        title: response?.result?.title,
        description: response?.result?.description,
        dueDate: dayjs(response?.result?.dueDate).format("YYYY-MM-DD"),

        priority: response?.result?.priority,
        tStatus: response?.result?.tStatus,
        // sprint: response?.result?.sprint?.map((item: any) => ({
        //   label: item?.name,
        //   value: item?.id,
        // })),
        project: response?.result?.project?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        })),
        assignee: response?.result?.assignee?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          ...item,
        })),
        assignedBy: response?.result?.assignedBy?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          ...item,
        })),
        label: response?.result?.label?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        })),
        sprintIds: response?.result?.sprint?.map((r: any) => r?.id),
        branch: response?.result?.branch?.map((item: any) => item?.id),
        reporter: response?.result?.reporter?.map((item: any) => item?.id),
      };
      setTaskId(response?.result?.id);
      setUKey(response?.result?.uKey);
      setTaskDetail(initialValue);
      setAssigneeName(initialValue?.assignee);
      setsprintIds(initialValue?.sprintIds);
      setUpdatedTaskDetail(initialValue);
      setIsEditable(false);
      Promise.all([
        GetSprintOptions(response?.result?.project[0]?.id),
        GetAssigneeOptions(response?.result?.project[0]?.id),
        GetLogs(response?.result?.id),
        getBatchData(),
        getChildTask(response?.result?.id),
        getTaskAttachment(response?.result?.id),
        GetIssueType(),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  };

  // get all users list
  const getAllUsers = async () => {
    await BooksService.getUser(2000).then((res: any) => {
      // console.log("resresres = ", res);
      setReporterOpt(
        res?.result?.map((item: any) => ({ label: item.name, value: item.id }))
      );
    });
  };
  const getBatchData = () => {
    BooksService.getBranch(1500).then((res: any) => {
      let branchArr: any = [];
      res.result.map((item: any) => {
        branchArr.push({ label: item.name, value: item.id, ...item });
      });
      setBranchData(branchArr);
    });
  };

  const GetSprintOptions = async (projectid: string) => {
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.sprint.getAll + "?",
      { start: 0, length: 100, projectid }
    );
    if (response?.result?.length > 0)
      setSprintOptions(
        response?.result?.map((arrayItem: any) => ({
          label: arrayItem?.name,
          value: arrayItem?.id,
        }))
      );
    setSprintName(response?.result?.map((item: any) => item?.name));
  };

  const GetAssigneeOptions = async (projectid: string) => {
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.user.getByProject + "/" + projectid,
      {}
    );
    if (response?.result?.length > 0)
      setAssigneeOptions(
        response?.result?.map((arrayItem: any) => ({
          label: arrayItem?.name,
          value: arrayItem?.id,
      
        }))
      );
    setAssigneeName(response?.result?.map((item: any) => item.name));
  };

  // IssueType Api
  const GetIssueType = async () => {
    let response = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.issueType.getAll + "?",
      { start: 0, length: 100 }
    );
    if (response?.result?.length > 0)
      setIssueTypeOptions(
        response?.result?.map((arrayItem: any) => ({
          label: arrayItem?.name,
          value: arrayItem?.id,
        }))
      );
    setIssueTypeName(response?.result?.map((item: any) => item?.name));
  };

  // Log APi
  const GetLogs = async (taskid: string = "") => {
    try {
      let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.log.get + "?", {
        start: 0,
        length: 1500,
        tid: taskid === "" ? taskId : taskid,
        iscomment: 1,
      });

      setHtmlHistory(response?.result);
      if (response?.result?.length > 0) {
        switch (isComment) {
          case LogType.Comment:
            return setCommentData(response?.result);
          case LogType.History:
            return setHistoryData(response?.result);
          case LogType.Child:
            return setChildTask(response?.result);
          case LogType.Attachment:
            return setTaskAttachment(response?.result);
        }
      } else if (response?.errors) {
        // showAlert(response.message, AlertType.ERROR)
      }
    } catch (err) {}
  };
  const getChildTask = async (taskid: string = "") => {
    setIsChildLoading(true);
    try {
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.task.getAll + "/" + taskid + "?",
        { childtask: 1 }
      );
      if (response?.status) setChildTask(response?.result);

      // console.log('Abhi',response.result);
    } catch (err) {}
    setIsChildLoading(false);
  };

  const getTaskAttachment = async (taskid: string = "") => {
    setIsDocsLoading(true);
    try {
      let response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.task.getAttachments + "/" + taskid
      );
      // console.log("ashish", response.result);

      if (response?.result) setTaskAttachment(response?.result);
    } catch (err) {}
    setIsDocsLoading(false);
  };

  const UpdateTask = async () => {
    // debugger;
    setLoading(true);
    try {
      let requestObject = { ...updatedTaskDetail };
      // console.log("request", requestObject);
      const changes: { [key: string]: string } = {};

      // Compare fields and record changes
      Object.keys(updatedTaskDetail).forEach((key: any) => {
        if (updatedTaskDetail[key] !== originalValue[key]) {
          changes[key] = `${
            key.charAt(0).toUpperCase() + key.slice(1)
          } updated successfully`;
        }
      });
      if (updatedTaskDetail !== originalValue) {
        requestObject = {
          ...requestObject,
          tStatus: requestObject.tStatus,
          priority: requestObject.priority,
          dueDate: dayjs(requestObject?.dueDate).format("YYYY-MM-DD"),

          project: requestObject.project?.map(
            (item: { value: any; label: any }) => ({
              id: item.value,
              name: item.label,
            })
          ),
          assignee: requestObject?.assignee?.map(
            (item: { id: any; name: any; email: any; thumb: any }) => ({
              id: item?.id,
              name: item?.name,
              email: item.email,
              thumb: item.thumb,
            })
          ),
          assignedBy: requestObject.assignedBy?.map(
            (item: { value: any; label: any; email: any; thumb: any }) => ({
              id: item.value,
              name: item.label,
              email: item.email,
              thumb: item.thumb,
            })
          ),
          label: requestObject?.label?.map((item: any) => ({
            id: item?.value,
            name: item?.label, //issueTypeOptions?.find((b: any) => b.value === item)?.label,
          })),
          branch: requestObject?.branch?.map((item: any) => ({
            id: item,
            name: branchData?.find((b: any) => b.value === item)?.label,
          })),
          sprintIds: requestObject?.sprintIds || [""],
          reporter: requestObject?.reporter?.map((item: any) => {
            return {
              label: reporterOpt?.find((r: any) => r.value === item)?.label,
              value: item,
            };
          }),
          reportersIds: requestObject?.reporter?.map((item: any) => item),
        };

        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.task.updateTask + "/" + taskId,
          requestObject
        );

        if (response?.result !== updatedTaskDetail) {
          if (response?.result) {
            AddLog();
            // IsSuccess(true);
            Object.values(changes).forEach((message) =>
              showNotification("success", message)
            );
            GetTaskDetailByUkey();
          } else if (response.errors) {
            // IsSuccess(false);
            showNotification("error", response.message);
            // SetAlertMessage(response.message);
          }
        } else {
          console.log("hhh");
        }

        // addFlag();
      }
    } catch (error) {
      showNotification("error", "An error occurred while updating the task");
      console.error("Error updating task:", error);
    } finally {
      setLoading(false);
    }
  };
  const AddLog = async () => {
    // debugger
    let logObject: any = {};
    let requestBody: {
      taskId: string;
      user: { id: string; name: string };
      project: { id: string; name: string };
      logs: Array<any>;
    } = {
      taskId: taskId,
      user: { id: user?.id, name: user?.name },
      project: {
        id: updatedTaskDetail?.project?.[0].value,
        name: updatedTaskDetail?.project?.[0].label,
      },
      logs: [],
    };
    Object.assign(logObject, getObjectChanges(taskDetail, updatedTaskDetail));
    console.log("taskDetail =",taskDetail  ,"updatedTaskDetail =",updatedTaskDetail);
    
    requestBody.logs = Object.keys(logObject).map((key: any) => {
      let logTemplate = {
        tId: taskId,
        PId: updatedTaskDetail?.project?.[0].value,
        uKey: uKey,
        assigneeId: user?.id,
        assigneeName: user?.name,
        remarks: "",
        from: "",
        isComment: 2,
        to: "",
        cngType: 3,
      };
      logTemplate.from = LogConvertor(logObject[key]?.from, key);
      logTemplate.to = LogConvertor(logObject[key]?.to, key);
      logTemplate.remarks = `${user?.name} updated  ${key}`;
      // console.log(logTemplate, "logTemplate");

      return logTemplate;
    });
    return await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.log.add, requestBody);
  };
  const AddComment = async () => {
    let commentTemplate = {
      taskId: taskId,
      user: {
        id: user?.id,
        name: user?.name,
      },
      project: {
        id: user?.id,
        name: user?.id,
      },
      logs: [
        {
          tId: taskId,
          PId: updatedTaskDetail?.project?.[0].value,
          uKey: uKey,
          assigneeId: user?.id,
          assigneeName: user?.name,
          email: user?.email,
          remarks: comment,
     
          from: "",
          isComment: 1,
          to: "",
          cngType: 4,
         company: "63a959074e041fdc2ae91ce8",
        },
      ],
    };
    setIsCommentIconLoader(true);
    let response = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.log.add,
      commentTemplate
    );
    setComment("");
    if (response?.result) {
      notification.success({
        message: "Success",
        description: "Description updated successfully",
      });
    } else if (response.errors) {
      notification.error({
        message: "Error",
        description: response.message,
      });
    }
    setIsCommentIconLoader(false);
    setIsCommentEditor(false);
    GetLogs();
  };
  const AddAttachment = async () => {
    if (!docList || docList.length === 0) {
      notification.error({
        message: "Upload Failed",
        description: "No attachments found. Please select a file to upload.",
        placement: "bottomLeft",
      });
      return;
    }
  
    setAttachmentLoading(true);
    try {
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.addFile + `/${taskId}/UploadFile`,
        docList.map((item: ImageType) => ({
          name: item?.file?.name,
          contentType: item?.file?.type,
          existingType: 1,
          path: item?.data_url,
        }))
      );
      if (response.result.length !== 0) {
        notification.success({
          message: "Success",
          description: "Attachment uploaded successfully!",
          placement: "bottomLeft",
        });
        getTaskAttachment(taskId);
        setIsDocumentUpload(false);
      }
    } catch (error) {
      notification.error({
        message: "Upload Error",
        description: "No document selected for upload.",
        placement: "bottomLeft",
      });
    } finally {
      setAttachmentLoading(false);
    }
  };
  

  const DeleteAttachment = async (taskid: string, fileid: string) => {
    let response = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.task.attachment + "?",
      {},
      { tid: taskid, fileid: fileid }
    );
    if (response?.result) {
      notification.success({
        message: "Success",
        description: "Attachment Delete successfully!",
        placement: "bottomLeft",
      });
      getTaskAttachment(taskId);
    }
  };

  function imagePath(imageName: string) {
    const extensionMap: { [key: string]: string } = {
      pdf: "pdf.svg",
      png: "png.svg",
      doc: "doc.svg",
      xlxs: "xlxs.svg",
      xls: "xls.svg",
      jpg: "jpg.svg",
    };

    const extension = imageName?.split(".").pop()?.toLowerCase();
    const imagePath =
      extension && extensionMap[extension]
        ? extensionMap[extension]
        : "other.svg";

    return (
      <img
        style={{ paddingTop: "2px", height: "20px" }}
        src={process.env.PUBLIC_URL + "/" + imagePath}
        alt=""
      />
    );
  }
  function substringmethod(data: string) {
    if (data?.length > 20) {
      return data?.substring(0, 20) + "...";
    } else {
      return data;
    }
  }

  useEffect(() => {
    GetTaskDetailByUkey();
  }, [location.pathname]);

  useEffect(() => {
    taskId !== "" && GetLogs();
  }, [isComment, location.pathname]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleDateChange = (dueDate: any) => {
    setOriginalValue({
      ...updatedTaskDetail,
      dueDate: dueDate,
      // setStartDate(dueDate)
    });
  };

  return (
    <>
      <Spin spinning={isLoading}  indicator={<LoadingOutlined spin />} size="default" >
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/board"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/board"} className="BreadcrumbLink">
                    Board
                  </Link>
                ),
              },
              {
                title: "Task Detail",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white">
        <Col span={24}>
          <Loader loading={attachmentLoading} />
          {/* <Alert
            isSuccess={isSuccess}
            message={message}
            flags={flags}
            handleDismiss={handleDismiss}
          /> */}

          <>
            {Object.keys(taskDetail).length !== 0 ? (
              <>
                <Row
                  align="middle"
                  gutter={[0, 0]}
                  style={{ padding: "10px 8px" }}
                  justify={"start"}
                >
                  <Col>
                    <Typography.Text strong>Task Details</Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text strong style={{ paddingLeft: 10 }}>
                      {UrlParam}
                    </Typography.Text>
                  </Col>
                </Row>
                <Divider style={{ margin: 0 }} />

                <Row justify={"space-between"}>
                  <Col span={13} className="DrawerPadding">
                    <Row gutter={[0, 0]} align={"middle"} className="mb-8">
                      <Col span={3}>Title</Col>
                      <Col span={20}>
                        <Input
                          variant="borderless"
                          className="borderLessInput"
                          defaultValue={taskDetail?.title}
                          onBlur={() => {
                            if (updatedTaskDetail?.title?.length>= 3) {
                              UpdateTask()
                            }else{
                              notification.error({
                                message: 'Error',
                                description: "Title cannot exceed 3 characters!",
                              placement:'bottomLeft'
                            })
                          }}}
                          onFocus={() => setOriginalValue(updatedTaskDetail)}
                          onChange={(e) => {
                            setUpdatedTaskDetail({
                              ...updatedTaskDetail,
                              title: e.target?.value,
                            });
                          }}
                          required
                        />
                      </Col>
                    </Row>

                    <Row gutter={[0, 0]} align={"middle"} className="mb-8">
                      <Col span={3}>Description</Col>
                      <Col span={20}>
                        {isDescriptionEditor ? (
                          <div style={{ backgroundColor: "#ffffff" }}>
                            <SunEditor
                              setContents={taskDetail?.description}
                              setOptions={{
                                buttonList: [
                                  ["undo", "redo"],
                                  ["font", "fontSize"],
                                  ["bold", "underline", "italic", "strike"],
                                  ["fontColor", "hiliteColor"],
                                  ["align", "list", "lineHeight"],
                                  ["outdent", "indent"],
                                  ["print"],
                                  ["removeFormat"],
                                ],
                                defaultTag: "div",
                                showPathLabel: false,
                              }}
                              height="400px"
                              onChange={(selectValue: any) => {
                                if (selectValue) {
                                  setUpdatedTaskDetail({
                                    ...updatedTaskDetail,
                                    description: selectValue,
                                  });
                                } else {
                                  setUpdatedTaskDetail({
                                    ...updatedTaskDetail,
                                    description: "<p><br></p>",
                                  });
                                }
                              }}
                              onFocus={() =>
                                setOriginalValue(updatedTaskDetail)
                              }
                            />
                            
                            <Space
                              direction="horizontal"
                              size="middle"
                              className="mt-2"
                            >
                              <Button
                                type="primary"
                                onClick={() => {
                                  UpdateTask();
                                  setIsDescriptionEditor(false);
                                }}
                              >
                                Save
                              </Button>
                              <Button
                                type="text"
                                onClick={() => setIsDescriptionEditor(false)}
                              >
                                Cancel
                              </Button>
                            </Space>
                          </div>
                        ) : (
                          <div
                            className="borderLessInput"
                            onClick={() => setIsDescriptionEditor(true)}
                            style={{
                              height: "100px",
                              overflowX: "scroll",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: taskDetail?.description,
                            }}
                          ></div>
                        )}
                      </Col>
                    </Row>
                    <div
                      style={{
                        marginTop: 10,
                        marginLeft: 12,
                        fontSize: 14,
                        fontFamily: "Open Sans",
                      }}
                    >
                      <Tabs size="small" defaultActiveKey="1">
                        {item?.map((tab) => (
                          <TabPane tab={<TabRender {...tab} />} key={tab?.key}>
                            {tab?.children}
                          </TabPane>
                        ))}
                      </Tabs>
                    </div>
                  </Col>
                  <Col span={8} className="DrawerPadding">
                    <div>
                      <Card
                        className="DrawerPadding"
                        title="Details"
                        size="small"
                        style={{ height: "74vh" }}
                        extra={
                          <Row justify="end">
                            <Col>
                              {isEditable ? (
                                <div>
                                  <Button
                                    type="text"
                                    className="mt-1 mb-2"
                                    onClick={() => {
                                      setIsEditable(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    type="primary"
                                    className="mt-1 mb-2"
                                    onClick={() => {
                                      setIsEditable(true);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}
                            </Col>
                          </Row>
                        }
                      >
                        <Divider style={{ margin: 0 }} />
                        <div className="mt-5">
                          {/* Status */}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Status</Col>
                            <Col span={16} offset={1}>
                              <Select
                                variant="borderless"
                                className="borderLessInput w100"
                                defaultValue={taskDetail?.tStatus}
                                value={updatedTaskDetail.tStatus}
                                options={[
                                  { label: "Backlog", value: 0 },
                                  { label: "Todo", value: 1 },
                                  { label: "Need To Discuss", value: 2 },
                                  { label: "In Progress", value: 3 },
                                  { label: "UAT", value: 4 },
                                  { label: "Developed", value: 7 },
                                  { label: "Duplicate", value: 8 },
                                  { label: "Testing", value: 5 },
                                  { label: "Done", value: 6 },
                                ]}
                                // spacing="compact"
                                placeholder="Choose status"
                                onBlur={() => {
                                  UpdateTask();
                                }}
                                onFocus={() =>
                                  setOriginalValue(updatedTaskDetail)
                                }
                                onChange={(selectValue: any) => {
                                  if (selectValue === 8) setIsDuplicate(true);
                                  else
                                    setUpdatedTaskDetail({
                                      ...updatedTaskDetail,
                                      tStatus: selectValue,
                                    });
                                }}
                                showSearch
                                onSearch={onSearch}
                                optionFilterProp="label"
                              />
                            </Col>
                          </Row>

                          {/* Branch */}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Branch</Col>
                            <Col span={16} offset={1}>
                              <TreeSelect
                                variant="borderless"
                                className="borderLessInput w100"
                                value={updatedTaskDetail.branch}
                                dropdownStyle={{
                                  maxHeight: 400,
                                  overflow: "auto",
                                }}
                                treeData={branchData}
                                placeholder="Choose branches"
                                maxTagCount={1}
                                treeCheckable={true}
                                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                onBlur={() => {
                                  UpdateTask();
                                }}
                                onFocus={() =>
                                  setOriginalValue(updatedTaskDetail)
                                }
                                onChange={(selectValue: any) => {
                                  setUpdatedTaskDetail({
                                    ...updatedTaskDetail,
                                    branch: selectValue,
                                  });
                                }}
                                showSearch
                                onSearch={onSearch}
                                treeNodeFilterProp="label"
                              />
                            </Col>
                          </Row>

                          {/* Assigned By */}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Assigned By</Col>
                            <Col span={16} offset={1}>
                              {taskDetail?.assignedBy.length
                                ? taskDetail?.assignedBy[0]?.label
                                : ""}
                            </Col>
                          </Row>

                          {/* Sprint  */}
                         

                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Sprint</Col>
                            <Col span={16} offset={1}>
                            
                              {isEditable ? (
                                <div>
                                  <Select
                                          variant="borderless"
                                          className="borderLessInput w100"
                                          // defaultValue={taskDetail?.sprint}
                                          value={updatedTaskDetail?.sprintIds}
                                          placeholder="Choose sprint"
                                          options={sprintOptions}
                                          onBlur={() => {
                                            UpdateTask();
                                          }}
                                          onFocus={() =>
                                            setOriginalValue(updatedTaskDetail)
                                          }
                                          onChange={(selectValue: any) => {
                                            // console.log("change");
                                            setUpdatedTaskDetail({
                                              ...updatedTaskDetail,
                                              sprintIds: [selectValue],
                                            });
                                          }}
                                        />
                                </div>
                              ) : (
                                <div>
                                  {taskDetail?.sprintIds?.length > 0 ? (
                                    
                                    <Row>
                                       <Select
                                  variant="borderless"
                                  className="borderLessInput w100"
                                  // defaultValue={[...taskDetail?.sprint]}
                                  // value={updatedTaskDetail?.sprint}
                                  value={updatedTaskDetail?.sprintIds}
                                  // spacing="compact"
                                  placeholder="Choose sprint"
                                  options={sprintOptions}
                                  // onBlur={() => {
                                  //   UpdateTask();
                                  // }}
                                  // onFocus={() =>
                                  //   setOriginalValue(updatedTaskDetail)
                                  // }
                                  onChange={(selectValue: any) => {
                                    // console.log("change");
                                    setUpdatedTaskDetail({
                                      ...updatedTaskDetail,
                                      sprintIds: [selectValue],
                                    });
                                  }}
                                  showSearch
                                  onSearch={onSearch}
                                  optionFilterProp="label"
                                />
                                      {taskDetail?.sprintIds?.map((item: any) => (
                                        <Typography.Paragraph
                                          key={item?.value}
                                          className="mb-0 mr-3"
                                        >
                                          {item?.sprintIds}
                                        </Typography.Paragraph>
                                      ))}
                                    </Row>
                                  ) : (
                                    <div>None</div>
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>
                          {/* Priority  */}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Priority</Col>
                            <Col span={16} offset={1}>
                              {isEditable ? (
                                <Row justify={"center"}>
                                  <Col span={24}>
                                    <Select
                                      variant="borderless"
                                      className="borderLessInput w100"
                                      options={[
                                        { label: "High", value: 2 },
                                        { label: "Medium", value: 1 },
                                        { label: "Low", value: 0 },
                                      ]}
                                      defaultValue={taskDetail?.priority}
                                      onBlur={() => UpdateTask()}
                                      onFocus={() =>
                                        setOriginalValue(updatedTaskDetail)
                                      }
                                      onChange={(selectValue) => {
                                        setUpdatedTaskDetail({
                                          ...updatedTaskDetail,
                                          priority: selectValue,
                                        });
                                        console.log(
                                          "updatedTaskDetail",
                                          updatedTaskDetail
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <Typography.Text className=" m-0">
                                  {priorityconvertor(taskDetail?.priority)}
                                </Typography.Text>
                              )}
                            </Col>
                          </Row>

                          {/* Reporter */}

                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Reporter</Col>
                            <Col span={16} offset={1}>
                              <Select
                                variant="borderless"
                                className="borderLessInput w100"
                                placeholder="Choose reporter"
                                defaultValue={taskDetail?.reporter}
                                options={[...reporterOpt]}
                                onBlur={() => {
                                  UpdateTask();
                                }}
                                onChange={(value) => {
                                  setUpdatedTaskDetail({
                                    ...updatedTaskDetail,
                                    reporter: [value],
                                  });
                                }}
                                showSearch
                                onSearch={onSearch}
                                optionFilterProp="label"
                              />
                            </Col>
                          </Row>

                          {/* Due Date */}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Due Date</Col>
                            <Col span={16} offset={1} className="borderLessInput w100" >
                              {isEditable ? (
                                <DatePicker
                                  // variant="borderless"
                                  className="borderLessInput w100" 
                                  dateFormat="dd MMM yyyy"
                                  onFocus={() =>
                                    setOriginalValue(updatedTaskDetail)
                                  }
                                  // showIcon
                                  toggleCalendarOnIconClick
                                  onBlur={UpdateTask}
                                  selected={updatedTaskDetail?.dueDate}
                                  onChange={(dueDate: any) => {
                                     console.log("dueDate", dueDate);
                                    setUpdatedTaskDetail({
                                      ...updatedTaskDetail,
                                      dueDate: dueDate,
                                    });
                                  }}
                                />
                              ) : (
                                format(taskDetail?.dueDate, "dd MMM yyyy")
                              )}
                            </Col>
                          </Row>

                          {/* Assignee*/}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Assignee</Col>
                            <Col span={16} offset={1}>
                              {taskDetail?.assignee?.length > 0 ? (
                                <div>
                                  {isEditable ? (
                                    <TreeSelect
                                      variant="borderless"
                                      className="borderLessInput w100"
                                      maxTagCount={1}
                                      treeData={assigneeOptions}
                                      placeholder="Choose assignee"
                                      treeCheckable={true}
                                      defaultValue={
                                        taskDetail?.assignee
                                          ? [...taskDetail?.assignee]
                                          : []
                                      }
                                      onBlur={UpdateTask}
                                      onFocus={() =>
                                        setOriginalValue(updatedTaskDetail)
                                      }
                                      onChange={(
                                        selectValue: any,
                                        record: any
                                      ) => {
                                        setUpdatedTaskDetail({
                                          ...updatedTaskDetail,
                                          assignee: selectValue.map(
                                            (id: any, index: any) => ({
                                              id: id,
                                              name: record[index],
                                            })
                                          ),
                                        });
                                      }}
                                      showSearch
                                      onSearch={onSearch}
                                      treeNodeFilterProp="label"
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns:
                                          "auto auto auto auto",
                                      }}
                                    >
                                      {taskDetail?.assignee?.map(
                                        (item: any) => (
                                          <div key={item.id}>
                                            <Typography.Text>
                                              {item?.name}
                                            </Typography.Text>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {isEditable ? (
                                    <div>
                                      <TreeSelect
                                        variant="borderless"
                                        className="borderLessInput w100"
                                        treeData={assigneeOptions}
                                        placeholder="Choose assignee"
                                        defaultValue={
                                          taskDetail?.assignee
                                            ? [...taskDetail?.assignee]
                                            : []
                                        }
                                        onBlur={UpdateTask}
                                        onFocus={() =>
                                          setOriginalValue(updatedTaskDetail)
                                        }
                                        onChange={(selectValue: any) => {
                                          setUpdatedTaskDetail({
                                            ...updatedTaskDetail,
                                            assignee: selectValue,
                                          });
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div>Null</div>
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>

                          {/* Issue Type */}
                          <Row className="mb-8" align={"middle"}>
                            <Col span={7}>Issue Type</Col>
                            <Col span={16} offset={1}>
                              {/* {console.log("task", isEditable, taskDetail?.label)} */}
                              {isEditable ? (
                                <div>
                                  <TreeSelect
                                    variant="borderless"
                                    className="borderLessInput w100"
                                    treeCheckable
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                    placeholder="Choose Issue Type"
                                    defaultValue={taskDetail?.label}
                                    treeData={issueTypeOptions}
                                    onBlur={UpdateTask}
                                    onFocus={() =>
                                      setOriginalValue(updatedTaskDetail)
                                    }
                                    onChange={(value) => {
                                      const labels = value?.map((item: any) => {
                                        const foundItem = issueTypeOptions.find(
                                          (i: any) => i.value === item
                                        );
                                        return {
                                          label: foundItem.label,
                                          value: foundItem.value,
                                        };
                                      });
                                      setUpdatedTaskDetail({
                                        ...updatedTaskDetail,
                                        label: labels,
                                      });
                                    }}
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      overflow: "auto",
                                    }}
                                    showSearch
                                    onSearch={onSearch}
                                    treeNodeFilterProp="label"
                                  />
                                </div>
                              ) : (
                                <div>
                                  {taskDetail?.label?.length > 0 ? (
                                    <Row>
                                      {taskDetail?.label?.map((item: any) => (
                                        <Typography.Paragraph
                                          key={item?.value}
                                          className="mb-0 mr-3"
                                        >
                                          {item?.label}
                                        </Typography.Paragraph>
                                      ))}
                                    </Row>
                                  ) : (
                                    <div>Null</div>
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row justify="center" align="middle" style={{ height: "75vh" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Typography.Title level={1}>
                    {" "}
                    <NoData message="No task found" />
                  </Typography.Title>
                </Col>
              </Row>
            )}
          </>
          <>
            {isDocumentUpload && (
              <ModalTemplate
                title="Upload document"
                closeModal={() => setIsDocumentUpload(false)}
                onSubmit={AddAttachment}
              >
                <ImageUpload
                  value={docList}
                  onChange={(file: ImageListType) => setDocList(file)}
                />
              </ModalTemplate>
            )}
          </>
          {createChild && (
            <TaskModel
              configData={{ parantTaskId: taskId }}
              callback={() => getChildTask(taskId)}
              onDismiss={() => setCreateChild(false)}
              createType={CreateType.Child}
            />
          )}
          {showPreview && currentDocToPreview && (
            <FilePreview
              file={currentDocToPreview}
              onClose={() => setShowPreview(false)}
            />
          )}
          {/* {isDuplicate && (
        <DuplicateAttachModel
          closeModel={() => setIsDuplicate(false)}
          taskid={taskDetail?.id}
          uKey={taskDetail?.uKey}
          title={taskDetail?.title}
          reload={() => GetTaskDetailByUkey()}
        />
      )} */}
        </Col>
      </Row>
      </Spin>
    </>
  );
};
export default DashboardMain;
