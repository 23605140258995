import { useQuery } from "@tanstack/react-query";
import { store } from "../store";
import { selectUserId } from "../store/slices/user-slice";
import HTTPSCalls from "./HTTPCalls";

export const useUserList = (payload: any) => {
  return useQuery({
    queryKey: ["getUserList",payload],
    queryFn: () => getUserList(payload),
  });
};

const getUserList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.getUserList,{
    ...payload
  });
