import { userType } from "../constants/commonConstants";
import { ApiUtility } from "./ApiUtility";

class BooksService {
    POST(addLog: any, commentTemplate: { taskId: string; user: { id: string; name: string; }; project: { id: string; name: string; }; logs: { tId: string; pId: string; uKey: string; assigneeId: string; assigneeName: string; remarks: string; from: string; isComment: number; to: string; cngType: number; }[]; }) {
      throw new Error("Method not implemented.");
    }
    route = "/v1/posts";
    bookRoute = "/v1/api/books?lang=";
    endpoint = "/v1/comments";
    labelRoute = "/v1/api/Label?start=0&length=";
    branchRoute = "/v1/api/Branch?start=0&length=";
    ratingCategory = "/v1/api/RatingCategory"

    adminTotalDetail = "v1/api/AdminDashboard/GetUserAndProjectCount";

    labelpost = "/v1/api/Label"

    branchpost = "/v1/api/Branch"

    designationpost = "v1/api/Designation"

    projectpost = "/v1/api/Project"

    meetingAccept = "/v1/api/Meeting/saveacceptance"

    childtask = "/v1/api/ChildTask"

    childtaskupdatestatus = "/v1/api/childupdatestatus"

    projectRoute = "/v1/api/Project?start=0&length=";

    //https://localhost:7227/Account/ForgotPassword?Email=gfgg

    forgotpasswordRoute = "/Account/ForgotPassword";

    //https://localhost:7227/v1/api/Project?start=0&length=15&uid=637f71d5ef3aa530d48ea148&assigneebyid=63c6a7c138296b1fe90bac22&projecttype=1

    projectRouteByMutualUser = "v1/api/Project?uid="

    logData = "/v1/api/user/getlog?start=0&length=1500"

    meetingRoute = "/v1/api/Meeting"

    meetingRtu = "/v1/api/Meeting/MeetingDataApp"

    //https://localhost:7227/v1/api/Task?start=0&length=15&assigneeId=63c6a7c138296b1fe90bac22&assigneebyid=63931841510a17b83f3a8448&projecttype=1

    taskRouteByByMutualUser = "v1/api/Task?"

    //https://localhost:7227/v1/api/Task/6401dace5d536b9117260c6e/file?download_attachment=true

    downloadAttachment = "v1/api/Task/"

    taskRouteByByMutualUserByProjectid = "v1/api/Task?projectId="

    //https://localhost:7227/v1/api/Task/Attachment?tid=6409805484143f6d3b3229cf&fileid=ff5d8795-8f60-4aeb-aabc-c2d2eb65ce80

    deleteAttachment = "v1/api/Task/Attachment?tid="

    projectidRoute = "/v1/api/Project"

    taskRoute = "/v1/api/Task?start=0&length=";

    taskRoute1 = "/v1/api/Task?start=0&length=17000";

    reporting = "/v1/api/Task/TaskWiseRating";

    usertaskWissss = "v1/api/Task/UserDetails"

    getmilestone = "/v1/api/Milestone/GetList"

    //Account/ResetPassword?Email=sdfsdf&Password=sfsdfsd&Code=sdfsd&Id=sdfsd

    resetpasswordRoute = "/Account/ResetPassword"

    // https://projectsapi.capsitech.com/v1/api/Log?length=15&tid=63a1b703990041c021bc0b32

    taskRouteByProject = "/v1/api/Task?";

    //https://projectsapi.capsitech.com/v1/api/Task/MonthWiseTotalRecord?assigneeId=dfgdfg&projectId=gdfgd

    linechartRoute = "/v1/api/Task/MonthWiseTotalRecord?assigneeId="

    // https://projectsapi.capsitech.com/v1/api/Rating?projectid=sdfsdfs&length=15

    ratingRouteByProjectid = "/v1/api/Rating?projectid="

    // https://projectsapi.capsitech.com/v1/api/Task

    taskPost = "/v1/api/Task"
    userwisetask = "v1/api/Task/TaskDistributionListTeamwise"
   

    getDoneTask = "/v1/api/Task/getdonetask"

    getBacklogTask = "/v1/api/Task/getbacklogtask"

    meetingDescription = "/v1/api/Meeting/SaveDescription"

    meetingData ="/v1/api/Meeting?start=0&length="

    bulktaskutility = "/v1/api/Task/SaveBulkTaskUtility"

    bulkuserutility = "/v1/api/upateuserid?email="

    getTasksAdmin = "v1/api/Task"

    // https://projectsapi.capsitech.com/v1/api/Rating

    userRating = "v1/api/Rating"

    // https://projectsapi.capsitech.com/v1/api/updatestatus?tid=637f7baed42543a0917daed1&tstatus=5

    taskupdatepost = "/v1/api/updatestatus"

    //"/v1/api/updatepriority?tid=dfsdf&priority=-1"

    updatePriorityPost = "/v1/api/updatepriority"

    // https://projectsapi.capsitech.com/v1/api/Sprint?length=15

    sprintRoute = "/v1/api/Sprint?length=150&projectId="

    // https://projectsapi.capsitech.com/v1/api/Sprint

    sprintPost = "/v1/api/Sprint"

    // https://projectsapi.capsitech.com/v1/api/Log

    logPost = "/v1/api/Log"

    // https://localhost:7227/v1/api/Task/ProjectPendingTask

    pendingtaskRoute = "v1/api/Task/ProjectPendingTask"

    // https://projectsapi.capsitech.com/v1/api/user/assignee?length=15

    userRoute = "/v1/api/user/assignee?length="

   

    userIdRoute = "/v1/api/user/assignee"

    userTaskCount = "/v1/api/user/assigneebytaskcount?length=400"

    taskProjectWise = "/v1/api/Task?start=0&length=1500"

    // https://projectsapi.capsitech.com/v1/api/user/assignee?projid=

    userRouteByProject = "v1/api/user/assignee?projid="

    // https://projectsapi.capsitech.com/v1/api/user/project

    userRouteByProjectId = "v1/api/Project"


    updateprojectroute = "v1/api/user/project"

    labelCatogeryRoute = "v1/api/Category"

    // https://projectsapi.capsitech.com/v1/api/Log?length=60

    logRoute = "/v1/api/Log?length=1500000000"

    // https://projectsapi.capsitech.com/v1/api/Task/StatusTotalRecord


    /*https://localhost:7227/v1/api/Task/sdfsdf*/

    navigateRoute = "v1/api/Task"

    logRoutewithlength = "v1/api/Log?start=0&length="


    getUserData = "v1/api/Project/GetUserByProject"

    testcase = "v1/api/Log/AppList?start=0&length=15&iscomment=0"

    getDoneTaskPercentage = "v1/api/Task/GetTaskPercentage"

    getTaskOfUserProject = "v1/api/Task/GetAssigneeTaskByProject"

    dashboardRoute = "v1/api/Task/StatusTotalRecord"

    // https://projectsapi.capsitech.com/v1/api/user/changepassword

    changepasswordRoute = "v1/api/user/changepassword"

    companyRoute = "v1/api/user/Company?email="

    userProfile = "v1/api/user/profile"

    categoryRoute = "/v1/api/settings/filters?lang=";

    //https://localhost:7227/v1/api/Task/fdfdf/filebyid?fileid=dfdfdf&download_attachment=false

    previewfile = "v1/api/Task/"

    inhandtask = "v1/api/Task/InHandTasks?"

    loginRoute = "/Account/Login"

    get = (id: string) => ApiUtility.getResult(`${this.route}/${id}`);

    getList = (
        start: number,
        length: number,
        sortCol?: string,
        sortDir?: string,
        search?: string
    ) =>
        ApiUtility.get(this.route, {
            start,
            length,
            sortCol,
            sortDir,
            search,
        });

    getFilters = (lang: string, productFor: string) => {
        return ApiUtility.get(
            this.categoryRoute + lang + "&productFor=" + productFor
        );
    };

    getTaskByTaskId = (taskid: string) => {
        return ApiUtility.get(
            this.taskPost + "/" + taskid
        )
    }

    PreviewDocumentFile = (taskid: string, fileid: string) => {
        return ApiUtility.get(this.previewfile + taskid + "/filebyid?fileid=" + fileid + "&download_attachment=true")
    }

    getepubstream(bookId: string, headerWithAuth: any) {
        return ApiUtility.get(
            "api/Books/" + bookId + "/epubstream/" + bookId + ".epub",
            //this.domainname + "/api/books/" + bookId + "/epub/",
            { headers: headerWithAuth }
        );
    }

    getBooks = (
        lang: string,
        start: number,
        length: number,
        IsChildLiterature: boolean,
        categoryId: string,
        authorId: string,
        search: string,
        sort: string,
        langId: string,
        isSpecial: boolean
    ) => {
        return ApiUtility.get(
            this.bookRoute +
            lang +
            "&start=" +
            start +
            "&length=" +
            length +
            "&categoryId=5bbc5df21fd2d735b0087d2d" +
            "&IsChildLiterature=" +
            IsChildLiterature +
            "&authorId=5bbc60101fd2d735b0087d36" +
            "&search=" +
            search +
            "&sort=" +
            sort +
            "&bookLangauge=" +
            langId +
            "&isSpecial=" +
            isSpecial
        );
    };

    // labelRoute = "/api/Label?length=";

    // https://projectsapi.capsitech.com/v1/api/Project?length=15

    getProject = (length: number) => {
        return ApiUtility.get(this.projectRoute, { company: "63a959074e041fdc2ae91ce8" });
    };


    getTastCase = () => {
        return ApiUtility.get(this.testcase)
    }
    getProjectByUser = (data: any) => {

        return ApiUtility.get(this.projectRoute + "1500" + "&uid=" + data)
    }

    getProjectByUserandprojectid = (projectid: any) => {

        return ApiUtility.get(this.projectidRoute + "/" + projectid)
    }

    getRatingByProject = (projectid: string) => {
        return ApiUtility.get(this.ratingRouteByProjectid + projectid)
    }


    getUserTAskkkk = (projectid: string, start: number = 0, end: number = 125) => {
        return ApiUtility.get(this.usertaskWissss + "?projectid=" + projectid + "start=" + start + "&length=" + end)
    }

    getinHandTask = (projectid: string, assigneeId: string, statusfilter: string) => {
        return ApiUtility.get(this.inhandtask + "projectid=" + projectid + "&assigneeId=" + assigneeId + "&statusFilter=" + statusfilter)
    }

     
    getMilestoneData = (isAll: boolean, withChild:boolean) => {
        return ApiUtility.get(this.getmilestone + "?isAll=" + isAll + "&withChild=" + withChild)
    }

    getRatingProjectandUserid = (projectid: string, year: number) => {
        return ApiUtility.get(this.ratingRouteByProjectid + projectid + "&year=" + year)
    }

    getUserByProject = (projectid: string) => {
        return ApiUtility.get(this.userRouteByProject + projectid)
    }


    getUserProjectWise = (projectid: string) => {
        return ApiUtility.get(this.getUserData + "/"+ projectid)
    }

    getTaskPercentageProjectWise = (projectid: string) => {
        return ApiUtility.get(this.getDoneTaskPercentage + "/" + projectid)
    }

    getTaskProjectWise = (projectid: string) => {
        return ApiUtility.get(this.getTaskOfUserProject + "/" + projectid)
    }

    getUserByProjectId = (projectid: string) => {
        return ApiUtility.get(this.userRouteByProjectId + "/" + projectid)
    }

    getUserByProjectandUserid = (projectid: string, userid: string) => {
        return ApiUtility.get(this.userRouteByProject + projectid + "&uid=" + userid)
    }

    getTaskByIdGet = (taskid: string) => {
        return ApiUtility.get(this.navigateRoute + "/" + taskid)
    }

    getProjectByMutualUser = (assigneeid: string, userid: string) => {
        return ApiUtility.get(this.projectRouteByMutualUser + assigneeid + "&assigneebyid=" + userid + "&projecttype=" + 1)
    }

    getTaskByMutualUser = (assigneeid: string, userid: string, taskSoring: number = 0) => {
        return ApiUtility.get(this.taskRouteByByMutualUser + "assigneeId=" + assigneeid + "&assigneebyid=" + userid + "&taskSorting=" + taskSoring + "&projecttype=" + 1)
    }

    getTaskByMutualUserByProjectid = (assigneeid: string, userid: string, projectid: string, taskSoring: number = 0) => {
        return ApiUtility.get(this.taskRouteByByMutualUserByProjectid + projectid + "&assigneeId=" + assigneeid + "&taskSorting=" + taskSoring + "&assigneebyid=" +  userid + "&projecttype=" + 1)
    }

    addRating = (data: any) => {
        return ApiUtility.post(this.userRating, data)
    }

    deleteAttachmentFile = (tid: string, fileid: string) => {
        return ApiUtility.post(this.deleteAttachment + tid + "&fileid=" + fileid, {})
    }

    addLabel = (data: any) => {
        return ApiUtility.post(this.labelpost, data);
    };

    addBranch = (data: any) => {
        return ApiUtility.post(this.branchpost,data)
    };

    addDesignation = (data: any) => {
        return ApiUtility.post(this.designationpost, data)
    };

    getBranch = (length: number) => {
        return ApiUtility.get(this.branchRoute + length);
    };

    getLabel = (length: number) => {
        return ApiUtility.get(this.labelRoute + length);
    };

    getDoneTasks = (isAll: boolean, projectid: string, assigneeid: string) => {
        return ApiUtility.get(this.getDoneTask + "?isAll=" + isAll + "&projectid=" + projectid + "&assigneeid=" + assigneeid)
    }

    getBacklogTasks = (isAll: boolean, projectid: string, assigneeid: string) => {
        return ApiUtility.get(this.getBacklogTask + "?isAll=" + isAll + "&projectid=" + projectid + "&assigneeid=" + assigneeid)
    }

    getProjectwiseusertask = (projectid: string) => {
        return ApiUtility.get(this.userwisetask + "?projectid=" + projectid)
    }

    addProject = async (data: any) => {
        return await ApiUtility.post(this.projectpost, data, "application/json-patch+json");
    };

    downloadAttachmentFile = async (id: string, fileid: string) => {
        return await ApiUtility.get(this.downloadAttachment + id + "/file?" + "fileid=" + fileid + "&download_attachment=true")
    }

    getTask = async (length: number, taskSoring: number = 0) => {
        return await ApiUtility.get(this.taskRoute + "&taskSorting=" + taskSoring);
    };

    getTaskByProject = async( projectid: any, assigneeid: string, taskSoring: number = 0, orderno: number = 0, search: string = "", status: number = -1, priority: number = -1, assigneeIdFilter: string = "", start: number = 0, end: number = 15000, statusFilter: string = "", Utype: userType = userType.All, shouldShowDuplicate: boolean = false) => {
        return await ApiUtility.get(this.taskRouteByProject + "start=" + start + "&length=" + end + "&projectId=" + projectid + "&assigneeId=" + assigneeid + "&taskSorting=" + taskSoring + "&orderNo=" + orderno + "&searchText=" + search + "&status=" + status + "&priority=" + priority + "&assigneeIdFilter=" + assigneeIdFilter + "&statusFilter=" + statusFilter + "&Utype=" + Utype + "&shouldShowDuplicate=" + shouldShowDuplicate)
    }

    getTaskByProjectMultiple = async (projectIdsStr: string, projectid: any, assigneeid: string, taskSoring: number = 0, orderno: number = 0, search: string = "", status: number = -1, priority: number = -1, assigneeIdFilter: string = "", start: number = 0, end: number = 15000, statusFilter: string = "", Utype: userType = userType.All) => {
        return await ApiUtility.get(this.taskRouteByProject + "start=" + start + "&length=" + end + "&projectIdsStr=" + projectIdsStr +  "&projectId=" + projectid + "&assigneeId=" + assigneeid + "&taskSorting=" + taskSoring + "&orderNo=" + orderno + "&searchText=" + search + "&status=" + status + "&priority=" + priority + "&assigneeIdFilter=" + assigneeIdFilter + "&statusFilter=" + statusFilter + "&Utype=" + Utype)
    }

    getTaskByDateFilter = async (projectid: any, fromdate: string = "", todate: string = "", taskSoring: number = 0) => {
        return await ApiUtility.get(this.taskRouteByProject + "projectid=" + projectid + "&fromDate=" + fromdate + "&toDate=" + todate + "&taskSorting=" + taskSoring)
    }

    addLabelCategory = async (data: any) => {
        return await ApiUtility.post(this.labelCatogeryRoute, data)
    }

    getLabelCategory = async () => {
        return await ApiUtility.get(this.labelCatogeryRoute)
    }


    getPendingTaskByProject = async (projectid: any, isDone: any, assigneeId: string, taskSoring: number = 0) => {
        return await ApiUtility.get(this.taskRouteByProject + "start=" + 0 + "&length=" + 10000 + "&projectId=" + projectid + "&taskSorting=" + taskSoring + "&isDone=" + isDone + "&assigneeId=" + assigneeId)
    }


/*    getChildTask = async (taskid: string) => {
        return await ApiUtility.get(this.taskPost + "?taskid=" + taskid)
    }*/

    addChildTask = async (data: any) => {
        return await ApiUtility.get(this.childtask, data)
    }

    getChildTask = async (taskid: any, childtask: number = 0) => {
        return await ApiUtility.get(this.taskPost + "/" + taskid + "?childtask=" + childtask)
    }

    /*updateChildTask = async (taskid: string, tstatus: number) => {
        return await ApiUtility.post(this.taskPost + "?tid=" + taskid + "&tstatus=" + tstatus, {})
    }*/
    addTask = async (data: any) => {
        return await ApiUtility.post(this.taskPost, data)
    }

    addRatingCategory = async (data:any) => {
        return await ApiUtility.post(this.ratingCategory, data)
    }

    addMeeting = async (data:any) => {
        return await ApiUtility.post(this.meetingRoute, data)
    }

    addMeetingDescription = async (data: any) => {
        return await ApiUtility.post(this.meetingDescription, data)
    }

    getNotLogedData = async (isTodayDateFilter: boolean, isLoginNot: boolean) => {
        return await ApiUtility.get(this.logData + "&isTodayDateFilter=" + isTodayDateFilter + "&isLoginNot=" + isLoginNot)
    }

    getMeeting = async (participentid: string, meetingId: string, fromDate: string, toDate: string, isdoneshow: boolean = true) => {
        return await ApiUtility.get(this.meetingRoute + "?participentid=" + participentid + "&projectid=" + meetingId + "&fromdate=" + fromDate + "&todate=" + toDate + "&isdoneshow=" + isdoneshow)
    }

    getMeeting1 = async (participentid: string, meetingId: string, fromDate: string, toDate: string, isdoneshow: boolean = true) => {
        return await ApiUtility.get(this.meetingRtu + "?participentid=" + participentid + "&projectid=" + meetingId + "&fromdate=" + fromDate + "&todate=" + toDate + "&isdoneshow=" + isdoneshow)
    }


    getMeetingById = async (id: string) => {
        return await ApiUtility.get(this.meetingRoute + `/${id}`)
    }
   

    savebulktaskutility = async (data: any) => {
        return await ApiUtility.post(this.bulktaskutility, data)
    }

    savebulkuserutility = async (data: any) => {
        return await ApiUtility.post(this.bulkuserutility + data.email + "&userId=" + data.userid, data)
    }

    updateTask = async (data: any) => {
        return await ApiUtility.post(this.taskupdatepost +
            '?tid=' +
            data.tid +
            "&tstatus=" +
            data.tstatus, data)
    }

    addMeetingAccepts = async (participentid: string, meetingid: string, isAccepted: number) => {
        return await ApiUtility.post(this.meetingAccept + "?participentid=" + participentid + "&meetingid=" + meetingid + "&isAccepted=" + isAccepted, {})
    }


    updatePriority = async (data: any) => {
        return await ApiUtility.post(this.updatePriorityPost +
        '?tid=' +
        data.tid +
        "&priority=" +
        data.priority, data)
}

    getSprint = async (projectid: any) => {

        if (projectid === "all") {
            return await ApiUtility.get(this.sprintRoute)
        }
        else {
            return await ApiUtility.get(this.sprintRoute + projectid);
        }

    };

    getAdminDashbordChart = async () => {
        return await ApiUtility.get(this.getTasksAdmin )
    }

    sendmail = async (data: any) => {
        return await ApiUtility.post(this.forgotpasswordRoute + "?Email=" + data.email, data)
    }

    //Account/ResetPassword?Email=sdfsdf&Password=sfsdfsd&Code=sdfsd&Id=sdfsd

    resetpassword = async (data: any) => {
        return await ApiUtility.post(this.resetpasswordRoute + "?Email=" + data.email + "&Password=" + data.password + "&ConfirmPassword=" + data.confirmpassword + "&Code=" + data.code, data)
    }

    addSprint = async (data: any) => {
        return await ApiUtility.post(this.sprintPost, data)
    }

    addLog = async (data: any) => {
        return await ApiUtility.post(this.logPost, data)
    }

    getUser = async (length: any) => {
        return await ApiUtility.get(this.userRoute + length)
    }

    getUserById = async (userid: string) => {
        return await ApiUtility.get(this.userIdRoute + "?assigneeid=" + userid)
    }

    getUserTaskCount = async (assigneeid: string) => {
        return await ApiUtility.get(this.userTaskCount + "&assigneeid=" + assigneeid)
    }

    getLog = async (length: any) => {
        return await ApiUtility.get(this.logRoute);
    }

    addUserProfile = async (data: any) => {
        return await ApiUtility.post(this.userProfile, data)
    }

    getlogByprojectid = async (length: number, projectid: string, userid: string = "", iscomment: number) => {
        if (projectid === "") {
            return await ApiUtility.get(this.logRoutewithlength + length + "&assigneeid=" + userid + "&iscomment=" + iscomment)
        }
        else {
            return await ApiUtility.get(this.logRoutewithlength + length + "&iscomment=" + iscomment + "&projectid=" + projectid)
        }
    }

    getpendingTasks = async (assigneeid: string) => {
        return await ApiUtility.get(this.pendingtaskRoute + "?assigneeId=" + assigneeid)
    }

    getReportingTasks = async () => {
        return await ApiUtility.get(this.reporting)
    }

    getAllTaskPojectWise = async () => {
        return await ApiUtility.get(this.taskProjectWise)
    }

    getReportingTasksbyassignee = async (assigneeid: string) => {
        return await ApiUtility.get(this.reporting + "?assigneeids=" + assigneeid)
    }

    getReportingTasksbyAssignee = async (assigneeid: string, assigneebyid: string, statusfilter1: string, projectId: string = "") => {
        return await ApiUtility.get(this.reporting + "?assigneeids=" + assigneeid + "&assigneebyid=" + assigneebyid + "&projecttype=1" + "&statusFilter1=" + statusfilter1 + "&projectId=" + projectId)
    }

    getReportingTasksbyproject = async (assigneeid: string, projectid: string, assigneebyid: string, statusFilter1: string = "") => {
        return await ApiUtility.get(this.reporting + "?assigneeids=" + assigneeid + "&projectId=" + projectid + "&assigneebyid=" + assigneebyid + "&projecttype=1" + "&statusFilter1=" + statusFilter1)
    }

    getTaskByAssignee = async (assigneeid: string, sort: number = 0, isDone: number = 1, isBacklog: number = 1, isPriority:number =1) => {
        return await ApiUtility.get(this.taskRoute + "&assigneeId=" + assigneeid + "&sort=" + sort + "&isDone=" + isDone + "&isBacklog=" + isBacklog + "&isPriority=" + isPriority)
    }

    getTaskByAssignee1 = async (assigneeid: string, sort: number = 0, isDone: number = 1, isBacklog: number = 1, isPriority: number = 1) => {
        return await ApiUtility.get(this.taskRoute1 + "&assigneeId=" + assigneeid + "&sort=" + sort + "&isDone=" + isDone + "&isBacklog=" + isBacklog + "&isPriority=" + isPriority)
    }

    getCircularchartdata = async (assigneeid: string, projectid: any) => {
        if (projectid === null || projectid === "") {
            return await ApiUtility.get(this.taskRoute + "&assigneeId=" + assigneeid)
        }
        else {
            return await ApiUtility.get(this.taskRoute + "&projectId=" + projectid + "&assigneeId=" + assigneeid)
        }
    }

    getLogByisComment = async (logtype: any, tid: string) => {
        return await ApiUtility.get(this.logRoute + "&tid=" + tid + "&iscomment=" + logtype);
    }

    getTaskByassingeeidandprojectid = async (projectid: string, assigneeid: string) => {
        return await ApiUtility.get(this.dashboardRoute + "?projectId=" + projectid)
    }

    updateProject = async (data: any) => {
        return await ApiUtility.post(this.updateprojectroute + "?uid=" + data.uid, { projectIds: data.projectIds })
    }

    getLogByTaskid = async (taskid: any) => {
        return await ApiUtility.get(this.logRoute + "&tid=" + taskid)
    }

    addUser = async (data: any) => {
        return await ApiUtility.post(this.loginRoute + "?Email=" + data.email + "&Password=" + data.password, data)
    }

    getLogedData = async (isTodayDateFilter: boolean, isLoginNot: boolean) => {
        return await ApiUtility.get(this.logData + "?isTodayDateFilter=true" + isTodayDateFilter  +"&isLoginNo" + isLoginNot)
    }

    getAllAdminData = async () => {
        return await ApiUtility.get(this.adminTotalDetail)
    }

    getDashboard = async () => {
        return await ApiUtility.get(this.dashboardRoute)
    }

    getLinechart = async (userid: string, projectid: any, year: number) => {
        if (projectid === null || projectid === "") {
            return await ApiUtility.get(this.linechartRoute + userid + "&year=" + year)
        }
        else {
            return await ApiUtility.get(this.linechartRoute + userid + "&projectId=" + projectid + "&year=" + year)
        }
    }

    getCircularchart = async (userid: string, projectid: string) => {
        return await ApiUtility.get(this.linechartRoute + userid + "&projectId=" + projectid)
    }

    getDashboardByProject = async (projectid: string) => {
        return await ApiUtility.get(this.dashboardRoute + "?projectId=" + projectid)
    }

    getCompany = async (email: string) => {
        return await ApiUtility.get(this.companyRoute + email)
    }

    resetPassword = async (data: any) => {
        return await ApiUtility.post(this.changepasswordRoute, data)
    }

    

}
export default new BooksService();