import React, { useCallback } from 'react';
import { useEffect, useState } from "react";
import { initialsgenerator, textnumbergenerator } from "../../../utils/Util";
import AdminAlertModal from "../AdminAlertModal/AdminAlertModal";
import { Duration } from "../../AdminPages/AdminDashboard/AdminDashboard";
import "./TotalData.css"
import ButtonGroup from "antd/es/button/button-group";
import { Spin, Table, Tabs, TabsProps } from "antd";
import { useSelector } from 'react-redux';
import BooksService from '../../../Services/Books'
import { userData } from '../../../store/slices/user-slice';

const TotalDataTable = ({ projectdata, setdefaultCount, loader, setdefaultCount2, userDatas, loader2 }: any) => {

    const [userName, setUserName] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const [projectDrowpdown, setProjectDropdown] = useState<any>([]);
    const [color, setColor] = useState("weekly")
    const [color2, setColor2] = useState("weekly")
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
    const user = useSelector(userData);

    const fetchProjects = async () => {
        try {
            const res = await BooksService.getProjectByUser(user?.id);
            let userdatatype: any = [];
            res.result.map((item: any) => {
                userdatatype.push({
                    label: item.name,
                    value: item.id,
                    ...item,
                });
            });
            setProjectDropdown([...userdatatype]);
        } catch (err) { }
    };


    useEffect(() => {
        fetchProjects();
    }, []);

    const columns = [

        {
            title: "Assignee",
            dataIndex: "name",
            key: "name",
            width: "50%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Tasks",
            dataIndex: "total",
            key: "total",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Done",
            dataIndex: "done",
            key: "done",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Percentage",
            dataIndex: "percentage",
            key: "percentage",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}%</div>,
        },
    ];

    const columns2 = [

        {
            title: "Assignee",
            dataIndex: "name",
            key: "name",
            width: "50%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Tasks",
            dataIndex: "total",
            key: "total",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Done",
            dataIndex: "done",
            key: "done",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}</div>,
        },
        {
            title: "Percentage",
            dataIndex: "percentage",
            key: "percentage",
            width: "25%",
            render: (item: any) => <div className="pl-4">{item}%</div>,
        },
    ];


    const avatargroupstack = (data: any) => {
        if (data.assignee != null) {
            const data2 = data.assignee.map((d: any, i: any) => ({
                email: d.name,
                key: d.name,
                name: d.name,
                href: '#',
                src: d.imageThumbPath === null || d.imageThumbPath === "" || d.imageThumbPath === undefined ? `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(d.name)}-${textnumbergenerator(d.name)}.png?ssl=1` :
                    d.imageThumbPath
            }))

            return (
                < p style={{ color: "#3f5bd3", margin: 0, fontWeight: 400, borderRadius: 10, padding: 0 }
                }>
                    {/* <AvatarGroup appearance="stack" size="small" maxCount={2} data={data2} /> */}
                </p >
            )

            // return (< p style={{ color: "#3f5bd3", margin: 0, fontWeight: 400, borderRadius: 10, padding: 0 }
            // }>
            //     {/* <AvatarGroup appearance="stack" size="small" maxCount={2} data={data2} /> */}
            // </p >)
        }
    }

    const items: TabsProps['items'] = [
        {
            key: 'Employee',
            label: 'Employee',
            children: <div>
                {
                    loader2 === true ?
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "90px", width: "400px", height: "523px" }}>
                            <Spin />
                        </div>
                        :

                        <div className="mainTable2" >
                            <div style={{ marginBottom: "20px" }}>
                                <ButtonGroup >
                                    <p onClick={() => {
                                        setColor2("weekly")
                                        setdefaultCount(Duration.Weekly)
                                    }} style={{ backgroundColor: `${color2 === "weekly" ? "#0E51D3" : "#ffffff"}`, width: "76px", marginRight: "10px", cursor: "pointer", color: `${color2 === "weekly" ? "white" : "#0E51D3"}`, padding: "5px", textAlign: "center", borderRadius: "25px", fontSize: "14px", border: "1px solid #0E51D3" }}>Weekly</p>
                                    <p onClick={() => {
                                        setColor2("monthly")
                                        setdefaultCount(Duration.Monthly)
                                    }} style={{ backgroundColor: `${color2 === "monthly" ? "#0E51D3" : "#ffffff"}`, width: "76px", marginRight: "10px", cursor: "pointer", color: `${color2 === "monthly" ? "white" : "#0E51D3"}`, padding: "5px", textAlign: "center", borderRadius: "25px", fontSize: "14px", border: "1px solid #0E51D3" }}>Monthly</p>
                                    <p onClick={() => {
                                        setColor2("yearly")
                                        setdefaultCount(Duration.Yearly)
                                    }} style={{ backgroundColor: `${color2 === "yearly" ? "#0E51D3" : "#ffffff"}`, width: "76px", marginRight: "10px", cursor: "pointer", color: `${color2 === "yearly" ? "white" : "#0E51D3"}`, padding: "5px", textAlign: "center", borderRadius: "25px", fontSize: "14px", border: "1px solid #0E51D3" }}>Yearly</p>
                                </ButtonGroup>
                            </div>
                            <div className="maintableinn" >
                                {

                                    <Table
                                        bordered
                                        scroll={{ y: 600 }}
                                        dataSource={userDatas}
                                        pagination={false}
                                        columns={columns}
                                    />

                                }
                            </div>
                        </div>
                }
            </div>,
        },
        {
            key: 'Projects',
            label: 'Projects',
            children: <div>
                {
                    loader === true ?
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "135px", width: "400px", height: "477px", padding: "0px" }}>
                            <Spin />
                        </div>
                        :
                        <div className="mainTable" >
                            <div style={{ marginBottom: "20px" }}>
                                <ButtonGroup>
                                    <p onClick={() => {
                                        setColor("weekly")
                                        setdefaultCount(Duration.Weekly)
                                    }} style={{ backgroundColor: `${color === "weekly" ? "#0E51D3" : "#ffffff"}`, width: "76px", marginRight: "10px", color: `${color === "weekly" ? "white" : "#0E51D3"}`, cursor: "pointer", padding: "5px", textAlign: "center", borderRadius: "25px", fontSize: "14px", border: "1px solid #0E51D3" }}>Weekly</p>
                                    <p onClick={() => {
                                        setColor("monthly")
                                        setdefaultCount(Duration.Monthly)
                                    }} style={{ backgroundColor: `${color === "monthly" ? "#0E51D3" : "#ffffff"}`, width: "76px", marginRight: "10px", color: `${color === "monthly" ? "white" : "#0E51D3"}`, padding: "5px", textAlign: "center", borderRadius: "25px", fontSize: "14px", border: "1px solid #0E51D3" }}>Monthly</p>
                                    <p onClick={() => {
                                        setColor("yearly")
                                        setdefaultCount(Duration.Yearly)
                                    }} style={{ backgroundColor: `${color === "yearly" ? "#0E51D3" : "#ffffff"}`, width: "76px", marginRight: "10px", color: `${color === "yearly" ? "white" : "#0E51D3"}`, cursor: "pointer", padding: "5px", textAlign: "center", borderRadius: "25px", fontSize: "14px", border: "1px solid #0E51D3" }}>Yearly</p>
                                </ButtonGroup>
                            </div>
                            <div style={{
                                height: "530px",
                                overflowX: "scroll"
                            }}>
                                {

                                    <Table
                                        bordered
                                        scroll={{ y: 600 }}
                                        dataSource={projectdata}
                                        pagination={false}
                                        columns={columns2}

                                    />

                                }
                            </div>
                        </div>
                }
            </div>,
        }

    ];

    return (
        <>
            <div>

                <div className="outerTable" >

                    <Tabs defaultActiveKey="1" items={items} />

                </div>
            </div>
            {
                isOpen &&
                <AdminAlertModal userName={userName} isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
            }
        </>
    )
}

export default TotalDataTable;