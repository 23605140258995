import * as React from "react";
import { Row, Col, Card, Select, Typography } from "antd";
import Barchartdashboard from "./Barchartdashboard";
import ProgressLine from "../Progressbar/Progressbar";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import {
  projectData as PD,
  projectData,
  setProjectData,
} from "../../store/slices/project-slice";
import "./Dashboardcharttodo.css";
import { useDispatch } from "react-redux";
import TaskReporSkeleton from "../dashboard/Common/TaskReporSkeleton";
import { useGetProjects } from "../../Services/RQDashboardService";
const TaskReportdash = ({
  priorityselect,
  linechartprojectdata,
  setprojectidcirculargraph,
  setpriorityselect,
  projectidcirculargraph,
  assigneedata,
  charttype,
  setProjectIds,
  setalltaskchartrefresh,
  setprioritychartrefresh,
  projectdata,
}: any) => {
  const user = useSelector(userData);
  const dispatch = useDispatch();

  // const [priorityfilter, setpriorityfilter] = React.useState<any>(null);
  const { isLoading} = useGetProjects();
  const [priorityfilter, setpriorityfilter] = React.useState<any>(null);
  const project = useSelector(projectData);
  const status = {
    Backlog: 0,
    Todo: 0,
    Needtodiscuss: 0,
    Inprogress: 0,
    Developed: 0,
    Uat: 0,
    Testing: 0,
    Done: 0,
  };
  // console.log(project?.id, "projectprojectprojectproject");
  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  let high = 0;
  let low = 0;
  let medium = 0;
  let total = 0;
  let totalstatus = 0;

  const totalstatuswisecount = (priority: any) => {
    if (assigneedata) {
      assigneedata.map((value: any) => {
        if (value.status === 0) status.Backlog += value.totalCount;
        else if (value.status === 1) status.Todo += value.totalCount;
        else if (value.status === 2) status.Needtodiscuss += value.totalCount;
        else if (value.status === 3) status.Inprogress += value.totalCount;
        else if (value.status === 7) status.Developed += value.totalCount;
        else if (value.status === 4) status.Uat += value.totalCount;
        else if (value.status === 5) status.Testing += value.totalCount;
        else if (value.status === 6) status.Done += value.totalCount;
        totalstatus += value.totalCount;
      });
    }
  };

  if (assigneedata) {
    assigneedata.map((value: any) => {
      if (value.priority === 0) low += value.totalCount;
      else if (value.priority === 1) medium += value.totalCount;
      else if (value.priority === 2) high += value.totalCount;
      total += value.totalCount;
    });
  }

  // if (user?.id === null || user?.id === undefined) {
  //   localStorage.setItem("projectid", linechartprojectdata[0].value);
  //   localStorage.setItem("name", linechartprojectdata[0].label);
  // }

  // const highpercentage = (high / total) * 100;
  // const mediumpercentage = (medium / total) * 100;
  // const lowpercentage = (low / total) * 100;

  totalstatuswisecount(priorityselect);

  const data2 = [
    { value: status.Backlog, name: "Backlog", itemStyle: { color: "#7690ff" } },
    { value: status.Todo, name: "ToDo", itemStyle: { color: "#8DD6FF" } },
    {
      value: status.Needtodiscuss,
      name: "Need to Discuss",
      itemStyle: { color: "#5FAFFF" },
    },
    {
      value: status.Inprogress,
      name: "In Progress",
      itemStyle: { color: "#27B7FF" },
    },
    {
      value: status.Developed,
      name: "Developed",
      itemStyle: { color: "#ff76af" },
    },
    { value: status.Uat, name: "UAT", itemStyle: { color: "#D3A8FD" } },
    { value: status.Testing, name: "Testing", itemStyle: { color: "#FFB579" } },
    { value: status.Done, name: "Done", itemStyle: { color: "#4FD190" } },
  ];

  const data3 = [
    { value: 0, name: "Backlog", itemStyle: { color: "#7690ff" } },
    { value: 0, name: "ToDo", itemStyle: { color: "#8DD6FF" } },
    { value: 0, name: "Need to Discuss", itemStyle: { color: "#5FAFFF" } },
    { value: 0, name: "In Progress", itemStyle: { color: "#27B7FF" } },
    { value: 0, name: "UAT", itemStyle: { color: "#D3A8FD" } },
    { value: 0, name: "Testing", itemStyle: { color: "#FFB579" } },
    { value: 0, name: "Done", itemStyle: { color: "#4FD190" } },
  ];

  const checkdatatype = () => {
    if (charttype === "tasks") {
      return data2;
    }
    return data3;
  };

  let option = {
    calculable: true,
    colorBy: "data",
    padding: 5,
    tooltip: { trigger: "item" },
    legend: {
      top: "76%",
      left: "right",
      icon: "circle",
      textStyle: { fontSize: 9.8 },
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        bottom: 19,
        top: -30,
        radius: ["40%", "66%"],
        avoidLabelOverlap: false,
        label: { show: false, position: "center" },
        emphasis: { label: { show: true, fontSize: 12 } },
        labelLine: { show: false },
        data: checkdatatype(),
      },
    ],
  };
  let option2 = {
    calculable: true,
    colorBy: "data",
    tooltip: {
      trigger: "item",
    },
    title: {
      textStyle: {
        fontFamily: "Open Sans",
      },
      top: "38%",
      left: "center",
    },
    legend: {
      top: "76%",
      left: "right",
      icon: "circle",
      textStyle: {
        fontSize: 10,
      },
    },

    series: [
      {
        name: "Access From",
        type: "pie",
        bottom: 19,
        top: -30,
        radius: ["40%", "66%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          color: "white",
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 12,
          },
        },
        labelLine: {
          show: false,
        },
        data: data3,
      },
    ],
  };
  // const [addwidth, setaddwidth] = React.useState(window.innerWidth);

  const nodatajsx = () => {
    return assigneedata && assigneedata?.length > 0 ? (
      <Barchartdashboard option={option} />
    ) : (
      <Barchartdashboard option={option2} />
    );
  };

  // window.addEventListener("resize", () => {
  //   setaddwidth(window.innerWidth);
  // });
  return (
    <>
    {isLoading ? (
      <TaskReporSkeleton/>
    ) : (
    <Card
      title={
        <Typography.Title
          level={4}
          style={{
            // paddingLeft: "22px",
            color: "rgb(54, 70, 99)",
            fontFamily: "Open Sans",
            fontWeight: 600,
            fontSize: "16px",
          }}
        >
          Task Report
        </Typography.Title>
      }
      className="cardtaskbord"
    >
      <Row gutter={[20, 26]}>
        <Col span={12} md={12}>
          {nodatajsx()}
        </Col>
        <Col
          className="pr-15"
          span={12}
          lg={11}
          md={10}
          style={
            {
              // display: "inline-grid",
              //  marginLeft: `${addwidth > 1024 ? "0px" : "0px"}`,
            }
          }
        >
     
          <Select
            className="mt-3 selecttaskbord"
            options={linechartprojectdata}
            value={linechartprojectdata?.length> 0 ? (project?.id || linechartprojectdata[0]): ''}
            placeholder="Choose project"
            onChange={(item) => {
              setProjectIds(item);
              setprojectidcirculargraph(item);
              const selected = linechartprojectdata.find(
                (p: any) => p.value === item
              );
              if (selected) {
                dispatch(
                  setProjectData({
                    id: String(item),
                    name: selected.label,
                  })
                );
              }
            }}
            style={{
              fontSize: 14,
              marginBottom: 16,
            }}
            showSearch
            onSearch={onSearch}
            optionFilterProp="label"
          />
          <Card
            style={{
              cursor: "pointer",
              // paddingLeft: "20px",
              height: "75px",
              //   width: "90%",
              // marginRight: "28px",
              borderRadius: "4px",
              border: "none",
            }}
          // onClick={() => {
          //   if (priorityborderstate === "high") {
          //     setpriorityborderstate("");
          //     setpriorityselect(null);
          //     setpriorityfilter(2);
          //   } else {
          //     setpriorityborderstate("high");
          //     setpriorityselect(2);
          //     setpriorityfilter(null);
          //   }
          // }}
          >
            <Row align="middle">
              <Col className="pr-2">
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F3F8FE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    style={{ height: "15px" }}
                    src={process.env.PUBLIC_URL + "/icons(2).svg"}
                    alt=""
                  />
                </div>
              </Col>
              <Col flex="auto">
                <p
                  style={{
                    marginBottom: "0px",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    color: "#364663",
                  }}
                >
                  High
                </p>
                <ProgressLine
                  visualParts={[
                    {
                      percentage: `${high ? (high / total) * 100 : "0"}%`,
                      color: "#FF9292",
                    },
                  ]}
                //   style={{ width: "180px", marginTop: 6, marginLeft: "10px" }}
                />
              </Col>
              <Col>
                <span
                  style={{
                    marginLeft: "-16px",
                    fontSize: "14px",
                    color: "#364663",
                    width: "15px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-7px",
                  }}
                >
                  {high}
                </span>
              </Col>
            </Row>
          </Card>
          <Card
            style={{
              cursor: "pointer",
              // paddingLeft: "20px",
              // width: "90%",
              height: "75px",
              // marginRight: "28px",
              borderRadius: "4px",
              border: "none",
            }}
          // onClick={() => {
          //   if (priorityborderstate === "medium") {
          //     setpriorityborderstate("");
          //     setpriorityselect(null);
          //     setpriorityfilter(1);
          //   } else {
          //     setpriorityborderstate("medium");
          //     setpriorityselect(1);
          //     setpriorityfilter(null);
          //   }
          // }}
          >
            <Row align="middle">
              <Col className="pr-2">
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F3F8FE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    style={{ height: "15px" }}
                    src={process.env.PUBLIC_URL + "/icons(3).svg"}
                    alt=""
                  />
                </div>
              </Col>
              <Col flex="auto">
                <p
                  style={{
                    marginBottom: "0px",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    color: "#364663",
                  }}
                >
                  Medium
                </p>
                <ProgressLine
                  visualParts={[
                    {
                      percentage: `${medium ? (medium / total) * 100 : "0"}%`,
                      color: "#F6C257",
                    },
                  ]}
                //   style={{ width: "180px", marginTop: 6, marginLeft: "10px" }}
                />
              </Col>
              <Col>
                <span
                  style={{
                    marginLeft: "-16px",
                    fontSize: "14px",
                    color: "#364663",
                    width: "15px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-7px",
                  }}
                >
                  {medium}
                </span>
              </Col>
            </Row>
          </Card>
          <Card
            style={{
              cursor: "pointer",
              // paddingLeft: "20px",
              // width: "90%",
              height: "75px",
              // marginRight: "28px"
              borderRadius: "4px",
              border: "none",
            }}
          // onClick={() => {
          //   if (priorityborderstate === "low") {
          //     setpriorityborderstate("");
          //     setpriorityselect(null);
          //     setpriorityfilter(0);
          //   } else {
          //     setpriorityborderstate("low");
          //     setpriorityselect(0);
          //     setpriorityfilter(null);
          //   }
          // }}
          >
            <Row align="middle">
              <Col className="pr-2">
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    backgroundColor: "#F3F8FE",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    style={{ height: "15px" }}
                    src={process.env.PUBLIC_URL + "/icons(1).svg"}
                    alt=""
                  />
                </div>
              </Col>
              <Col flex="auto">
                <p
                  style={{
                    marginBottom: "0px",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    color: "#364663",
                  }}
                >
                  Low
                </p>

                <ProgressLine
                  visualParts={[
                    {
                      percentage: `${low ? (low / total) * 100 : "0"}%`,
                      color: "#A0D0FF",
                    },
                  ]}
                />
              </Col>
              <Col>
                <span
                  style={{
                    marginLeft: "-16px",
                    fontSize: "14px",
                    color: "#364663",
                    width: "15px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-7px",
                  }}
                >
                  {low}
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Card>
                )}
    </>
  );
};

export default TaskReportdash;
