
// import Modal, {
//     ModalBody,
//     ModalFooter,
//     ModalHeader,
//     ModalTitle,
//     ModalTransition,
// } from '@atlaskit/modal-dialog';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import LocalStorage from '../../../Services/Localstorage';
import { Button, Modal } from 'antd';


const AllAssigneeList = ({ assigneeList, isOpen1, closeModal1 }: any) => {
    const navigate = useNavigate()
    const [pageopen, setpageopen] = useState<string>(LocalStorage.getItem("page", "string") || "mywork")
    const handleclick = async (pagename: string, item: any) => {
        setpageopen(pagename)
        /* await LocalStorage.setItem("page", pagename);*/
        console.log(item, "itemitem")
        if (pagename === "report") {
            navigate("/report/reporting", { state: { userid: item.id, email: item.email, name: item.name, thumb: item.imageThumb, ...item } })
        }

    }

    // const createHead = (withWidth: boolean) => {
    //     return {
    //         cells: [
    //             {
    //                 key: "Profile",
    //                 content: "Profile",
    //                 width: 10
    //             },
    //             {
    //                 key: "Name",
    //                 content: "Name",
    //                 width: 25
    //             },
    //             {
    //                 key:-"Email",
    //                 content: "Email",
    //                 width: 25
    //             }

    //         ],
    //     };
    // };

    // const head = createHead(true);

    return (
        <>
            {

                isOpen1 &&
                <Modal onCancel={closeModal1} width="large">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e8ecf3",
                        paddingLeft: 10,
                        fontSize: 14,
                        paddingTop: 5,
                        paddingRight: 10,
                        justifyContent: "space-between"
                    }}>
                        <p style={{ fontSize: 14, fontWeight: 600 }}>Assignee list</p>
                        <div>
                            <Button onClick={() => {

                                closeModal1()

                            }}>
                                {/* <CrossIcon
                                    label="Close Modal"
                                    primaryColor={token('color.text.subtle', N500)}
                                /> */}
                            </Button>
                        </div>
                    </div>


                    <div>
                        {
                            assigneeList.length > 0 ?
                                <div style={{
                                    height: "58vh",
                                    overflowX: "scroll",
                                    padding: "25px"
                                }}>
                                    {/* <DynamicTable
                                        head={head}
                                        rows={assigneeList.map((data: any, index: number) => ({
                                            key: `row-${index}-${data.id}`,
                                            isHighlighted: false,
                                            cells: [
                                                {
                                                    content: <Avatar size="small" src={data.imageThumb} />
                                                },
                                                {
                                                    content: <p onClick={() => {
                                                        handleclick("report", data)
                                                    }} style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans", cursor: "pointer" }}>{data.name}</p>
                                                },
                                                {
                                                    content: <p style={{ margin: 0, fontSize: "14px", fontFamily: "Open Sans" }}>{data.email}</p>
                                                },
                                            ],
                                        }))}
                                        defaultPage={1}
                                        loadingSpinnerSize="large"
                                    /> */}
                                </div>
                                :
                                <div style={{ marginBottom: "10%" }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "10%"
                                    }}>
                                        <img src={process.env.PUBLIC_URL + "/noTask.svg"} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <h3 style={{ margin: 0, fontFamily: "Open Sans", fontSize: 20 }}>No task yet</h3>
                                        <p style={{ marginTop: 4, fontFamily: "Open Sans", fontSize: 14 }}>You have no available task to display</p>
                                    </div>
                                </div>
                        }
                    </div>



                </Modal>

            }
        </>
    )
}

export default AllAssigneeList