import { useEffect, useState } from "react";
import BooksService from "../../../Services/Books";
import AdminMilestoneData from "../../AdminComponents/AdminMilestoneData/AdminMilestoneData";
import "./AdminDashboard.css"
import LoginUser from "../../AdminComponents/LoginUser/LoginUser";
import NotLoginUser from "../../AdminComponents/NotLoginUser/NotLoginUser";
import TotalDataTable from "../../AdminComponents/TotalDataTable/TotalDataTable";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import type { TabsProps } from 'antd';
import AllProject from "../../AdminComponents/AllProjectData/AllProject";
import HTTPSCalls from "../../../Services/HTTPCalls";
import AdminChart from "../../AdminComponents/AdminChart/AdminChart";
import TaskPercentageChart from "../../AdminComponents/TaskPercentageChart/TaskPercentageChart";
// import { useApp } from "../../../Contexts/AppContext";
import UserTaskCountChart from "../../AdminComponents/UserTaskCountChart/UserTaskCountChart";
import { Select, Spin, Tabs } from "antd";
import { useSelector } from "react-redux";
import { userData } from "../../../store/slices/user-slice";

export enum Duration {
    Weekly,
    Monthly,
    Yearly
}


const AdminDashboard = () => {
    const [logindata, setlogindata] = useState<any>(null)
    const [notLoginData, setNotLoginData] = useState<any>(null)
    const [pageroute, setPageRoute] = useState("")
    const [projectidcirculargraph, setprojectidcirculargraph] = useState<any>(localStorage.getItem("projectid") ? localStorage.getItem("projectid") : null)
    const [assigneechartdata, setassigneechartdata] = useState<any>(null)
    const [useridcirculargraph, setuseridcirculargraph] = useState<any>([])
    const [projectdata, setprojectdata] = useState([])
    const [pendingTaskData, setPendingTaskData] = useState([])
    const [allMilestone, setAllMilestone] = useState([])
    const [getExpireMilestone, setgetExpireMilestone] = useState([])
    const [defaultCount, setdefaultCount] = useState<Duration>(Duration.Weekly)
    const [getExpireTasks, setgetExpireTasks] = useState<any>([])
    const [userDatas, setUserDatas] = useState<any>([])
    const [loader, setLoader] = useState(false)
    const [loader2, setLoader2] = useState(false)
    const [loader3, setLoader3] = useState(false)
    const navigate = useNavigate()
    const [adminPage, setadminPage] = useState("adminDone")
    const [projectDrowpdown, setProjectDropdown] = useState<any>([]);
    const user = useSelector(userData);

    //for all the chart data
    const [chartData, setChartData] = useState([])
    const [chartData1, setChartData1] = useState([])
    const [chartData2, setChartData2] = useState([])
    const [projectid, setProjectId] = useState<any>(user.id ? user.id : null)
    const [highlightedButton, setHighlightedButton] = useState(null);

    console.log(user.id, "useruser");


    const fetchProjects = async () => {
        try {
            const res = await BooksService.getProjectByUser(user?.id);
            let userdatatype: any = [];
            res.result.map((item: any) => {
                userdatatype.push({
                    label: item.name,
                    value: item.id,
                    ...item,
                });
            });
            setProjectDropdown([...userdatatype]);
        } catch (err) { }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleButtonClick = (button: any) => {
        setHighlightedButton(button);
        localStorage.setItem('highlightedButton', button);
    };

    const FatchUserByProject = async () => {
        try {
            let res: any = await BooksService.getUserProjectWise(projectid)
            setChartData1(res.result.data)
        } catch (err) {

        }
    }

    const FetchTaskByProject = async () => {
        try {
            let res: any = await BooksService.getTaskProjectWise(projectid)
            setChartData2(res.result)
        } catch (err) {

        }
    }


    const FatchPercentageByTask = async () => {
        try {
            let res: any = await BooksService?.getTaskPercentageProjectWise(projectid)
            setChartData(res?.result)
        } catch (err) {

        }
    }

    const transformedData = chartData ? Object?.entries(chartData)?.map(([name, value]) => ({
        value,
        name,
    })) : [];

    useEffect(() => {
        FatchPercentageByTask()
        FatchUserByProject()
        FetchTaskByProject()
    }, [projectid])


    //For those Who are logged in today
    const getLoginData = async (isTodayDateFilter: boolean, isLoginNot: boolean) => {
        try {
            const res = await BooksService.getNotLogedData(isTodayDateFilter, isLoginNot)
            console.log(res.result, "sdsdsdddddddd")
            setlogindata(res.result)
        } catch (err) {

        }
    }

    //For those Who are not logged in today
    const getNotLoginData = async (isTodayDateFilter: boolean, isLoginNot: boolean) => {
        try {
            const res = await BooksService.getNotLogedData(isTodayDateFilter, isLoginNot)
            setNotLoginData(res.result)
        } catch (err) {

        }
    }


    //List of panding task for the Card
    const getPendingList = async () => {
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getAllPending)
        setPendingTaskData(response.result.items)
    }

    //List of project for the table
    const getProjectList = async () => {
        setLoader(true)
        let response = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.admin.getAll + "?", { durationType: defaultCount })
        if (response.result)
            setprojectdata(response.result)
        setLoader(false)
    }

    //List of User for the table
    const getUserList = async () => {
        setLoader2(true)
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.admin.gettAllUsers + "?", { durationType: defaultCount })
        if (res.result)
            setUserDatas(res.result)
        setLoader2(false)
    }

    //List of Milestone for the Card
    const getAllMilestone = async () => {
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.milestone.getAll)
        setAllMilestone(res.result)
    }


    //List of all Expired milestone for the Card
    const getAllExpireMilestone = async () => {
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.admin.getAllDueMIlestone)
        setgetExpireMilestone(res.result)
    }

    //List of Expired Tasks for the Card
    const getAllExpireTasks = async () => {
        setLoader3(true)
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.admin.getAllDueTasks)
        setgetExpireTasks(res.result)
        setLoader3(false)
    }

    const getUserData = async () => {

        try {
            let res = await BooksService.getAdminDashbordChart()
            console.log(res.result, "res.result")
            setprojectidcirculargraph(res.result)
            let userdatatype: any = []
            res.result.map((item: any) => {
                userdatatype.push({ label: item.name, value: item.id })
                console.log(item.id, "item.id")
                console.log(item.name, "item.name")
            })
            console.log(userdatatype, "67")
            setuseridcirculargraph(userdatatype)
            BooksService.getDashboardByProject(projectidcirculargraph === null ? userdatatype[0].value : projectidcirculargraph).then((res: any) => {
                if (res.result.length === 0) {
                    setassigneechartdata([])
                }
                else {
                    setassigneechartdata(res.result.items)
                }
            })

        } catch (err) {

        }
    }

    const transformdatas = chartData2?.map((item: any) => ({
        value: item?.taskcount,
        name: item?.name
    }))

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Active User',
            children: <div style={{ width: "100%" }}>
                <LoginUser logindata={logindata} />
            </div>,
        },
        {
            key: '2',
            label: 'InActive User',
            children: <div style={{ width: "100%" }}>
                <NotLoginUser notLoginData={notLoginData} />
            </div>,
        }
    ];

    useEffect(() => {
        getAllMilestone()
        getUserData()
        getPendingList()
    }, [])

    useEffect(() => {
        getLoginData(true, false)
        getNotLoginData(true, true)
        getAllExpireMilestone()
        getAllExpireTasks()
    }, [])

    useEffect(() => {
        getProjectList()

    }, [defaultCount])

    useEffect(() => {
        getUserList()
    }, [defaultCount])

    return (
        <>
            <div style={{
                height: "calc(100vh - 62px)",
                overflow: "auto"
            }}>
                <div style={{ display: "flex", padding: "1px", backgroundColor: "#f3f4fa", margin: "6px", borderRadius: "10px" }}>
                    <Link to="/admin/dashboard" onClick={() => {
                        handleButtonClick('button1')
                        setPageRoute("admin")
                    }} style={{ paddingRight: "30px", borderBottom: highlightedButton === 'button1' ? '3px solid #377DFF' : 'none', cursor: "pointer", textDecoration: "none" }}>
                        <p style={{ color: highlightedButton === 'button1' ? '#0049B0' : '#43526D', fontWeight: 500, paddingLeft: "10px" }}>Admin</p>
                    </Link>

                    < Link to="/dashboard" onClick={() => {
                        handleButtonClick('button2')
                        setPageRoute("user")
                    }} style={{ paddingLeft: "30px", fontWeight: 500, borderBottom: highlightedButton === 'button2' ? '2px solid ' : 'none', cursor: "pointer", textDecoration: "none" }}>
                        <p style={{ color: highlightedButton === 'button2' ? '#0049B0' : '#43526D' }}>User</p>
                    </Link>
                </div>
                {
                    logindata != null && notLoginData != null ?
                        <div>
                            <div className="coverlayout" >
                                <div className="mainlayout">
                                    <div>
                                        <AllProject allMilestone={allMilestone} pendingTaskData={pendingTaskData} />
                                    </div>
                                    <div className="adminDashboardmain" >
                                        <div className="TotalDataTable" >
                                            <TotalDataTable loader2={loader2} userDatas={userDatas} loader={loader} setdefaultCount={setdefaultCount} projectdata={projectdata} />
                                        </div>
                                        <div className="allChartData">

                                            <div style={{ boxShadow: "rgba(146, 166, 195, 0.58) 0px 0px 10px 0px", width: "97.8%", marginLeft: "15px", borderRadius: "5px", marginTop: "20px" }}>
                                                <div style={{
                                                    width: "20%",
                                                    marginLeft: 52,
                                                    paddingTop: 15
                                                }}>
                                                    <Select
                                                        className="checkbox-select"
                                                        options={projectDrowpdown}
                                                        defaultValue={user}
                                                        onChange={(item: any) => {
                                                            console.log(item);

                                                            setProjectId(item)
                                                        }}
                                                        style={{ width: "200px" }}
                                                        placeholder="Choose project"

                                                    />
                                                </div>
                                                <div className="allChartLayout" >
                                                    <AdminChart chartData1={chartData1} adminPage={adminPage} />
                                                    <TaskPercentageChart page="" transformedData={transformedData} setProjectId={setProjectId} projectid={projectid} />
                                                    <UserTaskCountChart
                                                        setProjectId={setProjectId}
                                                        projectid={projectid}
                                                        page=""
                                                        transformdatas={transformdatas}
                                                    />
                                                </div>

                                            </div>
                                            <div className="tabelsLayout" >

                                                <div className="activeUsers" >

                                                    <Tabs defaultActiveKey="1" items={items} />

                                                </div>

                                                <div className="milestoneExpire" >
                                                    <h4 style={{ marginTop: "0px", marginBottom: "6px", fontSize: "16px", color: "#3f5bd3" }}>MileStone Expire</h4>
                                                    <div style={{ height: "250px", overflowX: "scroll" }}>
                                                        <AdminMilestoneData getExpireMilestone={getExpireMilestone} />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>


                        </div> :
                        <div style={{
                            display: "flex",
                            margin: "20% auto",
                            justifyContent: "center"
                        }}>
                            <Spin />
                        </div>
                }
            </div>

        </>
    )
}

export default AdminDashboard;