import { useEffect, useState } from "react";
import AdminMainChart from "../AdminMainChart/AdminMainChart";
import './AdminChart.css';

const AdminChart = ({ useridcirculargraph, setprojectidcirculargraph, chartData1 }:any) => {
    let option = {
        color: ['#27B7FF', '#7690FF', '#8DD6FF', '#5FAFFF', '#FF76AF', '#D3A8FD', '#FFB579','#4FD190'],
        tooltip: {
            trigger: 'item',
            formatter: '{b}'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: chartData1,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
   
     /*let option = {
        tooltip: {
             trigger: 'item',
             formatter: '{b}'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['30%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: chartData1
            }
        ]
    };*/

    /*let option = {
      
        tooltip: {
            trigger: 'item',
            formatter: '{b}'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: chartData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
*/
    const nodatajsx = () => {
            return <AdminMainChart option={option} />
    }

    return (
        <>
            <div className="projectwiseuser">
                <h4 style={{ marginTop: "0px", marginBottom: "6px", fontSize: "16px", color: "#3f5bd3", paddingLeft: "20px", paddingTop: "10px" }}>Project wise User</h4>
                {
                    nodatajsx()
                }

            </div>
        </>
        )
}

export default AdminChart;