import { Avatar, Space, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";


interface IAssigneeAvatarProps {
  data: any[];
  maxCount?: number;
}

const renderAvatar = (item: any, maxCount: number) => (
  <Tooltip title={item.name}>
    <Avatar
      src={
        item.thumb ? (
          <img style={{ objectFit: "fill" }} src={item.thumb} alt="img" />
        ) : null
      }
      icon={!item.thumb && <UserOutlined />}
    />
  </Tooltip>
);
   
export const AssigneeAvatar = ({ data, maxCount = 2 ,}: IAssigneeAvatarProps) => (
  <Space size="small">
    {maxCount ? (
      <Tooltip title="Assignees" placement="bottom">
        <Avatar.Group maxCount={maxCount} size={25}>
          {data.map((item: any, index: number) => (
            <div
              key={index}
              style={{ marginRight: 5 }}
              // onClick={() => navigate("/task/" + item.uKey)}
            >
              {renderAvatar(item, maxCount)}
            </div>
          ))}
        </Avatar.Group>
      </Tooltip>
    ) : (
      data.map((item: any, index: number) => (
        <div key={index} style={{ marginRight: 5 }}>
          {renderAvatar(item, maxCount)}
        </div>
      ))
    )}
  </Space>
);

export const AssigneeAvatarSmall = ({
  data,
  maxCount = 2,
}: IAssigneeAvatarProps) => (
  <Space size="small">
    {maxCount ? (
      <Tooltip title="Assignees" placement="bottom">
        <Avatar.Group size="small" maxCount={maxCount}>
          {data.map((item: any, index: number) => (
            <div
              key={index}
              style={{ marginRight: 5 }}
            >
              {renderAvatar(item, maxCount)}
            </div>
          ))}
        </Avatar.Group>
      </Tooltip>
    ) : (
      data.map((item: any, index: number) => (
        <div key={index} style={{ marginRight: 5 }}>
          {renderAvatar(item, maxCount)}
        </div>
      ))
    )}
  </Space>
);
