// import { Button, Col, Form, Input, Row, Select, TreeSelect } from "antd";
// import React, { useEffect, useState } from "react";
// import { statusconvertor } from "../../utils/Util";
// import { userType } from "../../constants/commonConstants";
// import HTTPSCalls from "../../Services/HTTPCalls";
// import { useGetProjects } from "../../Services/RQDashboardService";
// import { useSelector } from "react-redux";
// import { userData } from "../../store/slices/user-slice";

// const IssueReportFilter = ({ ReporterCall, setFormData }: any) => {
//   const user = useSelector(userData);
//   const {
//     // isLoading,
//     data: projectData,
//     // isFetching,
//   } = useGetProjects();
//   const [users, setUsers] = useState<any>([]);
//   const getUsers = async () => {
//     let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", {
//       isAll: true,
//     });


//     if (res.result)
//       setUsers(
//         res.result?.map((item: any) => ({
//           label: item?.name,
//           value: item?.id,
//           ...item,
//         }))
//       );
//   };
//   const data = projectData?.result?.map((item: any) => ({
//     label: item?.name,
//     value: item?.id,
//   }));

//   const onSearch = (value: string) => {
//     // console.log("search:", value);
//   };

//    console.log(data, "projectDataprojectDataprojectDataprojectData");
//   useEffect(() => {
//     getUsers();
//   }, []);
//   return (
//     <div>
//       <div>
//         <div
//           className="pt-5 pb-5 pl-8 "
//           // style={{ backgroundColor: "#f3f4fa" }}
//         >
//           <Row justify={"space-between"}>
//             <Col>
//               <Form layout="inline">
//                 <Form.Item
//                   name="project"
//                   rules={[{ required: true, message: "Please enter project" }]}
//                 >
//                   <Select
//                     placeholder="Select Project"
//                     value={data?.id}
//                     defaultValue={data?.id}
//                     //  className="handsearchh"
//                     style={{
//                       width: "180px",
//                       // height: 35,
//                     }}
//                     maxTagCount={2}
//                     options={data}
//                     mode="multiple"
//                     onChange={(value: any) => {
//                       setFormData((prev: any) => ({
//                         ...prev,
//                         project: value.map((item: any) => item),
//                       }));
//                     }}
//                     optionFilterProp="label"
//                     onSearch={onSearch}
//                   ></Select>
//                 </Form.Item>

//                 <Form.Item name="tstatus">
//                   <TreeSelect
//                     // className="handsearchh"
//                     placeholder="Select status"
//                     treeData={[
//                       { title: statusconvertor(1), value: 1 },
//                       { title: statusconvertor(2), value: 2 },
//                       { title: statusconvertor(3), value: 3 },
//                       { title: statusconvertor(7), value: 7 },
//                       { title: statusconvertor(4), value: 4 },
//                       { title: statusconvertor(5), value: 5 },
//                       { title: statusconvertor(6), value: 6 },
//                     ]}
//                     defaultValue={[
//                       { title: statusconvertor(1), value: 1 },
//                       { title: statusconvertor(2), value: 2 },
//                       { title: statusconvertor(3), value: 3 },
//                       { title: statusconvertor(7), value: 7 },
//                       { title: statusconvertor(4), value: 4 },
//                       { title: statusconvertor(5), value: 5 },
//                     ]}
//                     onChange={(value: any) => {
//                       setFormData((prev: any) => ({
//                         ...prev,
//                         status: value.map((item: any) => item),
//                       }));
//                     }}
//                     treeCheckable
//                     maxTagCount={1}
//                     style={{
//                       width: "180px",
//                       // height: 35,
//                     }}
//                     showSearch
//                     onSearch={onSearch}
//                     treeNodeFilterProp="title"
//                   />
//                 </Form.Item>
//                 <Form.Item name="search">
//                   <Input
//                     // className="handsearch"
//                     onChange={(e: any) => {
//                       setFormData((prev: any) => ({
//                         ...prev,
//                         SearchText: e?.target?.value,
//                       }));
//                     }}
//                     placeholder="Search"
//                     style={{ width: 180 }}
//                   />
//                 </Form.Item>

//                 <Form.Item>
//                   <Select
//                     // className="handsearchh"
//                     placeholder="Select user type"
//                     style={{ width: 180 }}
//                     onChange={(value: any) => {
//                       setFormData((prev: any) => ({
//                         ...prev,
//                         userFilter: { userType: value, id: prev.userFilter.id },
//                       }));
//                     }}
//                     options={[
//                       { label: "All", value: userType.All },
//                       { label: "Assignee", value: userType.Assignee },
//                       { label: "AssignedBy", value: userType.AssignedBy },
//                       { label: "Reporter", value: userType.Reporter },
//                     ]}
//                     showSearch
//                     optionFilterProp="label"
//                     onSearch={onSearch}
//                   />
//                 </Form.Item>

//                 <Form.Item>
//                   <Select
//                     // className="handsearchh"
//                     placeholder="Select user"
//                     style={{ width: 180 }}
//                     onChange={(value: any) => {
//                       setFormData((prev: any) => ({
//                         ...prev,
//                         userFilter: {
//                           id: value,
//                           userType: prev.userFilter.userType,
//                         },
//                       }));
//                     }}
//                     options={[...users]}
//                     showSearch
//                     optionFilterProp="label"
//                     onSearch={onSearch}
//                   />
//                 </Form.Item>

//                 <Form.Item>
//                   <Select
//                     // className="handsearchh"
//                     defaultValue={{ label: "All", value: -1 }}
//                     style={{ width: 180 }}
//                     onChange={(item: any) => {
//                       setFormData((prev: any) => ({ ...prev, priority: item }));
//                     }}
//                     options={[
//                       { label: "All", value: -1 },
//                       { label: "High", value: 2 },
//                       { label: "Medium", value: 1 },
//                       { label: "Low", value: 0 },
//                     ]}
//                     showSearch
//                     optionFilterProp="label"
//                     onSearch={onSearch}
//                   />
//                 </Form.Item>

//                 <Form.Item>
//                   <Button
//                     style={{ borderRadius: "3px" }}
//                     onClick={(e: any) => {
//                       ReporterCall(true);
//                     }}
//                     type="primary"
//                     htmlType="submit"
//                   >
//                     Search
//                   </Button>
//                 </Form.Item>
//               </Form>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default IssueReportFilter;
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  Select,
  TreeSelect,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { statusconvertor } from "../../utils/Util";
import { userType } from "../../constants/commonConstants";
import HTTPSCalls from "../../Services/HTTPCalls";
import { useGetProjects } from "../../Services/RQDashboardService";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import { values } from "lodash";

const IssueReportFilter = ({ ReporterCall, setFormData }: any) => {
  const [form] = Form.useForm(); 
  const user = useSelector(userData);
  const { data: projectData } = useGetProjects();
  const [users, setUsers] = useState<any>([]);

  const getUsers = async () => {
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", {
      isAll: true,
    });
    if (res.result)
      setUsers(
        res.result?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
          ...item,
        }))
      );
  };

  const data = projectData?.result?.map((item: any) => ({
    label: item?.name,
    value: item?.id,
  }));

  const onSearch = (value: string) => {
    // Handle search functionality
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleSearchClick = () => {
    form
      .validateFields()
      .then((values) => {
        ReporterCall(true);
      })
      .catch((errorInfo) => {
        notification.error({
          message: "Select Project",
          description: "Please fill in the required fields correctly.",
          placement: "bottomLeft",
        });
      });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextPlaceholder: "#95a5a6",
            fontSize: 14,
            colorPrimaryHover: "none",
            // controlHeight: 32,
            fontSizeIcon: 10,
            // borderRadius: 4,
            paddingSM: 4,
            paddingXS: 4,
            paddingXXS: 4,
            lineWidth: 1,
            borderRadius: 4,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadiusXS: 4,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 27,
            controlHeightLG: 27,

            // fontSize: 14,
            // controlHeight: 28,
            // borderRadius: 1,
            algorithm: true,
            fontWeightStrong: 600,
          },
          DatePicker: {
            controlHeight: 27,
            controlHeightLG: 27,
          },
          Input: {
            controlHeight: 27,
            controlHeightLG: 27,
          },
        },
      }}
    >
      <div>
        <div>
          <div className=" pb-2 pl-4">
            <Row justify={"space-between"}>
              <Col>
                <Form layout="inline" form={form}>
                  <Form.Item
                    name="project"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Select
                      placeholder="Select Project"
                      style={{ width: "180px" }}
                      maxTagCount={1}
                      options={data}
                      mode="multiple"
                      onChange={(value: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          project: value.map((item: any) => item),
                        }));
                      }}
                      optionFilterProp="label"
                      onSearch={onSearch}
                    />
                  </Form.Item>

                  <Form.Item name="tstatus">
                    <TreeSelect
                      placeholder="Select status"
                      treeData={[
                        { title: statusconvertor(1), value: 1 },
                        { title: statusconvertor(2), value: 2 },
                        { title: statusconvertor(3), value: 3 },
                        { title: statusconvertor(7), value: 7 },
                        { title: statusconvertor(4), value: 4 },
                        { title: statusconvertor(5), value: 5 },
                        { title: statusconvertor(6), value: 6 },
                      ]}
                      defaultValue={[
                        { title: statusconvertor(1), value: 1 },
                        { title: statusconvertor(2), value: 2 },
                        { title: statusconvertor(3), value: 3 },
                        { title: statusconvertor(7), value: 7 },
                        { title: statusconvertor(4), value: 4 },
                        { title: statusconvertor(5), value: 5 },
                      ]}
                      onChange={(value: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          status: value.map((item: any) => item),
                        }));
                      }}
                      treeCheckable
                      maxTagCount={1}
                      style={{ width: "180px" }}
                      showSearch
                      onSearch={onSearch}
                      treeNodeFilterProp="title"
                    />
                  </Form.Item>

                  <Form.Item name="search">
                    <Input
                      onChange={(e: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          SearchText: e?.target?.value,
                        }));
                      }}
                      placeholder="Search"
                      style={{ width: 180 }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Select
                      placeholder="Select user type"
                      style={{ width: 180 }}
                      onChange={(value: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          userFilter: {
                            userType: value,
                            id: prev.userFilter.id,
                          },
                        }));
                      }}
                      options={[
                        { label: "All", value: userType.All },
                        { label: "Assignee", value: userType.Assignee },
                        { label: "AssignedBy", value: userType.AssignedBy },
                        { label: "Reporter", value: userType.Reporter },
                      ]}
                      showSearch
                      optionFilterProp="label"
                      onSearch={onSearch}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Select
                      placeholder="Select user"
                      style={{ width: 180 }}
                      onChange={(value: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          userFilter: {
                            id: value,
                            userType: prev?.userFilter?.userType,
                          },
                        }));
                      }}
                      options={[...users]}
                      showSearch
                      optionFilterProp="label"
                      onSearch={onSearch}
                      allowClear
                    
                    />
                  </Form.Item>

                  <Form.Item>
                    <Select
                      defaultValue={{ label: "All", value: -1 }}
                      style={{ width: 180 }}
                      onChange={(item: any) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          priority: item,
                        }));
                      }}
                      options={[
                        { label: "All", value: -1 },
                        { label: "High", value: 2 },
                        { label: "Medium", value: 1 },
                        { label: "Low", value: 0 },
                      ]}
                      showSearch
                      optionFilterProp="label"
                      onSearch={onSearch}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="pl-5 pr-5"
                      type="primary"
                      style={{ borderRadius: "3px" }}
                      onClick={handleSearchClick}
                      htmlType="submit"
                      size={"small"}
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default IssueReportFilter;
