import React, { ReactNode } from "react";
import { Row, Col, Upload } from "antd";

type NoDataProps = {
    message: string | ReactNode,
    instruction?: string | ReactNode,
    customImage?: string,
    shouldFitContainer?: boolean
}

const NoData = ({ message, instruction, customImage = "", shouldFitContainer = false }: NoDataProps) => {

    return (
      <div style={shouldFitContainer ? { width: "100%" } : {}}>
        <Row justify="center">
          <Col>
            <img
              src={
                customImage === ""
                ? process.env.PUBLIC_URL + "/noTask.svg"
                : customImage
                }
                alt="No Data"
                />
          </Col>
        </Row>
        <Row justify="center" style={{ textAlign: "center" }}>
          <Col>
            <h3
              style={{
                margin: 0,
                fontFamily: "Lato",
                fontSize: 20,
                color: "rgb(72 81 110)",
              }}
            >
              {message}
            </h3>
            <p
              style={{
                marginTop: 4,
                fontFamily: "Lato",
                fontSize: 14,
                color: "rgb(72 81 110)",
              }}
            >
              {instruction}
            </p>
          </Col>
        </Row>
      </div>
    );
}

export default NoData;
