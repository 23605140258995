import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Tooltip,
  notification,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Button,
  Spin,
} from "antd";
import HTTPSCalls from "../Services/HTTPCalls";
import "../common/Scrollbar.css";
import "../common/Board.css";
import ActiviyLogs from "./dashboard/ActiviyLogs/ActiviyLogs";
import Toothbar from "../components/commoncomponents/Toothbar";
import { useSelector } from "react-redux";
import {
  boardViewMode as BVM,
  projectData,
  setBoardViewMode,
} from "../store/slices/project-slice";
import { STATUS } from "../constants/commonConstants";
import DragableBoard from "../components/dragableBoard/DragableBoard";
// import Loader from "../components/commoncomponents/Loader";
import {
  AppstoreOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import SidebarDrawerbacklog from "../components/dragableBoard/SidebarDrawer";
import {
  boardRefreshState,
  refreshTaskDrawer,
} from "../store/slices/refresh-slice";
import { useDispatch } from "react-redux";
import {
  addSprintChangeLog,
  addStatusChangeLog,
  newStatusConvertor,
  statusconvertor,
} from "../utils/Util";
import { Link } from "react-router-dom";
const initialdetailstate = {
  openModaldetail: null,
  sprintdatacheckeddetail: null,
  labeldatadetail: null,
  logdatedroppabledetail: null,
  projectiddetail: null,
  randomcolordetail: null,
  titleimgdetail: null,
  labelidarraydetail: null,
  assigneeidarraydetail: null,
  initialuserdatadetail: null,
  projectimagedetail: null,
  projectnamedetail: null,
  pagedetail: null,
  isOpendetail: null,
  singletaskdetail: null,
};
const splittedpath = window.location.pathname.split("/");
const projecturlid = splittedpath[splittedpath.length - 2];
const projecturlname = splittedpath[splittedpath.length - 3];

export enum UserType {
  All,
  Assignee,
  AssignedBy,
}

const Board: React.FC = ( ) => {
  const [taskData, setTaskData] = useState<any>();
  // const user = useSelector(userData);
  const project = useSelector(projectData);
  const boardRefresh = useSelector(boardRefreshState);
  const boardViewMode = useSelector(BVM);
  const dispatch = useDispatch();
  const [projectname, setProjectName] = useState(project?.name || "");
  const [projectid, setProjectId] = useState(() => {
    return projecturlid || project?.id || "";
  });

  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [projectimage, setProjectImage] = useState("");
  const [titleimg, setTitleImg] = useState("");
  const [randomcolor, setRandomColor] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [userfilterid, setUserFilterId] = useState("");
  const [userfilterpid, setUserFilterpId] = useState("");
  const [assignedby, setAssignedby] = useState("");
  const [defaultUser, setDefaultUser] = useState<UserType>(UserType.All);
  const [cardWidth, setCardWidth] = useState(window.innerWidth);
  const [statusFilter, setStatusFilter] = useState("0,1,2,3,4,5,7");
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [status, setstatus] = useState(-1);
  const [localproject, setlocalporject] = useState([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectid, setSelectedId] = React.useState<string | null>(null);
  const [selectedAvatarId, setSelectedAvatarId] = useState<number | null>(null);
  const showLoading = (id: string) => {
    setOpen(true);
    setSelectedId(id);
  };
  const [loading, setLoading] = React.useState<boolean>(true);
  const [detailstate, setdetailstate] = useState<any>(initialdetailstate);
  // const [selectedAssignee, setSelectedAssignee] = useState<any>(null);

  const [loadData, setLoadData] = React.useState<boolean>(false);
  const [toDo, settoDo] = React.useState<any>([]);
  const [priority, setPriority] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setCardWidth(window.innerWidth);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchTasksByStatus = async (assigneeid?: any, assignee?: any) => {
    try {
      setIsLoading(true);
      if (projectid) {
        const projectId = project?.id || projectid;
        const response = await HTTPSCalls.GET(
          `${HTTPSCalls.ENDPOINTS.task.getAllByStatus}?`,
          {
            fromDate: fromdate,
            todate: todate,
            priority: priority,
            taskSorting: 0,
            statusFilter: statusFilter,
            status: status,
            sprintId: projectId,
            start: 0,
            length: 15000,
            projectid: projectId,
            assigneeId:  assignee,
            isDone: 1,
            Utype: defaultUser,
          }
        );
        if (response.result && response.status) {
          const newTaskData = {
           TODO: [],
            NEEDTODISCUSS: [],
            INPROGRESS: [],
            UAT: [],
            TESTING: [],
            DEVELOPED: [],
          };

          response.result.forEach((item: any) => {
            switch (item.status) {
              case STATUS.TODO:
                newTaskData.TODO = item.tasks;
                settoDo(item.tasks);
                break;
              case STATUS.NEEDTODISCUSS:
                newTaskData.NEEDTODISCUSS = item.tasks;
                break;
              case STATUS.INPROGRESS:
                newTaskData.INPROGRESS = item.tasks;
                break;
              case STATUS.UAT:
                newTaskData.UAT = item.tasks;
                break;
              case STATUS.TESTING:
                newTaskData.TESTING = item.tasks;
                break;
              case STATUS.DEVELOPED:
                newTaskData.DEVELOPED = item.tasks;
                break;
              default:
                break;
            }
          });
          setTaskData(newTaskData);
          setlocalporject(response.result);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(taskData, "taskDatataskData");

  useEffect(() => {
    // console.log("defaultUser", defaultUser);
    fetchTasksByStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
      projectid,
     defaultUser,
      project,
     fromdate,
     todate,
     priority,
    //  selectedAssignee,
     boardRefresh,
  ]);

  const onDragEnd = async (result: any) => {
    try {
      setIsLoading(true);
      const { source, destination } = result;
      if (!destination) return;

      const taskCategories = Object.keys(taskData);

      const sourceIndex = taskCategories.indexOf(source.droppableId);
      const destinationIndex = taskCategories.indexOf(destination.droppableId);

      result.task = { ...taskData[source.droppableId][source.index] };
      // if destination is BACKLOG, DONE, DUPLICATE
      if (destinationIndex === -1) {
        const columnData = [...taskData[source.droppableId]];
        columnData.splice(source.index, 1);

        const tasks = {
          ...taskData,
          [source.droppableId]: columnData,
        };
        setTaskData(tasks);
        destination.droppableId !== "BACKLOG" &&
          updateStatus(
            result?.task?._id,
            STATUS[destination.droppableId],
            result?.task
          );
      }

      if (
        sourceIndex < 0 ||
        sourceIndex >= taskCategories.length ||
        destinationIndex < 0 ||
        destinationIndex >= taskCategories.length
      ) {
        return;
      }

      const taskId = result?.draggableId;
      const taskDestinationStatus = +STATUS[destination.droppableId];
      await HandleStatusDropdown(
        result,
        taskId,
        sourceIndex,
        destinationIndex,
        taskDestinationStatus
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result: any = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    if (endIndex > 0) {
      result[endIndex].orderno = String(
        Number(result[endIndex - 1].orderno) + 1
      );
    }
    return result;
  };
  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone: any = Array.from(source);
    const destClone: any = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    removed.TStatus = STATUS[droppableDestination.droppableId];

    destClone.splice(droppableDestination.index, 0, removed);

    if (droppableDestination.index > 0) {
      destClone[droppableDestination.index].orderno = String(
        Number(destClone[droppableDestination.index - 1].orderno) + 1
      );
    }

    const result: any = {};

    destClone.map(
      (value: any) => (value.boardno = droppableDestination.droppableId)
    );
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const HandleStatusDropdown = async (
    result: any,
    taskId: string,
    sourceIndex: number,
    destinationIndex: number,
    taskDestinationStatus: number
  ) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const stateArrValues: any = Object.values(taskData);
    const stateArrKeys = Object.keys(taskData);
    if (sourceIndex === destinationIndex) {
      const items = reorder(
        stateArrValues[sourceIndex],
        source.index,
        destination.index
      );
      stateArrValues[sourceIndex] = items;
      setTaskData((p: any) => ({
        ...p,
        [stateArrKeys[sourceIndex]]: stateArrValues[sourceIndex],
      }));
    } else {
      const moveResult: any = move(
        stateArrValues[sourceIndex],
        stateArrValues[destinationIndex],
        source,
        destination
      );

      stateArrValues[sourceIndex] = moveResult[stateArrKeys[sourceIndex]];
      stateArrValues[destinationIndex] =
        moveResult[stateArrKeys[destinationIndex]];

      setTaskData((p: any) => ({
        ...p,
        [stateArrKeys[sourceIndex]]: stateArrValues[sourceIndex],
        [stateArrKeys[destinationIndex]]: stateArrValues[destinationIndex],
      }));

      updateStatus(taskId, taskDestinationStatus, result?.task);
    }
  };

  const onSelectDropDown = ({
    destinationDroppableId,
    sourceIndex,
    sourceDroppableId,
    task,
  }: {
    destinationDroppableId: string;
    sourceIndex: number;
    sourceDroppableId: string;
    task: any;
  }) => {
    const result = {
      destination: {
        index: 0,
        droppableId: destinationDroppableId, //STATUS_ARRAY[v],
      },
      source: {
        index: sourceIndex, //index,
        droppableId: sourceDroppableId, //STATUS_ARRAY[v],
        //  droppableId: STATUS_ARRAY[task?.TStatus],
      },
      draggableId: task?._id,
      task, //task?._id,
    };
    if (destinationDroppableId === "BACKLOG") {
      updateTaskSprint({ taskId: task?._id, sprintId: "", task });
    }
    onDragEnd(result);
  };

  const updateTaskSprint = async ({
    taskId,
    sprintId,
    task,
  }: {
    taskId: string;
    sprintId: any;
    task: any;
  }) => {
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateTaskSprint + "?",
        {
          id: taskId,
          sprintId: sprintId
        }
      );
      addSprintChangeLog(task, "Backlog", task?.Sprint[0]?.Name);
      return res;
    } catch (e) {
      console.log("error", e);
    }
  };

  const updateStatus = async (
    taskId: string,
    status: number | string,
    task: any
  ) => {
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateStatus + "?",
        {},
        {
          tid: taskId,
          tstatus: status,
        }
      );
      notification.success({
        message: "Success",
        description: "Task Status updated successfully.",
        placement: "bottomLeft",
      });

      addStatusChangeLog(task, status, task?.TStatus);
      return res;
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: "Error",
        description: "Failed to update task Status.",
        placement: "bottomLeft",
      });
    } finally {
      dispatch(refreshTaskDrawer());
    }
  };

  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "Board",
      // href: "/board",
    },
  ];

  // Handle avatar click function
  const handleAvatarClick = (assignee: any) => {
    setSelectedAssignee(assignee);
    fetchTasksByStatus(null, assignee);
    // setSelectedAvatarId(assignee);

  };

  return (
    <>
       <Spin spinning={isLoading}  indicator={<LoadingOutlined spin />} size="default" >
      <div>
        <Row justify={"end"} className="mb-2">
          <Col style={{ fontSize: "12px", textAlign: "center" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
      </div>
      <Layout style={{ backgroundColor: "white" }}>
        {/* <Loader loading={isLoading} /> */}
        <div>
          <div style={{ display: "flex",backgroundColor: "white"  }}>
            <div
              style={{
                marginRight: "5px",
                marginTop: "8px",
                height: "85vh",
                width: `${
                  width > 635 && width < 850
                    ? "65%"
                    : width > 851
                    ? "80%"
                    : "100%"
                }`,
              }}
            >
              <div>
                {taskData && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#fff",
                        padding: "0px 26px",
                        marginBottom: 20,
                      }}
                    >
                      <div>
                
                        <Toothbar
                          page="board"
                          setstatus={setstatus}
                          setPriority={setPriority}
                          setFromdate={setFromdate}
                          setTodate={setTodate}
                          setStatusFilter={setStatusFilter}
                          setDefaultUser={setDefaultUser}
                          setSelectedAssignee={setSelectedAssignee}
                          defaultUser={defaultUser}
                          setIsLoading2={setIsLoading}
                          isLoading2={isLoading}
                          projecturlname={projecturlname}
                          setTitleImg={setTitleImg}
                          userfilterid={userfilterid}
                          setUserFilterId={setUserFilterId}
                          setUserFilterpId={setUserFilterpId}
                          randomcolor={randomcolor}
                          setAssignedby={setAssignedby}
                          setRandomColor={setRandomColor}
                          titleimg={titleimg}
                          setProjectImage={setProjectImage}
                          setProjectName={setProjectName}
                          setProjectId={setProjectId}
                          projects={projects}
                          projectid={projectid}
                          projectname={projectname}
                          setProjects={setProjects}
                          handleAvatarClick={handleAvatarClick}
                          selectedAvatarId={selectedAvatarId}
                          setSelectedAvatarId={setSelectedAvatarId}
                          taskData={taskData}
                          setTaskData={setTaskData}
                          localproject={localproject}
                          setLoadData={setLoadData}
                          todo={toDo}
                         
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (boardViewMode === "list") {
                            dispatch(setBoardViewMode("grid"));
                          } else {
                            dispatch(setBoardViewMode("list"));
                            // setViewMode("list");
                          }
                        }}
                      >
                        <Tooltip
                          title={
                            boardViewMode === "list" ? "Grid View" : "List View"
                          }
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            // onClick={handleViewModeToggle}
                          >
                            {boardViewMode === "list" ? (
                              <AppstoreOutlined
                                style={{ fontSize: "24px", color: "#364663" }}
                              />
                            ) : (
                              <UnorderedListOutlined
                                style={{ fontSize: "24px", color: "#364663" }}
                              />
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    {/* {!loadData ? ( */}
                    <DragableBoard
                      page="board"
                      tasks={taskData}
                      viewMode={boardViewMode}
                      onDragEnd={onDragEnd}
                      showLoading={showLoading}
                      onSelectDropDown={onSelectDropDown}
                    />
                    {/* // ) : (
                  //   <DragableBoard
                  //     showLoading={showLoading}
                  //     tasks={taskData}
                  //     // statuses={STATUS_TO_DISPLAY_ON_BOARD}
                  //     onDragEnd={onDragEnd}
                  //     viewMode={viewMode}
                  //   />
                  // )} */}
                  </>
                )}
              </div>
              {/* )} */}
            </div>
            <div
              style={{
                backgroundColor: "white",
                width: `${
                  width > 635 && width < 850
                    ? "35%"
                    : width > 851
                    ? "18%"
                    : "0%"
                }`,
                marginTop: "8px",
                height: "80vh",
                // overflowX: "scroll",
              }}
            >
              <ActiviyLogs y={"75vh"} params={{ projectid: project?.id }}  />
            </div>
          </div>
        </div>
        {open && (
          <SidebarDrawerbacklog
            showLoading={showLoading}
            open={open}
            setRefresh={setRefresh}
            setOpen={setOpen}
            loading={loading}
            detailstate={detailstate}
            setdetailstate={setdetailstate}
            selectid={selectid}
          />
        )}
      </Layout>
      </Spin>
    </>
  );
};

export default Board;
